import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Campus_Banner from '../component/campus_banner'
import Campus_Footer from '../component/campus_footer'
import RightBar from '../component/rightbar'
import Videoplayer from '../component/videoplayer'
import { campusRoutes } from '../routes'

import v from '../../public/video.mp4'

import pf1 from '../../public/pf1.png'
import blue3 from '../../public/blue3.png'
import blue1 from '../../public/blue1.png'
import news1 from '../../public/news1.png'

//

export default class Campus_Professional extends Component {
    constructor(props) {
        super(props)
        this.state = {
            programs: [{ Name: '' }],
            index: 0,
            list: [
                { ID: 1, Name: '红旗校区', Img: '', Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '15512112119', Nub3: '0311-86618000' },
                { ID: 2, Name: '中华校区', Img: '', Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' },
                { ID: 3, Name: '阿里山校区', Img: '', Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' },
                { ID: 4, Name: '博物院校区', Img: '', Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' }
            ],
            professional: [],
            Name: '',
            Age: '',
            Phone: '',
            Address: '',
            MajorName: '',
            Note: '',
        }
    }
    componentDidMount() {
        this.getProfessional()
    }
    render() {
        let info
        if (!info) {
            info = this.state.list[0]
        } else {
            info = this.matching(this.props.location.state, this.state.list)
        }
        return (
            <styled.ContentBox>
                <Campus_Banner history={this.props.history} active={'专业设置'} />
                <styled.Swipper style={{ minHeight: "40rem", background: `url(${pf1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible', position: 'relative', display: 'flex', flexDirection: "column", justifyContent: "end", padding: "1rem 8rem" }}>
                    <div style={{ fontSize: '1.4rem', color: "rgb(84, 0, 255)", letterSpacing: '2px', fontWeight: "600", margin: "1rem 0", textAlign: 'center', display: "flex", flexDirection: "column", alignItems: 'center' }}>
                        {this.state.programs[this.state.index].Name}
                        <div style={{ width: '6rem', height: '6px', background: "linear-gradient(90deg, #7311ab 0%, #3908a2 100%)", marginTop: "1rem" }} />
                    </div>
                    <div style={{ width: '100%', overflow: "hidden", position: 'relative' }}>
                        <div id='swipper' style={{ display: 'flex', flexWrap: 'nowrap', padding: '0rem', position: 'relative', transform: 'translateX(0)', transition: 'all 1s' }}>
                            {this.state.programs.map((item, index) => {
                                return (
                                    <span style={{ display: 'inline-block', height: '12.5rem', minWidth: "calc(20% - 1rem)", background: `url(${blue3}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', margin: '0 0.5rem', display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '2.5rem 0', transform: this.state.index === index ? 'scale(1.1)' : "" }}>
                                        <p style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", textAlign: "center", fontSize: "1.33rem", color: "#fff", fontWeight: "600", letterSpacing: "2px" }}>{item.Name}</p>
                                        <div style={{ width: '2.5rem', height: '4px', background: this.state.index === index ? "#ffc422" : '#fff' }} />
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                    {/* <div style={{ marginTop: "3rem", padding: "0 10rem", display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "7", lineHeight: "1.5em", overflow: "hidden", fontSize: '.88rem', color: 'rgb(109, 109, 109)', textAlign: 'center' }}>{this.state.programs[this.state.index].Note}</div> */}
                    <styled.HoverIcon2 onClick={() => { this.pre() }} style={{ height: "3rem", width: "2rem", position: 'absolute', left: '3rem', bottom: "10%", transform: "translateY(-50%)" }}><use xlinkHref='#icon-qiehuanqizuo'></use></styled.HoverIcon2>
                    <styled.HoverIcon2 onClick={() => { this.next() }} style={{ height: "3rem", width: "2rem", position: 'absolute', right: '3rem', bottom: "10%", transform: "translateY(-50%)" }}><use xlinkHref='#icon-qiehuanqiyou'></use></styled.HoverIcon2>
                </styled.Swipper>
                <div style={{ width: "100%", minHeight: '50rem', background: `url(${news1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', padding: '2rem 15rem', display: "flex", flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div style={{ width: "100%", fontSize: '1.66rem', color: "#260ea2", display: 'flex', alignItems: "center", justifyContent: 'center', position: "relative", padding: '1rem', borderBottom: '1px solid #ccc' }}>
                        {this.state.programs[this.state.index].Name}
                        <i style={{ fontStyle: "normal", color: '#000', marginLeft: '1rem' }}>专业介绍</i>
                        <span style={{ position: 'absolute', bottom: '-2.5px', left: 'calc(50% - 3rem)', width: '6rem', height: '5px', background: "linear-gradient(90deg, #7311ab 0%, #3908a2 100%)", }} />
                    </div>
                    <div style={{ width: '100%', height: '22rem', padding: '2rem 0', display: 'flex' }}>
                        <div style={{ flex: "1", overflow: "hidden" }}> <img src={'http://49.4.2.15:9090/' + this.state.programs[this.state.index].Cover} alt='' style={{ height: '100%', width: '100%' }} /></div>
                        <div style={{ flex: '1.5', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: "1rem 3rem" }}>
                            <p style={{ fontSize: '1.66rem', color: "#260ea2", letterSpacing: "2px" }}>课程介绍</p>
                            <p style={{ color: '#c1c1c1', fontSize: '1rem', letterSpacing: '2px' }}>COURSE INTRODUCTION</p>
                            <p style={{ color: '#505050', fontSize: '.88rem', letterSpacing: '2px', display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "3", lineHeight: "1.5em", overflow: "hidden", }}>{this.state.programs[this.state.index].Note}</p>
                            <styled.HoverBox1 onClick={()=>{this.scroll()}} style={{ width: '11.5rem', height: "2.7rem", background: "linear-gradient(270deg, #7311ab 0%, #3908a2 100%)", borderRadius: "1rem", display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', color: '#fff' }}>在线咨询</styled.HoverBox1>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '6.5rem', display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: "6.5rem", width: '6.5rem', background: "linear-gradient(360deg, #7311ab 0%, #3908a2 100%)", color: '#fff', fontSize: '1.33rem' }}>
                            <span>招生</span>
                            <span>对象</span>
                        </div>
                        <div style={{ flex: '1', border: '1px solid #ccc', borderLeft: 'none', color: '#4c4c4c', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>针对想要提升自己的专业技能和综合素质的14--18岁初中毕业生；在校18个月，学期三年。</div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p style={{ fontSize: '1rem', color: '#260ea2', fontWeight: '600', marginTop: '2.5rem' }}>就业方向 :</p>
                        <p style={{ marginTop: '.5rem', fontSize: '.88rem', color: '#4c4c4c', letterSpacing: '2px', display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", lineHeight: "1.5em", overflow: "hidden", }}>{this.state.programs[this.state.index].Note}</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p style={{ fontSize: '1rem', color: '#260ea2', fontWeight: '600', marginTop: '1.5rem' }}>优惠政策 :</p>
                        <p style={{ marginTop: '.5rem', fontSize: '.88rem', color: '#4c4c4c', letterSpacing: '2px', display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", lineHeight: "1.5em", overflow: "hidden", }}>1、入学签订就业协议；学费：5800元/年 2、贫困生助学金补助1500元/年，两年共补助：3000元/2年。3、颁发中专学历证书、职业资格证书；并且有机会韩国留学深造。</p>
                    </div>
                </div>
                <div style={{ width: "100%", minHeight: '40rem', background: '#f7f6fe', overflow: 'hidden', padding: '2rem 15rem', display: "flex", flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div style={{ width: '10rem', height: '4rem', fontSize: '1.66rem', color: '#260ea2', letterSpacing: '2px', border: '4px solid', textAlign: 'center', marginBottom: '2rem' }}>
                        学习内容
                        <p style={{ display: 'flex', flexWrap: "nowrap", whiteSpace: 'nowrap', justifyContent: 'center', fontSize: "1rem", color: '#c1c1c1' }}>C&nbsp;&nbsp; O&nbsp;&nbsp; N &nbsp;&nbsp;T &nbsp;&nbsp;E &nbsp;&nbsp;N &nbsp;&nbsp;T</p>
                    </div>
                    <div style={{ height: 'calc(100% - 6rem)', width: "100%", display: 'flex' }}>
                        <div style={{ flex: '1', height: '100%', overflow: 'hidden' }}><img src={'http://49.4.2.15:9090/' + this.state.programs[this.state.index].Cover} alt='' style={{ width: "100%", height: '100%' }} /></div>
                        <div style={{ flex: '1', height: '100%', background: `url(${blue1}) no-repeat`, backgroundSize: '100% 100%', padding: '5rem 3rem' }}>
                            <p style={{ color: '#ffbe00', fontSize: '.88rem', fontWeight: '600', marginBottom: '1.5rem' }}>石家庄花都形象设计职业学校</p>
                            <p style={{ color: '#fff', fontSize: '.77rem', letterSpacing: '2px', display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "12", lineHeight: "1.5em", overflow: "hidden", }}>{this.state.programs[this.state.index].Note}</p>
                        </div>
                    </div>
                </div>
                <div id='contact' style={{ width: "100%", minHeight: '50rem', background: '#fff', overflow: 'hidden', padding: '2rem 15rem', display: "flex", flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div style={{ color: 'rgb(181, 185, 203)', paddingBottom: '1rem', borderBottom: '1px solid rgb(181, 185, 203)', margin: '2rem 0' }}>近三十年来学校始终坚持文化立校、特色办学、改革创新多元发展的办学宗旨，遵循“尚德、至善、存真、尚美”的校训。</div>
                    <div style={{ color: 'rgb(153, 153, 153)', display: 'flex', padding: '1rem 2rem', justifyContent: 'space-between', width: '100%', fontSize: '1rem' }}>
                        <span style={{ letterSpacing: '.1rem', lineHeight: '2rem' }}>
                            <p>地址 : {info.Address}</p>
                            <p>官方网站 : {info.Website}</p>
                            <p>招生就业办电话 : {info.Nub1}</p>
                            <p>学籍助学金办电话 : {info.Nub2}</p>
                            {/* <p>学校办公室电话 : {info.Nub3}</p> */}
                        </span>
                        <span style={{ letterSpacing: '.1rem', lineHeight: '2rem' }}>
                            <p style={{fontWeight:'600'}}>乘车路线</p>
                            <p>火车站：乘117路换乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                            <p>火车站北站线路：乘164路换乘18路公交车至汇春博物园站下车（斜对面）</p>
                            <p>白佛客运站线路：乘28路换乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                            <p>运河桥客运站线路：乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                            <p>南焦客运站线路：乘106路换乘18路公交车至汇春博物园站下车（斜对面）</p>
                        </span>
                    </div>
                    <styled.FormCard style={{padding:'1rem 5rem'}}>
                        <div>
                            <p style={{color:'#260ea2'}}>预约报名</p>
                            <span style={{ justifyContent: 'center' }}>我们会第一时间与您取得联系</span>
                        </div>
                        <div style={{ marginTop: '1rem', }}>
                            <span style={{ marginRight: '2rem' }}>
                                <p><span>姓名</span> <styled.FormInput name='Name' value={this.state.Name} onChange={this.onInput.bind(this)} /></p>
                                <p><span>年龄</span> <styled.FormInput name='Age' value={this.state.Age} onChange={this.onInput.bind(this)} /></p>
                                <p><span>电话</span> <styled.FormInput name='Phone' value={this.state.Phone} onChange={this.onInput.bind(this)} /></p>
                                <button onClick={() => { this.submit() }} style={{ width: '6.6rem', height: '1.5rem', color: '#fff', background: '#260ea2', border: 'none', marginTop: '2rem', marginLeft: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    提交
                                </button>
                            </span>
                            <span style={{ alignItems: '' }}>
                                <p><span>地址</span><styled.FormInput name='Address' value={this.state.Address} onChange={this.onInput.bind(this)} /></p>
                                <p>
                                    <span>预约专业</span>
                                    <select name='MajorName' value={this.state.MajorName} onChange={this.onInput.bind(this)} >
                                        {this.state.programs.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Name} >{item.Name}</option>
                                            )
                                        })}
                                    </select>
                                </p>
                                <p style={{ alignItems: 'normal' }}><span style={{ marginTop: '.5rem' }}>备注</span><textarea name='Note' value={this.state.Note} onChange={this.onInput.bind(this)} /></p>
                            </span>
                        </div>
                    </styled.FormCard>
                </div>
                <Campus_Footer history={this.props.history} />
            </styled.ContentBox>
        )
    }
    //
    scroll(){
        var con=document.getElementById('contact')
        con.scrollIntoView()
    }
    /////////////////////////////////////////////////////////////
    pre() {
        var swipper = document.getElementById('swipper')
        if (this.state.index === 0) {
            return
        }
        // if (this.state.index % 5 != 5) {
        //     swipper.style.left = "20%"
        // }
        this.setState({ index: this.state.index - 1 })
    }
    next() {
        var swipper = document.getElementById('swipper')
        if (this.state.index === 4) {
            return
        }
        // if (this.state.index > 4 || this.state.index === 4) {
        //     console.log("sssssssssssssss");
        //     swipper.style.background = "skyblue"
        //     swipper.style.transform = "translateX(-20%)"
        // }
        this.setState({ index: this.state.index + 1 })
    }
    //
    onInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    //
    submit() {
        if (this.state.Name === '') {
            alert('请填写您的姓名 !')
            return;
        }
        if (this.state.Phone === '') {
            alert('请填写您的电话 !')
            return;
        }
        service.request({
            method: 'post',
            url: '/apply',
            data: {
                Name: this.state.Name,
                Age: Number(this.state.Age),
                Phone: this.state.Phone,
                Address: this.state.Address,
                MajorName: this.state.MajorName,
                Note: this.state.Note,
            }
        }).then(res => {
            if (res.code === 200) {
                alert('提交成功 稍后我们将联系您!')

            }
        })
    }
    //
    getProfessional() {
        service.request({
            method: 'get',
            url: '/department',
            params: {
                limit: 6,
                campusID: this.props.id
            },
        }).then(res => {
            if (res.code === 200) {
                this.setState({ programs: res.data })
            }
        })
    }
}
