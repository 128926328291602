import styled from "styled-components"

import e3 from '../../public/e3.png'
import cover from '../../public/cover.png'
import tips from '../../public/TIPS.png'

export const ContentBox = styled.div`
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-width: 1600px;
height:100%;
display: flex;
flex-direction: column;
overflow-y: auto;
`

export const Icon = styled.svg`
width:.9rem;
height:.9rem;
fill:#ba0f22;
`

export const Banner = styled.div`
min-height:6rem;
min-width: 1600px;
width:100%;
background:#fff;
padding:0 7.5rem;
display:flex;
align-items:center;
justify-content:space-between;
border-bottom:3px solid #ba0f22;
color:#ba0f22;
font-weight:500;
font-family:PingFangSC-Medium;
>div{
    display:flex;
    align-items:center;
    justify-content:space-between;
    >span{
        display:flex;
        ${Icon}{
            :hover{
                cursor:pointer;
            }
        }
    }
}
`

export const CampusItem = styled.span`
padding-top:0.625rem;
font-size:0.9rem;
display:flex;
align-items:center;
justify-content:center;
>a{
    color:#ba0f22;
    text-decoration-line: none; 
}
>a{
    :hover{
        transform: scale(1.1);
    }
}
`
export const Up = styled.svg`
width:1rem;
height:1rem;
`
export const Down = styled.svg`
width:1rem;
height:1rem;
`

export const Drapdown = styled.div`
z-index:99;
position:absolute;
bottom:0rem;
transform:translate(-15%,100%);
background:#fff;
display:none;
flex-direction:column;
align-items:center;
padding:1rem 1rem 0 0;
font-weight:500;
color:#000;
border-radius:.5rem;
transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
box-shadow: 2px 2px 5px 5px rgb(0 0 0 / 10%);
>span{
    white-space:nowrap;
    padding-left:1rem;
    padding-bottom:0.625rem;
    :hover{
        color:#ba0f22;
    }
}
`

export const PageTitle = styled.div`
display:flex;
align-items:center;
position:relative;
padding-bottom:0.625rem;
margin-left:2rem;
${Up}{
    display:none;
}
:hover{
    cursor:pointer;
    color:#ba0f22;
    ${Drapdown}{
        display:flex;
    }
    ${Down}{
        display:none;
        fill:#ba0f22;
    }
    ${Up}{
        display:block;
        fill:#ba0f22;
    }
}
font-weight:${props => props.active ? 'bold' : 'normal'};
color:${props => props.active ? '#ba0f22' : '#000'};
${Icon}{
    position:absolute;
    bottom:-0.8rem;
    left:calc(50% - 1rem);
    transform:translateX(-calc(50% + 1rem));
    display:${props => props.active ? 'block' : 'none'};
}
${Up}{
    fill:${props => props.active ? '#ba0f22' : '#000'}
}
${Down}{
    fill:${props => props.active ? '#ba0f22' : '#000'}
}
`
export const C_PageTitle = styled.div`
display:flex;
align-items:center;
position:relative;
padding-bottom:0.625rem;
margin-left:2rem;
${Up}{
    display:none;
}
:hover{
    cursor:pointer;
    color:#ffbe40;
    ${Drapdown}{
        display:flex;
    }
    ${Down}{
        display:none;
        fill:#ba0f22;
    }
    ${Up}{
        display:block;
        fill:#ba0f22;
    }
}
font-weight:${props => props.active ? 'bold' : 'normal'};
color:${props => props.active ? '#ffbe40' : '#fff'};
${Icon}{
    position:absolute;
    bottom:-0.8rem;
    left:calc(50% - 1rem);
    transform:translateX(-calc(50% + 1rem));
    display:${props => props.active ? 'block' : 'none'};
}
${Up}{
    fill:${props => props.active ? '#ba0f22' : '#000'}
}
${Down}{
    fill:${props => props.active ? '#ba0f22' : '#000'}
}
`

export const Swipper = styled.div`
position:relative;
display:flex;
width:100%;
min-height:37rem;
overflow:hidden;
`
export const SwipperContent = styled.div`
position:absolute;
left:${props => props.left};
display:flex;
transition:${props => props.end === 1 ? 'all 0s ease-in-out' : 'left .5s ease-in-out'};
width:100%;
height:100%;

`
export const SwipperPoint = styled.svg`
width:3rem;
height:3rem;
fill:${props => props.active ? '#ba0f22' : '#ccc'};
>use{
    :hover{
        cursor:pointer;
    }
}
`
export const C_SwipperPoint = styled.svg`
width:3rem;
height:3rem;
fill:${props => props.active ? '#260ea2' : '#fff'};
>use{
    :hover{
        cursor:pointer;
    }
}
`


export const NewsFirst = styled.div`
display:flex;
flex:5;
:hover{
    cursor:pointer;
}
`
export const NewsType = styled.span`
margin-right:1rem;
color:${props => props.active ? '#ba0f22' : ''};
:hover{
    cursor:pointer;
}
`
export const NewsItem = styled.span`
display:flex;
justify-content:space-between;
:hover{
    cursor:pointer;
    color:#ba0f22;
}
`

export const Button = styled.button`
position:relative;
width:100%;
height:2.5rem;
color:#fff;
display:flex;
align-items:center;
justify-content:center;
background:#ba0f22;
border:none;
font-size:.88rem;
letter-spacing:.1rem;
:hover{
    cursor:pointer;
}
`
export const Card = styled.div`
display:flex;
flex-direction:column;
height:11.5rem;
border-radius:5px;
transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
box-shadow: 2px 2px 5px 5px rgb(0 0 0 / 10%);
>div{
    flex:1;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 2rem;
    >button{
        width:5.5rem;
        height:1.5rem;
        border-radius:15px;
        color:#ba0f22;
        font-size:.8rem;
        border:1px solid #ba0f22;
        :hover{
            cursor:pointer;
        } 
    }
}
`
export const CampusImg = styled.span`
display:flex;
flex-direction:column;
justify-content:space-between;
flex:.8;
padding:1rem;
@media screenand(-ms-high-contrast:active),(-ms-high-contrast:none) {
    ${props => props.right ? 'padding-right:5rem;' : ''}
} 
>p{
    font-size:1.4rem;
    font-weight:700;
}
>span{
    display:flex;
    align-items:center;
    justify-content:center;
    height:7rem;
    width:100%;
    background:#f3d0d4;
    padding:1.5rem;
    font-size:.88rem;
    >p{
        display: -webkit-box; 
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        line-height:1.5rem;
    }
}
>div{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    >span{
        display:flex;
        align-items:center;
        margin-bottom:.5rem;
        ${Icon}{
            width:1.3rem;
            height:1.3rem;
            margin-right:.5rem;
        }
    }
}
`

export const Img = styled.img`
width:11.5rem;
height:11.5rem;
position:absolute;
left:50%;
top:50%;
transform:translate(-50%,-50%);
`

export const ProgramsCard = styled.div`
display:flex;
flex:1;
font-family: "Microsoft YaHei";
font-size: .9rem;
flex-direction:column;
align-items:center;
justify-content:space-between;
height:100%;
padding:5rem 2rem 2.77rem 2rem;
background:${props => `url(${props.img}) no-repeat`};
color:#fff;
>div{
    :hover{
        cursor:pointer;
    }
    >span{
        display:none;
    }
    >img{
        display:block;
    }
}
:hover{
    background:#bc1425;
    >div{
        >span{
            display:block;
        }
        >img{
            display:none;
        }
    }
}
`

export const AdmissionItem = styled.div`
flex:1;
display:flex;
align-items:center;
justify-content:space-between;
color:#6a6869;
padding:1rem 0;
font-size:1.44rem;
font-weight:700;
border-bottom:1px solid #6a6869;
${Icon}{
    fill:#6a6869;
}
:hover{
    cursor:pointer;
    color:#ba0f22;
    ${Icon}{
        fill:#ba0f22;
    }
}
`

export const ImageItem = styled.div`
position:relative;
font-size:0.77rem;
>span{
    display:none;
    position:absolute;
    bottom:0;
}
>div{
    width:100%;
    height:100%;
}
:hover{
    transform:scale(1.1);
    z-index:99;
    border-style: solid;
    border-width: .5rem;
    border-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 17.98px 11.02px rgba(0, 0, 0, 0.7);
    >span{
        display:flex;
        width:100%;
        height:4rem;
        background:rgba(0,0,0,0.5);
        padding:0.66rem 2.22rem;
        align-items:center;
        justify-content:space-between;
        >p{
            color:#fff;
            width:17rem;
            height:100%;
            display: -webkit-box; 
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height:1.5rem;
            
        }
        >span{
            :hover{
                cursor:pointer;
            }
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:0 1.3rem;
            width:8rem;
            height:2rem;
            background:#fff;
            border-radius:1rem;
            >p{
                font-weight:700;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
        }
    }
      
}
`
export const ImageItem4 = styled.div`
position:relative;
font-size:0.77rem;
>span{
    display:none;
    position:absolute;
    bottom:0;
}
>div{
    width:100%;
    height:100%;
}

>span{
display:flex;
width:100%;
height:4rem;
background:linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
padding:0.66rem 2.22rem;
align-items:center;
justify-content:space-between;
    >p{
        color:#fff;
        width:100%;
        height:100%;
        display: -webkit-box; 
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height:1.5rem;
        
    }
}

`
export const ImageItem2 = styled.div`
position:relative;
flex:1;
box-shadow: -10px -10px 20px 10px rgba(74, 74, 74, 0.14);
background:${props => `url(${props.img}) no-repeat`};
:hover{
    transform:scale(1.1);
    z-index: 99;
}
>div{
    position:absolute;
    display:flex;
    align-items:end;
    bottom:0;
    padding:1rem;
    height:6rem;
    width:100%;
    background:rgba(0,0,0,0.4);
    color:#fff;
}
`


export const EducationBox = styled.div`
display:flex;
width:100%;
padding:0 18rem;
>div{
    display:flex;
    ::-webkit-scrollbar{
        display:none !important;
    }
    >p{
        font-size:1.3rem;
        color:#ba0f22;
        font-weight:700;
        margin-bottom:1rem;
    }
    
}
`
export const RightBarItem = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
height:3.5rem;
padding:1rem;
background:${props => props.active ? `url(${e3})` : '#fff'};
background-size:cover;
border-bottom:${props => props.active ? 'none' : '1px solid #ebebeb'};
color:${props => props.active ? '#fff' : '#000'};
:hover{
    cursor:pointer;
    color:${props => props.active ? '#fff' : '#ba0f22'};
}
>i{
    font-style:normal;
}
`
export const RightBarItemNext = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
height:2.5rem;
padding:.5rem 0 .5rem 2rem;
font-size:.85rem;
:hover{
    cursor:pointer;
    color:#ba0f22;
}
`

export const Line = styled.span`
display:inline-block;
border-width: 1px;
border-color: rgb(136, 136, 136);
border-style: solid;
width: 127px;
height: 0;
z-index: 65;
`
export const FontCard = styled.div`
width:10rem;
height:14rem;
background:#fbd6a1;
display:none;
flex-direction:column;
align-items:center;
justify-content:space-between;
position:absolute;
transform:translateY(25%);
padding:.8rem 2rem;
>span{
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:4rem;
    width:6rem;
    height:6rem;
    border-radius:50%;
    background:#f9d9cc;
}
>p{
    font-size:.77rem;
    text-align:center;
    color: rgb(120, 120, 120);
}
`

export const FontBox = styled.div`
width:5rem;
height:5rem;
border-radius:50%;
z-index:65;
background:#f9d9cc;
display:flex;
align-items:center;
justify-content:center;
font-size:3rem;
font-weight:700;
color:#ba0f22;
:hover{
    cursor:pointer;
    ${FontCard}{
        display:flex;
    }
}
`
export const FontBackground = styled.div`
height:8rem;
width:calc(100% - 6rem);
background:#fcf0d6;
position:absolute;
z-index:-1;
`

export const ImgTitle = styled.div`
display:none;
width:100%;
overflow:visible;
color:#fff;
position:absolute;
bottom:0;
padding:1rem 2rem;
background: url(${cover}) no-repeat;
background-size:cover;
>h1{
    font-size:2rem;
    font-weight:700;
    margin-bottom:1rem;
}
>p{
    font-size:.77rem;
    margin-bottom:1rem;
}
>span{
    width:4.5rem;
    height:2rem;
    font-size:.77rem;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid #bc1425;
    :hover{
        cursor:pointer;
    }
}
`
export const PushButton = styled.span`
width:4.5rem;
height:2rem;
color:#fff;
font-size:.77rem;
display:inline-flex;
align-items:center;
justify-content:center;
border:1px solid #bc1425;
:hover{
    cursor:pointer;
}
`

export const ImgBottom = styled.div`
width:100%;
padding:1rem 2rem;
color:#fff;
font-size:1.33rem;
background:rgba(0,0,0,.5);
display:flex;
align-items:center;
justify-content:space-between;
position:absolute;
bottom:0;
`


export const ImgMask = styled.div`
display:none;
width:100%;
height:100%;
background:rgba(0,0,0,0.5);
`
export const ImgBackground = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
overflow: visible;
position:relative;
>p{
    color:#fff;
    letter-spacing:2px;
    text-align:center;
}
${ImgMask}{
    flex-direction:column;
    align-items:center;
    justify-content:center;
    >p{
        color:#fff;
        letter-spacing:2px;
        text-align:center;
    }
}
:hover{
    cursor:pointer;
    ${ImgMask}{
        display:flex;
    }
    ${ImgTitle}{
        display:block;
    }
    ${ImgBottom}{
        display:none;
    }
}
`
export const TipsIcon = styled.span`
width:2.22rem;
height:.95rem;
background: url(${tips}) no-repeat;
font-size:.5rem;
padding-left:.2rem;
color:#fff;
display:flex;
align-items:center;
justify-content:center;
`
export const NewsType2 = styled.span`
color:${props => props.type === 1 ? '#ba0f22' : 'rgb(102, 102, 102)'};
font-size:.77rem;
:hover{
    cursor:pointer;
}
`
export const NewsCard = styled.span`
display:flex;
justify-content:space-between;
width:100%; 
color:rgb(102, 102, 102);
:hover{
    cursor:pointer;
}
>span{
    >p{
        ::-webkit-scrollbar{
            display:none;
        }
    }
}
`
export const NewsPicture = styled.div`
max-width:20rem;
display:flex;
flex-direction:column;
justify-content:space-around;
margin-right:1rem;
:hover{
    ${NewsCard}{
        color:#fff;
        background:#ba0f22;
    }
}
`

export const NewList = styled.div`
display:flex;
flex-direction:column;
font-size:.77rem;
color:#555;
width:calc(100% - 2rem);
>div{
    width:100%;
    height:2.5rem;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-bottom:1px solid #e5e7e5;
    :hover{
        cursor:pointer;
        color:#ba0f22;
    }
}
`

export const Pagination = styled.div`
width:100%;
height:1.1rem;
color:rgba(190, 190, 190, 0.678);
font-size:.72rem;
display:flex;
>div{
    height:100%;
    margin:0 .2rem;
    padding:.3rem;
    display:flex;
    align-items:center;
    justify-content:center;
    border:1px solid rgba(190, 190, 190, 0.678);
    :hover{
        cursor:pointer;
        background:rgba(0,0,0,.3);
        color:#fff;
    }
}
`

export const HoverBox = styled.span`
:hover{
    cursor:pointer;
    color:#ba0f22;
}
`
export const HoverBox1 = styled.span`
:hover{
    cursor:pointer;
    color:#ffc422;
}
`

export const NewsBox = styled.div`
display:flex;
width:100%;
padding:0 11rem;
>div{
    display:flex;
    :hover{
        cursor:pointer;
    }
    >p{
        font-size:1.3rem;
        color:#ba0f22;
        font-weight:700;
        margin-bottom:1rem;
    }
}
`
export const NewsCardBox = styled.span`
flex:1;
display:flex;
width:100%; 
color:rgba(102, 102, 102, 0.702);
background:#fff;
:hover{
    cursor:pointer;
    color:rgba(255, 255, 255, 0.702);
    transform:scale(1.1);
    z-index:99;
    background:#ba9a05;
    >span{
        border-bottom:2px solid #ba9a05;
    }
}
>span{
    display:flex;
    flex-direction:column;
    padding:.77rem;
    justify-content:center;
    border-bottom:2px solid #f2f2f2;
    >span{
        font-size:.8rem;
    }
    >p{
        font-size:.95rem;
        margin-top:1rem;
        width:calc(100%);
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        line-height:1.5rem;
        overflow:hidden;
    }
}
`

export const ArticleBox = styled.div`
padding:2rem;
display:flex;
height:calc(100% - 2.5rem);
flex-wrap:wrap;
max-height:100%;
overflow-Y:scroll;
::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44,#ccc),color-stop(0.72,#ccc),color-stop(0.86,#ccc));
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background-color: #F5F5F5;
}
`
export const HoverIcon = styled.svg`
width:1rem;
height:1rem;
:hover{
    fill:#ba0f22;
    cursor:pointer;
}
`
export const HoverIcon2 = styled.svg`
width:1rem;
height:1rem;
fill:#9c62ed;
:hover{
    fill:#270fa3;
    cursor:pointer;
}
`
export const ScrollNews = styled.div`
display:flex;
transform:translateX(${props => props.left});
transition:all .8s ease-in-out;
`
export const CourseCard = styled.span`
width:13rem;
min-height:16rem;
border-radius: 10px;
position: relative;
margin:0 1rem;
transform:translateX(5rem);
flex-direction:column;
align-items:center;
justify-content:space-around;
z-index:9999;
>p{
    width:100%;
    border-radius:0 0 10px 10px;
    text-align:center;
    text-overflow:ellipsis;
    letter-spacing:2px;
    font-size:.7rem;
    font-weight:bold;
}
>span{
    position:absolute;
    bottom:-2rem;
    left:50%;
    transform:translateX(-50%);
    width:2px;
    height:2rem;
    border-left:2px dotted #ba0f22;
}
>i{
    position:absolute;
    bottom:calc(-5.1rem - .5px);
    left:50%;
    transform:translateX(-50%);
    width:3rem;
    height:3rem;
    border-radius:50%;
    background:#ba0f22;
    color:#FFF;
    display:flex;
    align-items:center;
    justify-content:center;
    font-style:normal;
    
}
`
export const More = styled.div`
width:100%;
color:#fff;
display:flex;
align-items:center;
justify-content:center;
>span{
    width:4.5rem;
    height:2rem;
    font-size:.77rem;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    border:1px solid #bc1425;
    :hover{
        cursor:pointer;
    }
}
`
export const FontStyles = styled.div`
width:100%;
line-height: 2rem;
letter-spacing: .1rem;
`
export const ImageItem3 = styled.div`
position:relative;
width:calc( 50% - 2rem );
margin:1rem;
height:16rem;
background:${props => `url(${props.img}) no-repeat`};
:hover{
    cursor:pointer;
    box-shadow: -10px -10px 20px 10px rgba(74, 74, 74, 0.3);
    >div{
        display:flex;
    }
    ${ImgMask}{
        display:block;
    }
    >i{
        display:none;
    }
} 
>div{
    background-color: rgba(188, 20, 37,.8);
    position:absolute;
    display:none;
    align-items:center;
    flex-direction:column;
    justify-content:center;
    height:100%;
    width:100%;
    color:#fff;
    >span{
        font-size:.77rem;
        letter-spacing:.1rem;
        line-height:1.2rem;
        text-align: center;
    }
}
>i{
    position:absolute;
    color:#fff;
    font-size:.88rem;
    background-color: rgba(188, 20, 37,.8);
    bottom:0;
    font-style:normal;
    width:100%;
    text-align:center;
}
`
export const FormInput = styled.input`
width:14rem;
height:1.6rem;
border:2px dotted #ccc;
background:#eee;
margin:.5rem 0;
`

export const FormCard = styled.div`
width:100%;
margin-top:5rem;
border:2px dotted #ccc;
border-radius:10px;
padding:1rem 4rem;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
color:rgb(153, 153, 153);
>div{
    width:100%;
    display:flex;
    align-items:center;
    >p{
        font-size:1.5rem;
        color:#ba0f22;
        font-weight:bold;
    }
    >span{
        width:50%;
        height:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        >p{
            width:90%;
            display:flex;
            align-items:center;
            justify-content:space-between;
            >select{
                width:14rem;
                height:1.6rem;
                border:2px dotted #ccc;
                background:#eee;
                margin:.5rem 0;
                outline:none;
            }
            >textarea{
                width:14rem;
                height:6rem;
                border:2px dotted #ccc;
                background:#eee;
                margin:.5rem 0;
                outline:none;
                resize:none;
            }
            >input{
              outline:none;
              padding-left:.5rem;
            }
        }
    }
}
`

export const PictrueCard = styled.div`
height:14rem;
width:calc(25% - 2rem);
margin:0 1rem;
margin-bottom:2rem;
display:flex;
flex-direction:column;
font-size:.66rem;
color:#fff;
>span{
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    >img{
        position: absolute;
        right: 2px;
        bottom: 2px;
        width:5rem;
        height:5rem;
    }
}
`
export const VideoCard = styled.div`
min-width:26.5rem;
height:17.8rem;
line-height:17.8rem;
transform:${props => props.center === 1 ? 'scale(1.2)' : ''};
z-index:${props => props.center === 1 ? '999' : ''};
opacity:${props => props.center === 1 ? '1 ' : '.5'}; 
transition: all .5s ease-in-out;
>img{
    width:100%;
    height:100%;
    object-fit: cover;
}
`

export const ImageCard = styled.div`
min-width:50rem;
height:12rem;
display:flex;
background: url(${props => props.url}) no-repeat;
margin:.5rem 0;
>span{
    height:100%;
    flex:1;
}
`

export const WechatCode = styled.span`
width: 2.22rem;
height: 2.22rem;
border-radius: 50%;
background: #fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 1.5rem;
position:relative;
:hover{
    >i{
        display:block;
    }
    >span{
        display:block;
    }
}
>i{
    display:none;
    position:absolute;
    bottom:-1rem;
    font-size:1rem;
}
>span{
    display:none;
    width: 7.5rem;
    height: 7.5rem;
    position:absolute;
    z-index:999;
    bottom:-8rem;
}
`

// export const InputBox = styled.div`
// width:100%;
// height:50px;
// border-radius:10px;
// border:${props => props.active ? '1px solid #2bae3a' : '1px solid #ccc'};
// padding:10px;
// display:flex;
// align-items:center;
// justify-content:center;
// `

// export const Input = styled.input`
// width:calc(100% - 20px);
// height:100%;
// color:#9e9e9e;
// padding-left:20px;
// border:none;
// outline:none;
// caret-color:#9e9e9e;
// `




// export const Footer = styled.div`
// width:100%;
// display:flex;
// align-items:center;
// justify-content:space-between;
// >span{
//     color:#9e9e9e;
//     font-size:14px;
// }
// `