import React, { Component } from 'react'
import * as styled from '../Utlis/styled'

import cookie from 'react-cookies'
import '../../public/iconfont'
import service from '../Utlis/request'

import news1 from '../../public/news1.png'
import blue3 from '../../public/blue3.png'
import blue4 from '../../public/blue4.png'
import r from '../../public/右.png'


export default class Campus_Result extends Component {
    constructor(props) {
        super(props)
        this.state = {
            new: {},
            news: [{ Name: '' }],
            index: 0,
        }
    }
    componentDidMount() {
        service.request({
            method: 'get',
            url: '/article',
            params: {
                campusID: this.props.id,
                categoryID: 11,
                columnID: 4,
                offset: 0,
                limit: 1,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ new: res.data[0] })
            }
        })
        this.getNews()
    }
    render() {
        return (
            <div style={{ width: "100%", minHeight: '40rem', background: `url(${news1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', display: "flex", alignItems: "end   ", position: 'relative' }}>
                <div style={{ flex: '5.5', height: "80%", background: `url(${blue4}) no-repeat`, backgroundSize: '100% 100%', position: "relative" }}>
                    <div onClick={() => { }} style={{ width: '70%', height: '80%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", position: 'absolute', right: "0", top: "50%", transform: 'translateY(-50%)' }}>
                        <span style={{ width: '100%', height: '18rem', }} ><img src={'http://49.4.2.15:9090/' + this.state.new.Cover} style={{ width: '100%' }} alt='' /></span>
                        {/* <span style={{ width: '100%', height: '12rem', background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(this.state.new.Cover)}) no-repeat`, backgroundSize: '100% 100%' }} /> */}
                        <styled.NewsCard onClick={() => { this.props.history.push({ pathname: '/campus_results/' + this.props.id, article: this.state.new }) ; cookie.save('campus', "教学成果")}} style={{ background: "#fff", justifyContent: 'center', flex: '1' }}>
                            <span style={{ maxWidth: '80%', display: "flex", flexDirection: "column", justifyContent: "space-around", padding: '1rem 0' }}>
                                <p style={{ fontSize: '1rem', marginBottom: '.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{this.state.new.Title}</p>
                                <p style={{ fontSize: '.77rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(this.state.new.Content)}</p>
                            </span>
                        </styled.NewsCard>
                    </div>
                </div>
                <div style={{ flex: "7", height: "100%", padding: "4rem 6rem", background: "#fff", display: "flex", flexDirection: 'column' }}>
                    <div><span style={{ fontSize: "2rem" }}>教学成果</span></div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem 0', flex: '1' }}>
                        {this.state.news.map((item, index) => {
                            return (
                                <styled.NewsCard onClick={() => { this.props.history.push({ pathname: '/campus_results/' + this.props.id, article: item }); cookie.save('campus', "教学成果") }} key={index} style={{ flex: '1' }}>
                                    <span style={{ textAlign: 'center', padding: '1rem 0' }}><p style={{ fontSize: '2rem', fontWeight: '700' }}>{this.formatDate(item.Time / 1000000, 1)}</p><p style={{ fontSize: '1rem' }}>{this.formatDate(item.Time / 1000000, 2)}</p></span>
                                    <span style={{ width: "3px", height: "100%", background: "#4646e5" }} />
                                    <span style={{ width: '70%', padding: '1rem', borderTop: index === 0 ? '' : '2px solid #f1f1f1' }}>
                                        <p style={{ fontSize: '1rem', color: '#555', marginBottom: '1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                        <p style={{ fontSize: '.77rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                    </span>
                                    <styled.TipsIcon style={{ backgroundSize: '100% 100%', margin: '1rem 0' }}>HOT</styled.TipsIcon>
                                </styled.NewsCard>
                            )
                        })}
                    </div>

                </div>
            </div>
        )
    }
    //
    getSimpleText(html) {
        if (!html) { return }
        var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
        var m = rx.exec(html);
        if (m) {
            m = m[1];
        } else {
            m = html
        }
        var re1 = new RegExp("<.+?>", "g")
        var msg = m.replace(re1, '')
        msg = msg.replace(/&nbsp;/g, ' ')
        return msg
    }
    formatDate(dates, type) {
        var date = new Date(dates);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        if (type === 1) { return y }
        if (type === 2) { return m + '.' + d }
        if (!type) { return y + '-' + m + '-' + d }
    }
    //
    getNews() {
        service.request({
            method: 'get',
            url: '/article',
            params: {
                campusID: this.props.id,
                categoryID: 11,
                columnID: 4,
                offset: 1,
                limit: 3,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ news: res.data, count: res.count })
            }
        })
    }
}
