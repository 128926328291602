import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import code2 from '../../public/code.jpg'
import zhaosheng from '../../public/zhaosheng.pdf'

export default class RightBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: '',
            code: false,
        }
    }
    componentDidMount() {
        this.setState({ active: this.props.active })
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', zIndex: '99999', float: 'right' }}>
                {this.props.routes.routes.map((item, index) => {
                    return (
                        <div key={index}>
                            <styled.RightBarItem active={this.isShow(item.name)} onClick={() => { this.pushPage(item) }}><span>{item.name}</span><i style={{ display: item.routes ? '' : 'none' }}>{this.isShow(item.name) ? '-' : '+'}</i></styled.RightBarItem>
                            <div style={{ width: '100%', borderBottom: '1px solid #ebebeb', display: this.isShow(item.name) ? 'block' : 'none' }}>
                                {item.routes ? item.routes.map((route, index) => {
                                    return (
                                        <styled.RightBarItemNext style={{ color: this.props.active_next === route.name ? '#ba0f22' : '' }} key={index} onClick={() => { this.pushPage(route) }}>{route.name}</styled.RightBarItemNext>
                                    )
                                }) : ''}
                            </div>
                        </div>
                    )
                })}
                <div style={{ width: '100%', height: '16rem', border: '1px solid #ba0f22', padding: '0.5rem 1.4rem', display: this.state.code ? 'flex' : 'none', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', fontSize: '.7rem', background: '#fff', marginTop: '1rem' }}>
                    <span style={{ width: '9rem', height: '9rem', background: `url(${code2}) no-repeat`, backgroundSize: '100% 100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        {/* <span style={{ width: '2.5rem', height: '2.5rem', background: `url(${logo3}) no-repeat`, backgroundSize: '100% 100%' }} /> */}
                    </span>
                    <p>欢迎关注 “石家庄花都教育”</p>
                    <p>回复{this.state.active === '校友会' ? '校友会' : '心理健康'}与老师取得联系</p>
                </div>
            </div>
        )
    }
    isShow(s) {
        if (s === this.props.active) {
            return true
        }
        return false
    }
    //
    pushPage(i) {
        if (i.name === '招生简章') {
            let a = document.createElement('a')
            a.style.display = 'none'
            a.target = '_blank'
            a.href = zhaosheng
            a.click()
            return
        }
        if (i.name === '校友会' || i.name === '心理健康') {
            this.setState({ code: !this.state.code, active: i.name })
        } else {
            this.props.history.push({ pathname: i.path, state: i.name })
        }

    }
}
