import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
import service from '../Utlis/request'
import red from '../../public/red.jpg'
import gray from '../../public/矩形9.png'

// import logo from '../../public/logo.png'

import zhaosheng from '../../public/zhaosheng.pdf'

import { homeRoutes } from '../routes'

import v from '../../public/video.mp4'
import Videoplayer from './videoplayer'

export default class Admission extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admission: [
                { ID: 1, Title: '招生简章', Url: '' },
                { ID: 2, Title: '专业介绍', Url: '' },
                { ID: 3, Title: '报名指南', Url: '' },
                { ID: 4, Title: '中专招生', Url: '' },
                { ID: 5, Title: '培训技能', Url: '' },
                { ID: 6, Title: '单招升学', Url: '' },
            ],
            system: {
                Phone: "",
                Postcodes: "",
                ICP: "",
                ZipCode: "",
            }
        }
    }
    componentDidMount() {
        service.request({
            method: 'get',
            url: '/system',
        }).then(res => {
            if (res.code === 200) {
                this.setState({ system: res.data })
            }
        })

    }
    render() {
        return (
            <div style={{ width: '100%', height: '47.77rem', minHeight: '47.77rem', position: 'relative', padding: '0 7.5rem', marginBottom: '4.5rem' }}>
                <div style={{ width: '100%', height: '41.77rem', display: 'flex' }}>
                    <div style={{ background: `url(${gray})`, height: '100%', width: '22.72rem', zIndex: '99', padding: '5rem 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '1.8rem', padding: '0 2.77rem' }}>
                            <p style={{ fontSize: '1.22rem' }}><i style={{ color: '#ba0f22', fontSize: '1.8rem' }}>_</i>Admission Requirements</p>
                            <p><i style={{ color: '#e3e3e3' }}>_</i>招生报名</p>
                        </div>
                        <div style={{ width: '100%', height: '25rem', padding: '0 4.44rem', display: 'flex', flexDirection: 'column' }}>
                            {homeRoutes[4].routes.map((item, index) => {
                                return (
                                    <styled.AdmissionItem key={index}>
                                        <span onClick={() => {
                                            if (item.name === '招生简章') {
                                                let a = document.createElement('a')
                                                a.style.display = 'none'
                                                a.target = '_blank'
                                                a.href = zhaosheng
                                                a.click()
                                                return
                                            }
                                            this.props.history.push({ pathname: item.path, state: item.name })
                                        }}>{item.name}</span>
                                        <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                    </styled.AdmissionItem>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ flex: '1', position: 'relative' }}>
                        <div style={{ width: '100%', background: 'skyblue', position: 'absolute', left: '0', right: '0', bottom: '0', top: '4rem', zIndex: '99' }}>
                            <Videoplayer url={v} />
                        </div>
                    </div>
                </div>
                <div style={{
                    position: 'absolute', bottom: '0', left: '0', right: '0',
                    background: `url(${red})`, height: '37.22rem',
                    display: 'flex', alignItems: 'end', justifyContent: 'end', padding: '2rem 7.5rem',
                    fontFamily: 'Benmo', fontSize: '1.8rem', color: '#fff'
                }}>
                    {/* <span style={{ width: '2.22rem', height: '2.22rem', borderRadius: '50%', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1.5rem' }}><styled.Icon style={{ width: '1.1rem', height: '1.1rem' }}><use xlinkHref='#icon-xinlangweibo'></use></styled.Icon></span> */}
                    <styled.WechatCode>
                        <i>&#9650;</i>
                        <span>
                            <img src={"http://49.4.2.15:9090/" + this.state.system.Postcodes} alt='' style={{ width: '100%' }} />
                        </span>
                        <styled.Icon style={{ width: '1.1rem', height: '1.1rem' }}><use xlinkHref='#icon-weixin'></use></styled.Icon>
                    </styled.WechatCode>
                    <span style={{ width: '2.22rem', height: '2.22rem', borderRadius: '50%', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1.5rem' }}><styled.Icon style={{ width: '1.1rem', height: '1.1rem' }}><use xlinkHref='#icon-dianhua'></use></styled.Icon></span>
                    {'0311-86618000'}
                </div>

            </div>
        )
    }
}
