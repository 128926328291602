// import {ShopOutlined} from '@ant-design/icons';

import { Route, Redirect } from 'react-router-dom'

//main
import Login from '../Login';
import Regist from '../Regist';
import PageNotFound from '../PageNotFound';
import Campus_HomePage from '../campus/campus.homepage';
import Campus_News from '../campus/campus_news';
import Campus_Professional from '../campus/campus_professional';
import Campus_Activities from '../campus/campus_results';
import Campus_Environment from '../campus/campus_environment';

//home
import HomePage from '../home/homepage';
import SchoolProfile from '../home/school_profile';
import News from '../home/news';
import Departments from '../home/departments';
import Enrolling from '../home/enrolling';
import Education from '../home/education';
import StudentServices from '../home/student_service';
import Teaching from '../home/teaching';
import Activity from '../students/activity';

export const educationModules = require.context('../education/', true, /\.js$/)
export const educations = educationModules.keys().reduce((educations, modulePath) => {
    // get the module's name
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    // add the module to the modules object
    educations[moduleName] = educationModules(modulePath).default
    return educations
}, {})

export const newsModules = require.context('../news/', true, /\.js$/)
export const news = newsModules.keys().reduce((news, modulePath) => {
    // get the module's name
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    // add the module to the modules object
    news[moduleName] = newsModules(modulePath).default
    return news
}, {})
//
export const profileModules = require.context('../schoolprofile/', true, /\.js$/)
export const profile = profileModules.keys().reduce((profile, modulePath) => {
    // get the module's name
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    // add the module to the modules object
    profile[moduleName] = profileModules(modulePath).default
    return profile
}, {})
//
export const enrollingModules = require.context('../enrolling/', true, /\.js$/)
export const enrolling = enrollingModules.keys().reduce((enrolling, modulePath) => {
    // get the module's name
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    // add the module to the modules object
    enrolling[moduleName] = enrollingModules(modulePath).default
    return enrolling
}, {})
//
export const teachingModules = require.context('../teaching/', true, /\.js$/)
export const teaching = teachingModules.keys().reduce((teaching, modulePath) => {
    // get the module's name
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    // add the module to the modules object
    teaching[moduleName] = teachingModules(modulePath).default
    return teaching
}, {})

//main
export const mainRoutes = [{
    path: '/login',
    name: '登录',
    component: Login
},
{
    path: '/regist',
    name: '注册',
    component: Regist
},
{
    path: '/404',
    name: '404',
    component: PageNotFound
},
]

export const campusRoutes = [
    {
        path: '/campus_homepage/:id',
        name: '首页',
        component: Campus_HomePage
    },
    {
        path: '/campus_news/:id',
        name: '校区新闻',
        component: Campus_News
    },
    {
        path: '/campus_professional/:id',
        name: '专业设置',
        component: Campus_Professional
    },
    {
        path: '/campus_results/:id',
        name: '教学成果',
        component: Campus_Activities
    },
    {
        path: '/campus_environment/:id',
        name: '校区环境',
        component: Campus_Environment
    }
]

export const homeRoutes = [
    {
        path: '/home',
        isShow: true,
        exact: true,
        name: '首页',
        component: HomePage
    },
    {
        path: '/home/schoolprofile',
        isShow: true,
        exact: true,
        name: '学校概况',
        component: SchoolProfile,
        routes: [
            {
                path: '/home/schoolprofile/party',
                isShow: true,
                exact: true,
                name: '党团建设',
                component: profile.party,
                routes: [
                    {
                        path: '/home/schoolprofile/party/architecture',
                        isShow: true,
                        exact: true,
                        name: '组织架构',
                        component: profile.architecture,
                    },
                    {
                        path: '/home/schoolprofile/party/dynamic',
                        isShow: true,
                        exact: true,
                        name: '团委动态',
                        component: profile.dynamic,
                    },
                ]
            },
            {
                path: '/home/schoolprofile/picture',
                isShow: true,
                exact: true,
                name: '花都影像',
                component: profile.picture,
                routes: [
                    {
                        path: '/home/schoolprofile/picture/activity',
                        isShow: true,
                        exact: true,
                        name: '校园活动',
                        component: profile.picture,
                    },
                    {
                        path: '/home/schoolprofile/picture/introduce',
                        isShow: true,
                        exact: true,
                        name: '专业介绍',
                        component: profile.picture,
                    },
                    {
                        path: '/home/schoolprofile/picture/scenery',
                        isShow: true,
                        exact: true,
                        name: '校园风光',
                        component: profile.picture,
                    },
                ]
            },
            {
                path: '/home/schoolprofile/leader',
                isShow: true,
                exact: true,
                name: '领导团队',
                component: profile.leader,
            },
            {
                path: '/home/schoolprofile/distribution',
                isShow: true,
                exact: true,
                name: '校区分布',
                component: profile.distribution,
            },
            {
                path: '/home/schoolprofile/contact',
                isShow: true,
                exact: true,
                name: '联系我们',
                component: profile.contact,
            },
        ],
    },
    {
        path: '/home/news',
        isShow: true,
        exact: true,
        name: '花都新闻',
        component: News,
        routes: [
            {
                path: '/home/news/newslist1',
                isShow: true,
                exact: true,
                name: '花都头条',
                component: news.newslist,
            },
            {
                path: '/home/news/newslist2',
                isShow: true,
                exact: true,
                name: '实时动态',
                component: news.newslist,
            },
            {
                path: '/home/news/newslist3',
                isShow: true,
                exact: true,
                name: '实践活动',
                component: news.newslist,
            },
        ],
    },
    {
        path: '/home/departments',
        isShow: true,
        exact: true,
        name: '院系设置',
        component: Departments,
    },
    {
        path: '/home/enrolling',
        isShow: true,
        exact: true,
        name: '招生报名',
        component: Enrolling,
        routes: [
            {
                path: '/home/enrolling/introduction',
                isShow: true,
                exact: true,
                name: '招生简章',
                component: enrolling.introduction,
            },
            {
                path: '/home/enrolling/professional',
                isShow: true,
                exact: true,
                name: '专业介绍',
                component: enrolling.professional,
            },
            {
                path: '/home/enrolling/signUp',
                isShow: true,
                exact: true,
                name: '报名指南',
                component: enrolling.signUp,
            },
            {
                path: '/home/enrolling/admissions',
                isShow: true,
                exact: true,
                name: '中专招生',
                component: enrolling.admissions,
            },
            {
                path: '/home/enrolling/skills',
                isShow: true,
                exact: true,
                name: '技能培训',
                component: enrolling.skills,
            },
            {
                path: '/home/enrolling/riseLearn',
                isShow: true,
                exact: true,
                name: '单招升学',
                component: enrolling.riseLearn,
            },
        ]
    },
    {
        path: '/home/education',
        isShow: true,
        exact: true,
        name: '升学就业',
        component: Education,
        routes: [
            {
                path: '/home/education/through',
                isShow: true,
                exact: true,
                name: '3+3 大学直通车',
                component: educations['3+3'],
                routes: [
                    {
                        path: '/home/education/through/information',
                        isShow: true,
                        exact: true,
                        name: '升学咨询',
                        component: educations.information,
                    },
                    {
                        path: '/home/education/through/prosperity',
                        isShow: true,
                        exact: true,
                        name: '升学喜报',
                        component: educations.prosperity,
                    },
                    // {
                    //     path: '/home/education/through/academy',
                    //     isShow: true,
                    //     exact: true,
                    //     name: '院校一览',
                    //     component: educations.academy,
                    // },
                    {
                        path: '/home/education/through/example',
                        isShow: true,
                        exact: true,
                        name: '榜样力量',
                        component: educations.example,
                    },
                ]
            },
            {
                path: '/home/education/cooperation',
                isShow: true,
                exact: true,
                name: '校企合作',
                component: educations.cooperation,
            },
            {
                path: '/home/education/merge',
                isShow: true,
                exact: true,
                name: '产教融合',
                component: educations.merge,
            },
            {
                path: '/home/education/base',
                isShow: true,
                exact: true,
                name: '实训基地',
                component: educations.base,
            },
            {
                path: '/home/education/alumni',
                isShow: true,
                exact: true,
                name: '校友会',
            },
        ]
    },
    {
        path: '/home/studentservices',
        isShow: true,
        exact: true,
        name: '学生服务',
        component: StudentServices,
        routes: [
            {
                path: '/home/studentservices/activity1',
                isShow: true,
                exact: true,
                name: '通知公告',
                component: Activity,
            },
            {
                path: '/home/studentservices/activity2',
                isShow: true,
                exact: true,
                name: '教育活动',
                component: Activity,
            },
            {
                path: '/home/studentservices/activity3',
                isShow: true,
                exact: true,
                name: '学生活动',
                component: Activity,
            },
            {
                path: '/home/studentservices/activity4',
                isShow: true,
                exact: true,
                name: '奖励活动',
                component: Activity,
            },
            {
                path: '/home/education/alumni',
                isShow: true,
                exact: true,
                name: '心理健康',
            },
        ]
    },
    {
        path: '/home/teaching',
        isShow: true,
        exact: true,
        name: '教学科研',
        component: Teaching,
        routes: [
            {
                path: '/home/teaching/mien',
                isShow: true,
                exact: true,
                name: '名师风采',
                component: teaching.mien,
            },
            {
                path: '/home/teaching/activities',
                isShow: true,
                exact: true,
                name: '教学活动',
                component: teaching.activities,
            },
            {
                path: '/home/teaching/results',
                isShow: true,
                exact: true,
                name: '教学成果',
                component: teaching.results,
            },
            {
                path: '/home/teaching/star',
                isShow: true,
                exact: true,
                name: '升学明星',
                component: teaching.star,
            },
        ]
    },
]




export const matchRoutes = (routes, path) => {
    let result = null;
    routes.forEach(route => {
        if (route.path === path) {
            result = route;
        } else if (route.routes) {
            result = matchRoutes(route.routes, path);
        }
    });
    return result;
}

export const renderRoutes = (routes, extraProps = {}) => {
    return routes.map((route, index) => {
        if (route.redirect) {
            return <Redirect key={index} to={route.to} />;
        } else {
            return (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={props => {
                        const childRoutes = matchRoutes(routes, route.path);
                        return childRoutes ? (
                            <route.component {...props} {...extraProps}>
                                {renderRoutes(childRoutes, extraProps)}
                            </route.component>
                        ) : (
                            <route.component {...props} {...extraProps} />
                        );
                    }
                    }
                />
            );
        }
    });
}
export const renderRoutesMain = (Routes) => {
    return Routes.map((route, index) => {
        return (
            <div key={index}>
                < Route
                    path={route.path}
                    isShow={route.isShow}
                    exact={route.exact}
                    render={props => {
                        return <route.component {...props} />
                    }}
                />
                {route.routes && renderRoutesMain(route.routes)}
            </div>
        )
    })
}