import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Swipper from '../component/swipper'
import News from '../component/news'
import Campus from '../component/campus'
import Programs from '../component/programs'
import Admission from '../component/admission'
import Images from '../component/images'
import Footer from '../component/footer'



export default class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  //
  componentDidMount() {
  }
  render() {
    return (
      <styled.ContentBox>
        <Banner history={this.props.history} active={'首页'}/>
        <Swipper />
        <News history={this.props.history}/>
        <Campus history={this.props.history}/>
        <Programs history={this.props.history}/>
        <Admission history={this.props.history}/>
        <Images history={this.props.history}/>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


}
