import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'
import cookie from 'react-cookies'
import { homeRoutes } from '../routes'
import { Link } from 'react-router-dom'

import logo from '../../public/logo.png'
import zhaosheng from '../../public/zhaosheng.pdf'


export default class Banner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: '首页',
            campus: [{ ID: 1, Name: '红旗校区' }, { ID: 2, Name: '中华校区' }, { ID: 3, Name: '阿里山校区' }],
        }
    }
    componentDidMount() {
        this.setState({ active: this.props.active })
    }
    render() {
        return (
            <styled.Banner>
                <div style={{ justifyContent: '' }}>
                    <img src={logo} alt='' style={{ height: '5rem', width: '5rem', marginRight: '10px' }} />
                    <span style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '1.6rem', fontWeight: 'bold', letterSpacing: '2px' }}>花都形象艺术学校</p>
                        <p style={{ fontSize: '.9rem', letterSpacing: '.9px', marginTop: '.3rem' }}>HUADU IMAGE ART SCHOOL</p>
                    </span>

                </div>
                <div style={{ height: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-end' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {this.state.campus.map((item, index) => {
                            return (
                                <styled.CampusItem key={index}>
                                    <Link target='_blank' to={'/campus_homepage/' + item.ID} onClick={() => { this.pushCampus(item) }}>{item.Name}</Link>
                                    {/* <styled.Icon><use xlinkHref={index === this.state.campus.length - 1 ? '#icon-vertical_line' : '#icon-dian'}></use></styled.Icon> */}
                                    <styled.Icon style={{ display: index === this.state.campus.length - 1 ? 'none' : '' }}><use xlinkHref='#icon-dian'></use></styled.Icon>
                                </styled.CampusItem>
                            )
                        })}
                        {/* <styled.Icon style={{ marginTop: '0.625rem' }}><use xlinkHref='#icon-fangdajing'></use></styled.Icon>
                        <styled.Icon style={{ marginTop: '0.625rem', marginLeft: '0.625rem' }}><use xlinkHref='#icon-liebiao'></use></styled.Icon> */}
                    </span>
                    <span style={{ fontSize: '1.1rem', color: '#000' }}>
                        {homeRoutes.map((item, index) => {
                            return (
                                <styled.PageTitle active={this.state.active === item.name ? true : false} key={index}>
                                    <span onClick={() => { this.pushPage(item.path, item.name) }}>{item.name}</span>
                                    <styled.Down style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                    <styled.Up style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-shangla'></use></styled.Up>
                                    <styled.Drapdown style={{ display: item.routes ? '' : 'none' }}>
                                        {item.routes ? item.routes.map((route, index) => {
                                            return (
                                                <span key={index} onClick={() => { this.pushPage(route.path, route.name) }} style={{ display: route.name === '校友会' || route.name === '心理健康' ? 'none' : '' }}>{route.name}</span>
                                            )
                                        }) : ''}
                                    </styled.Drapdown>
                                    <styled.Icon style={{ left: item.name === '首页' ? '50%' : '', transform: item.name === '首页' ? 'translateX(-50%)' : '' }}><use xlinkHref='#icon-xiangshang'></use></styled.Icon>
                                </styled.PageTitle>
                            )
                        })}
                    </span>
                </div>
            </styled.Banner>
        )
    }
    /////////////////////////////////////////// //////////////////////////////////////////////////////////////////////
    pushPage(p, s) {
        if (s === '招生简章') {
            let a = document.createElement('a')
            a.style.display = 'none'
            a.target = '_blank'
            a.href = zhaosheng
            a.click()
            return
        }
        this.props.history.push({ pathname: p, state: s })
    }
    //
    pushCampus(item) {
        cookie.save('campus', '首页')
        cookie.save('campusname', item.Name)
        cookie.save('campusid', item.ID)
        return
    }
}
