import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import { homeRoutes } from '../routes'

import e1 from '../../public/e1.jpg'
import e2 from '../../public/e2.png'
import e4 from '../../public/e4.png'
import e5 from '../../public/e5.png'
import e6 from '../../public/e6.png'
import e7 from '../../public/e7.png'



export default class Through extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'升学就业'} />
                <styled.Swipper style={{ background: `url(${e1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>3 + 3 大学直通车</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>升学就业<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>3+3 大学直通车</div>
                </styled.Swipper>
                <styled.EducationBox style={{ marginTop: '5rem', justifyContent: 'space-between' }}>
                    <div style={{ width: '70%',  padding: '2rem 0', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '16', lineHeight: '2.5rem', letterSpacing: '.15rem' }}>
                        我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立交桥”。实现中专与高职院校3+3升学模式，我校设立升学部，专门配设教学经验丰富的优秀教师团队进行有针对性的授课，学生在中专阶段学习期满后，通过参加高职单招考试和对口升学考试，成绩合格升入河北女子职业学院、河北工业职业技术学院、邢台职业技术学院、秦皇岛职业技术学院、唐山工业职业技术学院、河北交通职业技术学院、保定职业技术学院、石家庄职业技术学院、保定幼儿师范高等专科学校、石家庄幼儿师范高等专科学校等院校。毕业颁发国家承认全日制普通大专毕业证书，享受普通高校毕业生待遇，且可专接本。让每一位进入我校的同学，找到自己的人生目标，实现自我人生改变。
                        <br />
                        <br />
                        招生对象 : 已通过2022年河北省普通高校招生报名，且符合所报考院校招生条件的考生。
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={'3+3 大学直通车'} history={this.props.history} routes={homeRoutes[5]} />
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ minHeight: '25rem', height: '25rem', margin: '3rem 0', background: '#f7e7e9' }}>
                    <styled.ImageItem2 img={e4} style={{ backgroundSize: '100% 100%' }}><div>名校师资团队</div></styled.ImageItem2>
                    <styled.ImageItem2 img={e5} style={{ backgroundSize: '100% 100%' }}><div>“文化素养+职业技能”相结合</div></styled.ImageItem2>
                    <styled.ImageItem2 img={e6} style={{ backgroundSize: '100% 100%' }}><div>优美的学习环境</div></styled.ImageItem2>
                    <styled.ImageItem2 img={e7} style={{ backgroundSize: '100% 100%' }}><div>高职单招，换一个赛道圆梦大学</div></styled.ImageItem2>
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '3rem' }}>
                    <div style={{ marginBottom: '.1rem', fontSize: '1.44rem' }}>管理优势</div>
                    <div style={{ fontSize: '1.11rem', color: 'rgb(136, 136, 136)', alignItems: 'center' }}>
                        <styled.Line />
                        <span style={{ margin: '0 3rem' }}>Management Advantages</span>
                        <styled.Line />
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '3rem', padding: '0 22rem' }}>
                    军事化管理，班主任24小时跟班管理，定位于精细管理。学校校内和校外周边监控网络覆盖，昼夜开展门卫执勤和校内外治安巡查巡逻，以确
                    军事化管理，班主任24小时跟班管理，定位于精细管理。学校校内和校外周边监控网络覆盖，昼夜开展门卫执勤和校内外治安巡查巡逻，以确
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '3rem' }}>
                    <div style={{ marginBottom: '.1rem', fontSize: '1.44rem' }}>办学优势</div>
                    <div style={{ fontSize: '1.11rem', color: 'rgb(136, 136, 136)', alignItems: 'center' }}>
                        <styled.Line />
                        <span style={{ margin: '0 3rem' }}>School Advantages</span>
                        <styled.Line />
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ margin: '3rem 0' }}>
                    <div style={{ width: '100%', minHeight: '17.5rem', height: '17.5rem', border: '4px solid #f6b937', borderRadius: '1rem', position: 'relative', flexDirection: 'column', alignItems: 'center' }}>
                        <styled.FontBackground style={{ transform: 'translateY(-100%)', height: '5rem' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: 'calc(100% - 6rem)', height: '5rem', transform: 'translateY(-50%)' }}>
                            <styled.FontBox>
                                <span>学</span>
                                <styled.FontCard>
                                    <span>学</span>
                                    <p>国际/国家大赛 专家评委 行业一线名师</p>
                                </styled.FontCard>
                            </styled.FontBox>
                            <styled.FontBox>
                                <span>练</span>
                                <styled.FontCard>
                                    <span>练</span>
                                    <p>国际/国家大赛 专家评委 行业一线名师</p>
                                </styled.FontCard>
                            </styled.FontBox>
                            <styled.FontBox>
                                <span>改</span>
                                <styled.FontCard>
                                    <span>改</span>
                                    <p>国际/国家大赛 专家评委 行业一线名师</p>
                                </styled.FontCard>
                            </styled.FontBox>
                            <styled.FontBox>
                                <span>管</span>
                                <styled.FontCard>
                                    <span>管</span>
                                    <p>国际/国家大赛 专家评委 行业一线名师</p>
                                </styled.FontCard>
                            </styled.FontBox>
                            <styled.FontBox>
                                <span>测</span>
                                <styled.FontCard>
                                    <span>测</span>
                                    <p>国际/国家大赛 专家评委 行业一线名师</p>
                                </styled.FontCard>
                            </styled.FontBox>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: 'calc(100% - 6rem)', transform: 'translateY(-50%)' }}>
                            <div style={{ color: '#ba0f22', fontWeight: '700', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p>50 + </p><p>名师指导</p></div>
                            <div style={{ color: '#ba0f22', fontWeight: '700', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p>80 % </p><p>技术实练</p></div>
                            <div style={{ color: '#ba0f22', fontWeight: '700', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p>1 对 1 </p><p>作业指导</p></div>
                            <div style={{ color: '#ba0f22', fontWeight: '700', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p>教育教学</p><p>双重管理</p></div>
                            <div style={{ color: '#ba0f22', fontWeight: '700', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><p>每周一测</p><p>每月一考</p></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '5rem', transform: 'translateY(60%)', alignItems: 'center', flexDirection: 'column' }}>
                            <p style={{ color: '#ba0f22', fontWeight: '700', fontSize: '1.3rem' }}>五位一体教学模式</p>
                            <p style={{ padding: '0 3rem', textAlign: 'center' }}>学校与多所大学建立教育合作交流，感召行业名师、专家加入到教学研发和学生实训中，全力督导教学，结合了文化和艺术的丰富教学资源、优</p>
                        </div>
                        <styled.FontBackground />
                    </div>
                    {/* <div style={{width:'60rem',height:'13rem',minHeight:'13rem',position:'absolute',background:'#faedd1',left:'50%',transform:'translateX(-50%)',top:'-50%'}}></div> */}
                    {/* 办学优势 */}
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '3rem' }}>
                    <div style={{ marginBottom: '.1rem', fontSize: '1.44rem' }}>阳光助学</div>
                    <div style={{ fontSize: '1.11rem', color: 'rgb(136, 136, 136)', alignItems: 'center' }}>
                        <styled.Line />
                        <span style={{ margin: '0 3rem' }}>Sunshine Grants</span>
                        <styled.Line />
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', marginBottom: '3rem', padding: '0 22rem' }}>
                    <div style={{ flexDirection: 'column', marginBottom: '2rem' }}>
                        <p>奖学金政策</p>
                        <div>· 国家奖励 : 6000元/年</div>
                        <div>· 国家助学金 : 一、二年级在校各专业家庭经济困难学生,2000元/年生活费补助</div>
                    </div>
                    <div style={{ flexDirection: 'column' }}>
                        <p>社会实践</p>
                        <div>社会实践   学校长期与政府公益部门合作，为有志磨炼自己的学生搭建社会实践的公益平台，在重要的节日和公益项目中将艺术实践与社会</div>
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
}


