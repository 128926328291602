import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
import service from '../Utlis/request'

import r from '../../public/右.png'


export default class Programs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            programs: [],
        }
    }
    componentDidMount() {
        this.getProfessional()
    }
    render() {
        return (
            <div style={{ width: '100%', height: '50rem', minHeight: '50rem', padding: '4.5rem 7.5rem', paddingBottom: '0' }}>
                <div style={{ fontSize: '1.8rem' }}>
                    <p><i style={{ color: '#ba0f22' }}>_</i>Programs of Study</p>
                    <p><i style={{ color: '#fff' }}>_</i>院系设置</p>
                </div>
                <div style={{ width: '100%', height: '30.88rem', marginTop: '2rem', display: 'flex' }}>
                    {this.state.programs.length === 0 ? '' : this.state.programs.map((item, index) => {
                        return (
                            <styled.ProgramsCard key={index} img={'http://49.4.2.15:9090/' + (item.Cover)} style={{ backgroundSize: '100% 100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <p style={{ fontSize: '1.1rem' }}>{item.Name}</p>
                                    <div style={{ width: '2.77rem', height: '1px', background: '#fff', margin: '.7rem 0' }} />
                                    <p>{item.EnglishName}</p>
                                </div>
                                <div style={{ width: '7.9rem', height: '2.2rem', borderRadius: '5px', border: '1px solid #fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span onClick={() => { this.props.history.push('/home/departments') }}>查看详情</span>
                                    <img src={r} alt='' style={{ width: '50%' }} />
                                </div>
                                <div style={{ width: '100%', height: '7.2rem', overflow: 'hidden', WebkitLineClamp: '6', display: '-webkit-box', WebkitBoxOrient: 'vertical', lineHeight: '1.2rem' }}>{item.Note}</div>
                            </styled.ProgramsCard>
                        )
                    })}
                </div>
            </div>
        )
    }
    //
    getProfessional() {
        service.request({
            method: 'get',
            url: '/department',
            params: {
                limit: 6
            },
        }).then(res => {
            if (res.code === 200) {
                this.setState({ programs: res.data })
            }
        })
    }
}
