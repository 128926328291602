import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'

import pic1 from '../../public/pic1.jpg'
import he2 from '../../public/he2.jpg'
import he3 from '../../public/he3.png'
import he4 from '../../public/he4.png'
import he5 from '../../public/he5.png'
import he6 from '../../public/he6.jpg'
import r from '../../public/右.png'

import code2 from '../../public/code.jpg'




export default class Education extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <styled.ContentBox style={{ alignItems: 'center' }}>
                <Banner history={this.props.history} active={'升学就业'} />
                <styled.Swipper
                    style={{
                        background: `url(${pic1}) no-repeat`,
                        backgroundSize: '100% 100%',
                        overflow: 'visible',
                        padding: '4.5rem 11rem',
                        flexDirection: 'column',
                        alignItems: 'end',
                        justifyContent: 'end'
                    }}>
                    <p style={{ color: '#ba0f22', fontSize: '3.33rem', fontWeight: '700', letterSpacing: '2px' }}>升学就业</p>
                    <p style={{ color: '#fff', fontSize: '2.33rem', fontWeight: '700', letterSpacing: '2px' }}>搭建 “多专业、多层次、多路径” 的成才 “立交桥”</p>
                </styled.Swipper>
                <div style={{ width: 'calc(100% - 16rem)', minHeight: '2px', background: '#b1b5c2', marginTop: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ background: '#fff', padding: '1rem', color: 'rgb(108, 108, 108)', fontSize: '1.33rem' }}>2021年升学就业情况</div>
                </div>
                <div style={{ width: 'calc(100% - 16rem)', margin: '2rem 0', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <span>
                        <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>2340</h1>
                        <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>就业人数</p>
                    </span>
                    <span>
                        <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>98.6%</h1>
                        <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>就业率</p>
                    </span>
                    <span>
                        <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>1723</h1>
                        <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>升学人数</p>
                    </span>
                </div>
                <div style={{ width: 'calc(100% - 16rem)', minHeight: '52rem', padding: '.77rem', display: 'flex' }}>
                    {/* 图片1 */}
                    <styled.ImgBackground style={{ flex: '1', background: `url(${he2}) no-repeat`, backgroundSize: '100% 100%' }}>
                        <styled.ImgBottom><span>3+3大学直通车</span><img src={r} alt='' /></styled.ImgBottom>
                        <styled.ImgMask />
                        <styled.ImgTitle>
                            <h1>3+3大学直通车</h1>
                            <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                            <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/through') }}>点击进入</span>
                        </styled.ImgTitle>
                    </styled.ImgBackground>
                    <div style={{ flex: '1.5', borderLeft: '.77rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: '.9rem', display: 'flex' }}>
                            {/* 图片2 */}
                            <styled.ImgBackground style={{ flex: '1', background: `url(${he3}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.385rem solid #fff' }}>
                                <styled.ImgBottom><span>校企合作</span><img src={r} alt='' /></styled.ImgBottom>
                                <styled.ImgMask />
                                <styled.ImgTitle>
                                    <h1>校企合作</h1>
                                    <p>学校与多所大学建立教育合作交流，感召行业名师、专家加入到教学研发和学生实训中，全力督导教学，结合了文化和艺术的丰富教学资源、优</p>
                                    <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/cooperation') }}>点击进入</span>
                                </styled.ImgTitle>
                            </styled.ImgBackground>
                            {/* 图片3 */}
                            <styled.ImgBackground style={{ flex: '1', background: `url(${he4}) no-repeat`, backgroundSize: '100% 100%', borderLeft: '.385rem solid #fff' }}>
                                <styled.ImgBottom><span>产教融合</span><img src={r} alt='' /></styled.ImgBottom>
                                <styled.ImgMask />
                                <styled.ImgTitle>
                                    <h1>产教融合</h1>
                                    <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                                    <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/merge') }}>点击进入</span>
                                </styled.ImgTitle>
                            </styled.ImgBackground>
                        </div>
                        {/* 图片4 */}
                        <styled.ImgBackground style={{ flex: '.9rem', background: `url(${he5}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.77rem solid #fff' }}>
                            <styled.ImgBottom><span>实训基地</span><img src={r} alt='' /></styled.ImgBottom>
                            <styled.ImgMask />
                            <styled.ImgTitle>
                                <h1>实训基地</h1>
                                <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                                <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/base') }}>点击进入</span>
                            </styled.ImgTitle>
                        </styled.ImgBackground>
                    </div>
                </div>
                <div style={{ width: '100%', minHeight: '26rem', background: `url(${he6}) no-repeat`, backgroundSize: '100% 100%', color: 'rgb(98, 97, 94)', display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', padding: '2rem 20rem', position: 'relative' }}>
                    <div>
                        <span style={{ letterSpacing: '2px' }}>ALUMNI ASSOCIATION<i style={{ fontStyle: 'normal', color: '#ba0f22', fontSize: '1.5rem' }}>/校友会</i></span>
                    </div>
                    <div style={{ width: '25rem' }}>
                        <p style={{ color: '#ba0f22', fontSize: '1.33rem', marginBottom: '1rem' }}>学校与多所大学建立教育合作交流</p>
                        <p style={{ fontSize: '.77rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '6', lineHeight: '1.5rem', letterSpacing: '.15rem' }}>学校与多所大学建立教育合作交流，感召行业名师、
                            专家加入到教学研发和学生实训中，全力督导教学，结
                            合了文化和艺术的丰富教学资源、优秀的教学成果、完
                            善的教育体系，为学生的升学之路、艺术之路打下坚实
                            的基础。</p>
                    </div>
                    <div>
                        {/* <styled.PushButton style={{ color: '#ba0f22' }}>+ MORE</styled.PushButton><styled.PushButton style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/alumni') }}>点击进入</styled.PushButton> */}
                    </div>
                    <div style={{ width: '11rem', height: '13rem', border: '1px solid #ba0f22', display: 'flex', padding: '0.5rem', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', fontSize: '.66rem', background: '#fff', marginTop: '1rem', position: 'absolute', left: '50%', top: '30%', transform: 'translateX(-50%)' }}>
                        <span style={{ width: '9rem', height: '9rem', background: `url(${code2}) no-repeat`, backgroundSize: '100% 100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            {/* <span style={{ width: '2.5rem', height: '2.5rem', background: `url(${logo3}) no-repeat`, backgroundSize: '100% 100%' }} /> */}
                        </span>
                        <p>欢迎关注</p>
                        <p>花都形象艺术学校 校友会</p>
                        <p>微信公众号</p>
                    </div>
                </div>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    pushPage(p) {
        this.props.history.push(p)
    }
}



