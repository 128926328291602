import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'
// import logo from '../../public/logo.png'
import Banner from '../component/banner'
import Footer from '../component/footer'
// import RightBar from '../component/rightbar'

// import { homeRoutes } from '../routes'

import e1 from '../../public/e1.jpg'
import he2 from '../../public/update13.jpg'
import he3 from '../../public/update14.jpg'
import he4 from '../../public/update15.jpg'
import he5 from '../../public/update16.png'
import he6 from '../../public/update17.jpg'
import he8 from '../../public/update18.jpg'
// import he6 from '../../public/he6.jpg'
// import ie1 from '../../public/ie1.jpg'
import r from '../../public/右.png'
import zhaosheng from '../../public/zhaosheng.pdf'


export default class Enrolling extends Component {
  constructor() {
    super()
    this.state = {
      list: [
        { Name: "化妆与形象设计", ID: 1, },
        { Name: "影视化妆方向", ID: 1, },
        { Name: "美发与形象设计", ID: 1, },
        { Name: "服装设计与表演", ID: 1, },
      ],
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'招生报名'} />
        <styled.Swipper
          style={{
            background: `url(${e1}) no-repeat`,
            backgroundSize: '100% 100%',
            overflow: 'visible',
            padding: '4.5rem 11rem',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'end'
          }}>
          <p style={{ color: '#ba0f22', fontSize: '3.33rem', fontWeight: '700', letterSpacing: '2px' }}>招生报名</p>
          <p style={{ color: '#fff', fontSize: '2.33rem', fontWeight: '700', letterSpacing: '2px' }}>搭建 “多专业、多层次、多路径” 的成才 “立交桥”</p>
        </styled.Swipper>
        <div style={{ width: 'calc(100% - 16rem)', minHeight: '2px', background: '#b1b5c2', marginTop: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ background: '#fff', padding: '1rem', color: 'rgb(108, 108, 108)', fontSize: '1.33rem' }}>2022年在校学生情况</div>
        </div>
        <div style={{ width: 'calc(100% - 16rem)', margin: '2rem 0', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <span>
            <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>2340</h1>
            <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>高职大专升学班</p>
          </span>
          <span>
            <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>389</h1>
            <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>定向培训班</p>
          </span>
          <span>
            <h1 style={{ fontSize: '3rem', color: '#ba0f22', letterSpacing: '2px' }}>1723</h1>
            <p style={{ textAlign: 'center', fontSize: '.88rem', color: 'rgb(109, 109, 109)' }}>中专普通就业班</p>
          </span>
        </div>
        <div style={{ width: 'calc(100% - 16rem)', minHeight: '52rem', padding: '.77rem', display: 'flex' }}>
          {/* 图片1 */}
          <styled.ImgBackground style={{ flex: '1', background: `url(${he2}) no-repeat`, backgroundSize: '100% 100%' }}>
            <styled.ImgBottom><span>招生简章</span><img src={r} alt='' /></styled.ImgBottom>
            <styled.ImgMask />
            <styled.ImgTitle>
              <h1>招生简章</h1>
              <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
              <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/education/introduction', "招生简章") }}>点击进入</span>
            </styled.ImgTitle>
          </styled.ImgBackground>
          <div style={{ flex: '1.5', borderLeft: '.77rem solid #fff', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: '.9rem', display: 'flex' }}>
              {/* 图片2 */}
              <styled.ImgBackground style={{ flex: '1', background: `url(${he3}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.385rem solid #fff' }}>
                <styled.ImgBottom><span>专业介绍</span><img src={r} alt='' /></styled.ImgBottom>
                <styled.ImgMask />
                <styled.ImgTitle>
                  <h1>专业介绍</h1>
                  <p>学校与多所大学建立教育合作交流，感召行业名师、专家加入到教学研发和学生实训中，全力督导教学，结合了文化和艺术的丰富教学资源、优</p>
                  <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/enrolling/professional', "专业介绍") }}>点击进入</span>
                </styled.ImgTitle>
              </styled.ImgBackground>
              {/* 图片3 */}
              <styled.ImgBackground style={{ flex: '1', background: `url(${he4}) no-repeat`, backgroundSize: '100% 100%', borderLeft: '.385rem solid #fff' }}>
                <styled.ImgBottom><span>报名指南</span><img src={r} alt='' /></styled.ImgBottom>
                <styled.ImgMask />
                <styled.ImgTitle>
                  <h1>报名指南</h1>
                  <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，搭建“多专业、多层次、多路径”的成才“立更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                  <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/enrolling/signUp', "报名指南") }}>点击进入</span>
                </styled.ImgTitle>
              </styled.ImgBackground>
            </div>
            {/* 图片4 */}
            <div style={{ flex: '.9rem', display: 'flex' }}>
              <styled.ImgBackground style={{ flex: 1, background: `url(${he6}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.77rem solid #fff' }}>
                <styled.ImgBottom><span>中专招生</span><img src={r} alt='' /></styled.ImgBottom>
                <styled.ImgMask />
                <styled.ImgTitle>
                  <h1>中专招生</h1>
                  <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                  <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/enrolling/admissions', "中专招生") }}>点击进入</span>
                </styled.ImgTitle>
              </styled.ImgBackground>
              {/* 图片5 */}
              <styled.ImgBackground style={{ flex: 1, background: `url(${he8}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.77rem solid #fff', borderLeft: '.385rem solid #fff' }}>
                <styled.ImgBottom><span>技能培训</span><img src={r} alt='' /></styled.ImgBottom>
                <styled.ImgMask />
                <styled.ImgTitle>
                  <h1>技能培训</h1>
                  <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                  <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/enrolling/skills', "技能培训") }}>点击进入</span>
                </styled.ImgTitle>
              </styled.ImgBackground>
              {/* 图片6 */}
              <styled.ImgBackground style={{ flex: 1, background: `url(${he5}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.77rem solid #fff', borderLeft: '.385rem solid #fff' }}>
                <styled.ImgBottom><span>单招升学</span><img src={r} alt='' /></styled.ImgBottom>
                <styled.ImgMask />
                <styled.ImgTitle>
                  <h1>单招升学</h1>
                  <p>我校升学部针对有升学愿望，希望获得更高层次升学教育机会,参加单招考试实现大学梦的同学，</p>
                  <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/enrolling/riseLearn', "单招升学") }}>点击进入</span>
                </styled.ImgTitle>
              </styled.ImgBackground>
            </div>
          </div>
        </div>

        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  pushPage(p, s) {
    if (s === '招生简章') {
      let a = document.createElement('a')
      a.style.display = 'none'
      a.target = '_blank'
      a.href = zhaosheng
      a.click()
      return
    }
    this.props.history.push(p)
  }
}
