import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Campus_Banner from '../component/campus_banner'
import Campus_Swipper from '../component/campus.swipper'
import Campus_News from '../component/campus_news'
import Campus_Programs from '../component/campus_programs'
import Campus_Result from '../component/campus_result'
import Campus_Footer from '../component/campus_footer'

import Footer from '../component/footer'



export default class Campus_HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    //
    componentDidMount() {
        console.log(this.props.match.params.id);
    }
    render() {
        return (
            <styled.ContentBox>
                <Campus_Banner history={this.props.history} active={'首页'} />
                <Campus_Swipper id={this.props.match.params.id} />
                <Campus_News history={this.props.history} id={this.props.match.params.id} />
                <Campus_Programs history={this.props.history} id={this.props.match.params.id} />
                {/* <Campus_Result history={this.props.history} id={this.props.match.params.id} /> */}
                <Campus_Footer id={this.props.match.params.id} />
            </styled.ContentBox>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


}
