import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'


import { homeRoutes } from '../routes'

import pp1 from '../../public/pp1.jpg'
import pp2 from '../../public/pp2.png'
import e2 from '../../public/e2.png'
// import n3 from '../../public/n3.png'
// import rr from '../../public/r右.png'

export default class Party extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        this.setState({ type: this.props.location.state })
    }
    render() {
        return (
            <styled.ContentBox style={{ alignItems: 'center' }}>
                <Banner history={this.props.history} active={'学校概况'} />
                <styled.Swipper style={{ background: `url(${pp1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>{this.props.location.state}</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>{this.props.location.state}</div>
                </styled.Swipper>
                <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between' }}>
                    <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <styled.Swipper style={{ background: `url(${pp2}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible', minHeight: '24rem', overflow: 'hidden', marginBottom: '2rem' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: '99',
                                    background: `url(${e2}) no-repeat`,
                                    backgroundSize: '100% 100%',
                                    width: '16rem',
                                    height: '3.7rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: '8rem',
                                    color: '#fff',
                                    fontSize: '1.2rem',
                                    bottom: '3.5rem',
                                    left: '-8rem',
                                    letterSpacing: '.1rem',
                                }}><span style={{ borderLeft: '1px solid #fff', paddingLeft: '1rem' }}>青年团</span></div>
                        </styled.Swipper>
                        <div style={{ overflow: 'hidden', lineHeight: '1.5rem', letterSpacing: '.15rem' }}>
                            &nbsp;&nbsp;中国共产主义青年团是中国共产党领导下的先进青年的群团组织，是青年在实践中学习中国特色社会主义和共产主义的学校，是党的助手和后备军，是党联系青年学生的桥梁和纽带。
                            <br />
                            <br />
                            &nbsp;&nbsp;共青团石家庄花都形象艺术学校委员会（以下简称校团委）是在学校党支部和上级团组织领导下，指导全校各级团组织和团员青年开展思想教育学习及活动的领导部门。
                            <br />
                            <br />
                            &nbsp;&nbsp;校团委现设有中华校区团总支部、阿里山校区信息技术专业团总支部及教工团支部。
                            <br />
                            &nbsp;&nbsp;校团委主要负责组织指导学生团总支、校学生会、学生社团联合会、青年传媒中心、青年志愿者协会五个学生组织展开工作。
                            <br />
                            <br />
                            &nbsp;&nbsp;校团委以马克思列宁主义、毛泽东思想、邓小平理论、“三个代表“重要思想、科学发展观、习近平新时代中国特色社会主义思想为指导，深入贯彻落实党关于青年工作的指导精神，以融入为中心、走进青年为主线，以“思想引领”和“成长服务”为战略，以构筑思想育人体系、加强自身建设为重点，努力实现共青团工作整体式、内涵式、品牌化发展，通过展开思想教育、校园文化、社会实践、科技创新、新媒体建设等活动，是青年学生真正成为优良校风、学风建设的参与者和“自我教育、自我管理、自我服务”的实践者，在服务学校发展大局、服务学生成长成才等方面取得较好的成效。
                            <br />
                            <br />
                            &nbsp;&nbsp;近年来，石家庄花都形象艺术学校团委获得“桥西区2020年度五四红旗团委” 、“桥西区2021年度五四红旗团委”、“石家庄市优秀抗疫青年志愿服务集体”等荣誉称号，工作上受到了上级团委和社会各界的普遍关注。
                            <br />
                            <br />
                            &nbsp;&nbsp;在未来，石家庄花都形象艺术学校团委将不断地内强素质、外树形象，围绕学校育人中心工作，进行更有力的探索实践，争取更大的成绩，为高水平职业教育建设贡献青春和智慧！
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <RightBar active={this.props.location.state} history={this.props.history} routes={homeRoutes[1]} />
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
}
