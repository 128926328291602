import 'core-js/es'
import 'react-app-polyfill/ie9'; // ie9 include ie11
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Home from './home';
import { mainRoutes, campusRoutes } from './routes/index';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Campus_Banner from './component/campus_banner'


ReactDOM.render(
  <Router>
    <Switch>
      <Route path='/home' render={routeProps => <Home {...routeProps} />} />
      {mainRoutes.map(route => {
        return <Route key={route.path} {...route} />
      })}
      {campusRoutes.map(route => {
        return <Route key={route.path} {...route} />
      })}
      <Redirect to='/home' />
    </Switch>
  </Router>,
  document.getElementById('root')
);

