import React, { Component } from 'react'
import { Map, Marker, ZoomControl, InfoWindow } from 'react-bmapgl';
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import '../Utlis/map.css'


import { homeRoutes } from '../routes'

import logo from '../../public/logo.png'
import pc1 from '../../public/pc1.jpg'
import pabcg from '../../public/pabcg.jpg'
import e2 from '../../public/e2.png'
import pl4 from '../../public/pl4.png'




export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [
        { ID: 1, Name: '红旗校区', Img: pl4, Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '15512112119', Nub3: '0311-86618000' },
        { ID: 2, Name: '中华校区', Img: pl4, Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' },
        { ID: 3, Name: '阿里山校区', Img: pl4, Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' },
        { ID: 4, Name: '博物院校区', Img: pl4, Address: '石家庄古城西路3号(肖家营花卉基地东侧)', Website: 'http://www.sjzhuadu.cn/', Nub1: '0311-86618000', Nub2: '0311-86618000', Nub3: '0311-86618000' }
      ],
      professional: [],
      Name: '',
      Age: '',
      Phone: '',
      Address: '',
      MajorName: '',
      Note: '',
    }
  }
  componentDidMount() {
    // console.log(this.props.location.state);
    service.request({
      method: 'get',
      url: '/major',
      //    params:,
    }).then(res => {
      if (res.code === 200) {
        this.setState({ professional: res.data })
      }
    })
  }
  render() {
    let info
    if (!info) {
      info = this.state.list[0]
    } else {
      info = this.matching(this.props.location.state, this.state.list)
    }
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper style={{ background: `url(${pc1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>联系我们</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '45rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>联系我们</div>
        </styled.Swipper>
        <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', background: `url(${pabcg}) no-repeat`, backgroundSize: '100% 100%', fontSize: '.88rem' }}>
          <div style={{ width: '75%', display: 'flex', flexDirection: 'column', }}>
            <div style={{ color: 'rgb(181, 185, 203)', paddingBottom: '1rem', borderBottom: '1px solid rgb(181, 185, 203)', margin: '2rem 0' }}>近三十年来学校始终坚持文化立校、特色办学、改革创新多元发展的办学宗旨，遵循“尚德、至善、存真、尚美”的校训。</div>
            <div style={{ color: 'rgb(153, 153, 153)', display: 'flex', padding: '1rem 0', justifyContent: 'space-between', width: '100%', fontSize: '.8rem' }}>
              <span style={{ letterSpacing: '.1rem', lineHeight: '2rem' }}>
                <p>地址 : {info.Address}</p>
                <p>官方网站 : {info.Website}</p>
                <p>招生就业办电话 : {info.Nub1}</p>
                <p>学籍助学金办电话 : {info.Nub2}</p>
                {/* <p>学校办公室电话 : {info.Nub3}</p> */}
              </span>
              <span style={{ letterSpacing: '.1rem', lineHeight: '2rem' }}>
                <p>乘车路线</p>
                <p>火车站：乘117路换乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                <p>火车站北站线路：乘164路换乘18路公交车至汇春博物园站下车（斜对面）</p>
                <p>白佛客运站线路：乘28路换乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                <p>运河桥客运站线路：乘18路公交车至汇春博物园站下车（斜对面即到）</p>
                <p>南焦客运站线路：乘106路换乘18路公交车至汇春博物园站下车（斜对面）</p>
              </span>
            </div>
            <styled.FormCard >
              <div>
                <p>预约报名</p>
                <span style={{ justifyContent: 'center' }}>我们会第一时间与您取得联系</span>
              </div>
              <div style={{ marginTop: '1rem', }}>
                <span style={{ marginRight: '2rem' }}>
                  <p><span>姓名</span> <styled.FormInput name='Name' value={this.state.Name} onChange={this.onInput.bind(this)} /></p>
                  <p><span>年龄</span> <styled.FormInput name='Age' value={this.state.Age} onChange={this.onInput.bind(this)} /></p>
                  <p><span>电话</span> <styled.FormInput name='Phone' value={this.state.Phone} onChange={this.onInput.bind(this)} /></p>
                  <button onClick={() => { this.submit() }} style={{ width: '6.6rem', height: '1.5rem', color: '#fff', background: '#ba0f22', border: 'none', marginTop: '2rem', marginLeft: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    提交
                  </button>
                </span>
                <span style={{ alignItems: '' }}>
                  <p><span>地址</span><styled.FormInput name='Address' value={this.state.Address} onChange={this.onInput.bind(this)} /></p>
                  <p>
                    <span>预约专业</span>
                    <select name='MajorName' value={this.state.MajorName} onChange={this.onInput.bind(this)} >
                      {this.state.professional.map((item, index) => {
                        return (
                          <option key={index} value={item.Name} >{item.Name}</option>
                        )
                      })}
                    </select>
                  </p>
                  <p style={{ alignItems: 'normal' }}><span style={{ marginTop: '.5rem' }}>备注</span><textarea name='Note' value={this.state.Note} onChange={this.onInput.bind(this)} /></p>
                </span>
              </div>
            </styled.FormCard>
          </div>
          <div style={{ width: '20%' }}>
            <RightBar active={'联系我们'} history={this.props.history} routes={homeRoutes[1]} />
          </div>
        </styled.EducationBox>
        <div style={{ width: '100%', height: '24rem' }} className='own'>
          <Map
            style={{ width: '100%', height: '24rem' }}
            center={{ lng: 114.497277, lat: 38.112241 }} zoom="11"
            enableScrollWheelZoo
            onClick={e => console.log(e)}
          >
            <ZoomControl />
            <Marker position={{ lng: 114.497277, lat: 38.112241 }} />
            <InfoWindow position={{ lng: 114.497277, lat: 38.112241 }} title={null} height={80} width={380}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <img src={logo} alt='' style={{ height: '4rem', width: '4rem', borderRadius: '50%', marginRight: '.5rem' }} />
                <span style={{ whiteSpace: 'nowrap' }}><p>花都形象艺术学院</p><p>{info.Name} 欢迎您!</p><p style={{ whiteSpace: 'wrap' }}>{info.Address}</p></span>
              </div>
            </InfoWindow>
            <div style={{ width: '100%', height: '24rem', zIndex: '-1' }} />
          </Map>
        </div>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  ////////////////////////////////////////////////////////////////
  matching(id, arr) {
    let result = arr.find(obj => {
      if (obj.ID === id) {
        return obj
      }
      return null
    })
    return result
  }
  ////////////////////////////////////////////////////////////////
  onInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  //
  submit() {
    if (this.state.Name === '') {
      alert('请填写您的姓名 !')
      return;
    }
    if (this.state.Phone === '') {
      alert('请填写您的电话 !')
      return;
    }
    service.request({
      method: 'post',
      url: '/apply',
      data: {
        Name: this.state.Name,
        Age: Number(this.state.Age),
        Phone: this.state.Phone,
        Address: this.state.Address,
        MajorName: this.state.MajorName,
        Note: this.state.Note,
      }
    }).then(res => {
      if (res.code === 200) {
        alert('提交成功 稍后我们将联系您!')

      }
    })
  }
}

