import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import { homeRoutes } from '../routes'

import e1 from '../../public/e1.jpg'
import e2 from '../../public/e2.png'
// import e4 from '../../public/e4.png'
// import e5 from '../../public/e5.png'
// import e6 from '../../public/e6.png'
// import e7 from '../../public/e7.png'
import pabcg from '../../public/pabcg.jpg'
import nb2 from '../../public/nb2.png'



export default class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'招生报名'} />
                <styled.Swipper style={{ background: `url(${e1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>报名指南</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>招生报名<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>报名指南</div>
                </styled.Swipper>
                <styled.EducationBox style={{ paddingTop: '5rem',paddingBottom: '5rem',justifyContent: 'space-between', background: `url(${pabcg}) repeat`, backgroundSize: '100% 100%', }}>
                    <div style={{ width: '70%', height: 'calc(100% + 5rem)', padding: '2rem 0', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', lineHeight: '1.5rem', letterSpacing: '.15rem' }}>
                      <div style={{fontSize:"1.61rem",fontWeight:"bolder"}}>报名指南</div>
                      <div style={{width:"3.5rem",height:"0.22rem",background:"#cf1818",margin:"1rem 0 1rem 0"}}></div>
                      <div style={{fontSize:"0.77rem"}}>轻松学技能 圆你大学梦!</div>
                      <div style={{width:"4.57rem",height:"1.38rem",fontSize:"0.77rem",marginTop:"1rem",fontWeight:"bolder",color:"#fff",textAlign:"center",lineHeight:"1.38rem", background: `url(${nb2}) no-repeat`,backgroundSize: '100% 100%',}}>报考须知</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>1、招生对象:初中及初中以上学历（面向全国招生）；</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>2、学前教育专业录取条件：身体匀称、五官端正、口齿清晰、听力正常、无色盲、色弱；</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>3、航空高铁专业录取条件：男身高不低于170cm、女不低于160cm、视力正常、身体健康、无重大疾病及传染病、无纹身、形象端庄气质佳、无“X”及“O”型腿；</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}> 4、报名和录取：应、往届初、高中毕业生也可持身份证或户口本复印件3张，近期1寸免冠照片8张，直接到我校报名由学校统一办理网上注册录取手续；</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}> 5、毕业待遇：</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>(1) 中专毕业后，颁发由石家庄教育局验印的中专毕业证书，国家承认学历，可参加相应的资格考试；</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>(2) 初中起点全日制统招大专班毕业后，由升入全日制大专学院领发全日制大专毕业证，高职专科毕业生经考试可“专接本”。</div>
                      <div style={{width:"8.6rem",height:"1.38rem",marginTop:"1rem",fontSize:"0.77rem",fontWeight:"bolder",color:"#fff",textAlign:"center",lineHeight:"1.38rem", background: `url(${nb2}) no-repeat`,backgroundSize: '100% 100%',}}>国家奖励优惠政策</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>1、所有农村户口生源地学生可享受国家免学费政策，每生每年减免学费 <span style={{color:"#c91427"}}>2000</span>元，享受3年共 <span style={{color:"#c91427"}}>6000</span>元。</div>
                      <div style={{fontSize:"0.77rem",marginTop:"1rem"}}>2、家庭经济困难学生按照比例可享受国家助学金补助 <span style={{color:"#c91427"}}>2000</span>元/年。</div>
                      <div style={{fontSize:"0.77rem",margin:"1rem 0 1rem 0"}}>3、品学兼优的学生可获得国家励志奖学金 <span style={{color:"#c91427"}}>6000</span>元/生。</div>
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={'报名指南'} history={this.props.history} routes={homeRoutes[4]}/>
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
}


