import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'
import Videoplayer from '../component/videoplayer'
import { homeRoutes } from '../routes'

import pic1 from '../../public/pic1.jpg'
import cibankground from '../../public/cibankground.jpg'
import e2 from '../../public/e2.png'

import p from '../../public/picture.jpg'
import p1 from '../../public/picture1.jpg'
import p2 from '../../public/picture2.jpg'
import p3 from '../../public/picture3.png'
import p4 from '../../public/picture4.png'
import p5 from '../../public/picture5.jpg'
import v from '../../public/v2.mp4'
import v1 from '../../public/video1.mp4'

import pp from '../../public/picture7.jpg'
import pp1 from '../../public/picture8.jpg'
import pp2 from '../../public/picture6.jpg'
import pp3 from '../../public/picture10.jpg'
import pp4 from '../../public/picture11.jpg'
import pp5 from '../../public/picture12.jpg'
import pv from '../../public/美发店长证言.mp4'
import pv1 from '../../public/逆时光.mp4'
// import pv1 from '../../public/逆时光学子证言1.mp4'

import ppp from '../../public/picture14.jpg'
import ppp1 from '../../public/picture15.jpg'
import ppp2 from '../../public/picture13.jpg'
import ppp3 from '../../public/picture16.jpg'
import ppp4 from '../../public/picture17.jpg'
import ppp5 from '../../public/picture18.png'
import ppv from '../../public/逆时光.mp4'
import ppv1 from '../../public/逆时光.mp4'
// import ppv1 from '../../public/幼师就业环境.mp4'

export default class Pictrue extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgs: [
      ],
      types: [{ ID: 1, Name: '校园活动' }, { ID: 2, Name: '专业介绍' }, { ID: 3, Name: '校园风光' }],
      typeID: 1,
      pic_arr1:[p,p1,p2,p3,p4,p5,v,v1],
      pic_arr2:[pp2,pp1,pp,pp3,pp4,pp5,pv,pv1],
      pic_arr3:[ppp,ppp1,ppp2,ppp3,ppp4,ppp5,ppv,ppv1],
    }
  }
  componentDidMount() {
    if (this.props.location.state === '校园活动') { this.setState({ typeID: 1 }) }
    if (this.props.location.state === '专业介绍') { this.setState({ typeID: 2 }) }
    if (this.props.location.state === '校园风光') { this.setState({ typeID: 3 }) }
  }
  render() {
    let pic_arr=[];
    if(this.state.typeID === 1) {pic_arr=this.state.pic_arr1;}
    if(this.state.typeID === 2) {pic_arr=this.state.pic_arr2;}
    if(this.state.typeID === 3) {pic_arr=this.state.pic_arr3;}
    return (
      <styled.ContentBox>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper style={{ background: `url(${pic1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>花都影像</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '53rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>花都影像</div>
        </styled.Swipper>
        <styled.EducationBox style={{ minHeight: '65rem', marginTop: '5rem', justifyContent: 'space-between', position: 'relative', background: `url(${cibankground}) no-repeat`, backgroundSize: '100% 100%' }}>
          <div style={{ width: '70%', height: 'calc(100% - 4rem)', padding: '1rem 0', flexDirection: 'column' }}>
            <div style={{ width: '100%', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '.72rem', marginBottom: '1rem', color: 'rgb(58, 58, 58)' }}>
              <span style={{ fontSize: '.88rem', display: 'flex', alignItems: 'center' }}><i style={{ width: '2rem', height: '2px', background: '#ba0f22', display: 'inline-block', marginRight: '.5rem' }} />{this.matching(this.state.typeID, this.state.types)}</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {this.state.types.map((item, index) => {
                  return (
                    <div key={index}>
                      <styled.HoverBox onClick={() => { this.setState({ typeID: item.ID }, () => { this.getImages(item.ID) }) }} style={{ color: this.state.typeID === item.ID ? '#ba0f22' : '' }}>{item.Name}</styled.HoverBox>
                      {this.state.types.length - 1 === index ? '' : <span style={{ margin: '0 1rem' }}>/</span>}
                    </div>
                  )
                })}
              </span>
            </div>
            {/* 图片集 */}
            {/* <styled.ImgBackground style={{ flex: '1', background: `url(${he3}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.385rem solid #fff' }}></styled.ImgBackground> */}
            <div style={{ flex: '1', display: 'flex', boxShadow: '0px 28px 34.83px 8.17px rgba(74, 74, 74, 0.26)' }}>
              {/* 左 */}
              <div style={{ flex: '2', borderRight: '0.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                {/* 上 */}
                <div style={{ flex: '2', display: 'flex', borderBottom: '.25rem solid #fff' }}>
                  <styled.ImgBackground style={{ flex: '1',  borderRight: '.25rem solid #fff' }}>
                    <img src={pic_arr[2]} alt='' style={{width: '100%',height:'100%'}}/>
                  </styled.ImgBackground>
                  <div style={{ flex: '1', borderLeft: '.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                    <styled.ImgBackground style={{ flex: '1', borderBottom: '.25rem solid #fff' }}>
                    <img src={pic_arr[1]} alt='' style={{width: '100%',height:'100%'}}/>
                    </styled.ImgBackground>
                    <styled.ImgBackground style={{ flex: '1',  borderTop: '.25rem solid #fff' }}>
                    <img src={pic_arr[0]} alt='' style={{width: '100%',height:'100%'}}/>
                    </styled.ImgBackground>
                  </div>
                </div>
                {/* 中 */}
                <div style={{ flex: '1', display: 'flex', borderBottom: '.25rem solid #fff', borderTop: '.25rem solid #fff' }}>
                  <styled.ImgBackground style={{ flex: '1',  borderRight: '.25rem solid #fff' }}>
                  <img src={pic_arr[3]} alt='' style={{width: '100%',height:'100%'}}/>
                  </styled.ImgBackground>
                  <styled.ImgBackground style={{ flex: '1',  borderLeft: '.25rem solid #fff' }}>
                  <img src={pic_arr[4]} alt='' style={{width: '100%',height:'100%'}}/>
                  </styled.ImgBackground>
                </div>
                {/* 下 */}
                <styled.ImgBackground style={{ flex: '2',  borderTop: '.25rem solid #fff' }}>                     
                <img src={pic_arr[5]} alt='' style={{width: '100%',height:'100%'}}/>
                </styled.ImgBackground>
              </div>
              {/* 右 */}
              <div style={{ flex: '1', borderLeft: '0.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                {/* 上 */}
                <styled.ImgBackground style={{ flex: '2', borderBottom: '.25rem solid #fff' }}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <Videoplayer url={pic_arr[6]} />
                  </div>
                </styled.ImgBackground>
                {/* 中 */}
                <styled.ImgBackground style={{ flex: '2', borderBottom: '.25rem solid #fff', borderTop: '.25rem solid #fff' }}>               
                   <div style={{ width: '100%', height: '100%' }}>
                    <Videoplayer url={pic_arr[7]} />
                  </div>
                </styled.ImgBackground>
                {/* 下 */}
                <styled.ImgBackground style={{ flex: '1', borderTop: '.25rem solid #fff', background: '#ba0f22' }}>
                  <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>敬请期待</p>
                  <p style={{ fontSize: '.83rem' }}>COMING SOON</p>
                </styled.ImgBackground>
              </div>
            </div>
          </div>
          <div style={{ width: '20%', height: '100%' }}>
            <RightBar active={'花都影像'} active_next={this.matching(this.state.typeID, this.state.types)}  history={this.props.history} routes={homeRoutes[1]} />
          </div>
          {/* <styled.HoverBox style={{ position: 'absolute', bottom: '2rem', left: '45%', fontSize: '.84rem', color: '#ba0f22', letterSpacing: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div>MORE</div>
            <styled.Icon><use xlinkHref='#icon-jiantouxia'></use></styled.Icon>
          </styled.HoverBox> */}
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  /////////////////////////////////////////////////////////////
  getImages(id) {
    // service.request({
    //   method: 'get',
    //   url: '',
    //   params: {
    //     typeID: this.state.id,
    //   }
    // }).then(res => {
    //   if (res.data.code === 200) {
    //     this.setState({ imgs: res.data.data})
    //   }
    // })
  }
  //
  matching(id, arr) {
    let result = arr.find(obj => {
      if (obj.ID === id) {
        return obj
      }
      return null
    })
    return result.Name
  }
}
