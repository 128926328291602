import React, { Component } from 'react'
import * as styled from '../Utlis/styled'

import cookie from 'react-cookies'
import '../../public/iconfont'
import service from '../Utlis/request'

import news1 from '../../public/news1.png'
import blue3 from '../../public/blue3.png'
import r from '../../public/右.png'


export default class Campus_Programs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            programs: [{ Name: '' }],
            index: 0,
        }
    }
    componentDidMount() {
        this.getProfessional()
    }
    render() {
        return (
            <div style={{ width: "100%", minHeight: '40rem', background: `url(${news1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', padding: '5rem', display: "flex", flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                <div style={{ fontSize: '2rem', letterSpacing: '2px' }}>专业设置</div>
                <div style={{ fontSize: '1.4rem', color: "#260ea2", letterSpacing: '2px', fontWeight: "600", margin: "1rem 0" }}>
                    {this.state.programs[this.state.index].Name}
                </div>
                <div style={{ width: '100%', overflow: "hidden", position: 'relative' }}>
                    <div id='swipper' style={{ display: 'flex', flexWrap: 'nowrap', padding: '0rem', position: 'relative', transform: 'translateX(0)', transition: 'all 1s' }}>
                        {this.state.programs.map((item, index) => {
                            return (
                                <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_professional/' + this.props.id }); cookie.save('campus', "专业设置") }} style={{ display: 'inline-block', height: '12.5rem', minWidth: "calc(20% - 1rem)", background: `url(${blue3}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', margin: '0 0.5rem', display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '2.5rem 0', transform: this.state.index === index ? 'scale(1.1)' : "" }}>
                                    <p style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", textAlign: "center", fontSize: "1.33rem", color: "#fff", fontWeight: "600", letterSpacing: "2px" }}>{item.Name}</p>
                                    <div style={{ width: '2.5rem', height: '4px', background: this.state.index === index ? "#ffc422" : '#fff' }} />
                                </styled.HoverBox1>
                            )
                        })}
                    </div>
                </div>
                <div style={{ marginTop: "3rem", padding: "0 10rem", display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "7", lineHeight: "1.5em", overflow: "hidden", fontSize: '.88rem', color: 'rgb(109, 109, 109)', textAlign: 'center' }}>{this.state.programs[this.state.index].Note}</div>
                <styled.HoverIcon2 onClick={() => { this.pre() }} style={{ height: "3rem", width: "2rem", position: 'absolute', left: '3rem', top: "45%", transform: "translateY(-50%)" }}><use xlinkHref='#icon-qiehuanqizuo'></use></styled.HoverIcon2>
                <styled.HoverIcon2 onClick={() => { this.next() }} style={{ height: "3rem", width: "2rem", position: 'absolute', right: '3rem', top: "45%", transform: "translateY(-50%)" }}><use xlinkHref='#icon-qiehuanqiyou'></use></styled.HoverIcon2>
            </div>
        )
    }
    //
    pre() {
        var swipper = document.getElementById('swipper')
        if (this.state.index === 0) {
            return
        }
        // if (this.state.index % 5 != 5) {
        //     swipper.style.left = "20%"
        // }
        this.setState({ index: this.state.index - 1 })
    }
    next() {
        var swipper = document.getElementById('swipper')
        if (this.state.index === 4) {
            return
        }
        // if (this.state.index > 4 || this.state.index === 4) {
        //     console.log("sssssssssssssss");
        //     swipper.style.background = "skyblue"
        //     swipper.style.transform = "translateX(-20%)"
        // }
        this.setState({ index: this.state.index + 1 })
    }
    //
    getProfessional() {
        service.request({
            method: 'get',
            url: '/department',
            params: {
                limit: 6,
                campusID: this.props.id
            },
        }).then(res => {
            if (res.code === 200) {
                this.setState({ programs: res.data })
            }
        })
    }
}
