import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'


import { homeRoutes } from '../routes'

import pp1 from '../../public/pp1.jpg'
import pabcg from '../../public/pabcg.jpg'
import e2 from '../../public/e2.png'
import pa1 from '../../public/pa1.png'
// import rr from '../../public/r右.png'
import jiagou from '../../public/jiagou.png'


export default class Architecture extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper style={{ background: `url(${pp1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>组织架构</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '45rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>党团建设<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>组织架构</div>
        </styled.Swipper>
        <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', background: `url(${pabcg}) no-repeat`, backgroundSize: '100% 100%', }}>
          <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{width:'41.61rem',height:'34.88rem',marginBottom:"5rem", marginTop:"2rem",background: `url(${jiagou}) no-repeat`,backgroundSize: '100% 100%',}}/>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <span style={{color:'#ba0f22',fontSize:'.77rem',marginRight:'2rem'}}>
                <p style={{fontSize:'1rem',fontWeight:'bold',marginBottom:'1rem'}}>石家庄花都形象艺术学校团委荣誉</p>
                <p style={{marginBottom:'.3rem'}}>2020年荣获“石家庄市优秀抗疫青年志愿服务集体”称号</p>
                <p style={{marginBottom:'.3rem'}}>2020年度桥西区五四红旗团委</p>
                <p style={{marginBottom:'.3rem'}}>2021年度桥西区五四红旗团委</p>
               </span>
               <span style={{width:'20rem',height:'13rem', background: `url(${pa1}) no-repeat`,backgroundSize: '100% 100%',}}/>
            </div>
          </div>
          <div style={{ width: '20%' }}>
            <RightBar active={'党团建设'} active_next={'组织架构'}  history={this.props.history} routes={homeRoutes[1]} />
          </div>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
}
