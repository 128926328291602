import React, { Component } from 'react'

import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'
import Pagination from '../component/pagination'


import { homeRoutes } from '../routes'

import nl1 from '../../public/nl1.jpg'
import e2 from '../../public/e2.png'
import rr from '../../public/r右.png'


export default class NewsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            page: 1,
            count: 0,
            visible: false,
            content: {},
            type: ''

        }
    }
    componentDidMount() {
        if (this.props.location.article) {
            this.showContent(true, this.props.location.article)
        }
        this.setState({ type: this.props.location.state })
        this.getList()
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'花都新闻'} />
                <styled.Swipper style={{ background: `url(${nl1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>{this.props.location.state}</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>花都新闻<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>{this.props.location.state}</div>
                </styled.Swipper>
                <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between' }}>
                    <div style={{ width: '70%', height: 'calc(100% - 4rem)', padding: '2rem 0', display: this.state.visible ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ padding: '1rem', borderTop: '2px solid #ccc', width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {this.state.list.map((item, index) => {
                                return (
                                    <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxHeight: '10rem', borderBottom: '2px dotted #ccc', padding: '1rem 0', position: 'relative' }}>
                                        <img src={'http://49.4.2.15:9090/' + item.Cover} alt='' style={{ display: item.Cover === '' ? 'none' : 'block', maxWidth: '12rem', maxHeight: '10rem', marginRight: '2rem' }} />
                                        <styled.NewsCard onClick={() => { this.showContent(true, item) }} style={{ flex: '1', overflow: 'hidden' }}>
                                            <span style={{ width: '100%' }}>
                                                <p style={{ width: '100%', color: '#ba0f22', fontSize: '1rem', fontWeight: 'bold', marginBottom: '1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                                <p style={{  maxWidth: '90%',fontSize: '.9rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                            </span>
                                        </styled.NewsCard>
                                        <styled.HoverBox style={{ position: 'absolute', bottom: '1rem', right: '0' }}><img src={rr} alt='' /></styled.HoverBox>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ paddingTop: '2rem' }}>
                            <Pagination page={this.state.page} max={3} onPaging={(page) => { this.setState({ page }, () => { this.getList() }) }} count={this.state.count} />
                        </div>
                    </div>
                    <div style={{ width: '70%', height: 'calc(100% - 4rem)', padding: '2rem 0', display: this.state.visible ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {/* 正文 */}
                        <div style={{ width: '100%', padding: '1rem 0', color: '#ba0f22', textAlign: 'center', borderBottom: '1px solid #ccc', }}>{this.state.content.Title}</div>
                        <div style={{ width: '100%', padding: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '.66rem' }}>
                            <styled.HoverBox onClick={() => { this.showContent(false, {}) }} style={{ color: '#ba0f22' }}><span >返回</span></styled.HoverBox>
                            <span>上传时间 : {this.formatDate(this.state.content.Time / 1000000)}</span>
                        </div>
                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: this.state.content.Content }} />
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={this.props.location.state} history={this.props.history} routes={homeRoutes[2]} />
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
    // overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '16', lineHeight: '1.5rem', letterSpacing: '.15rem' 
    //
    getList() {
        var type
        if (this.props.location.state === '花都头条') {
            type = 1
        }
        if (this.props.location.state === '实时动态') {
            type = 2
        }
        if (this.props.location.state === '实践活动') {
            type = 3
        }
        let offset = (this.state.page - 1) * 6
        service.request({
            method: 'get',
            url: '/article',
            params: {
                categoryID: type,
                offset: offset,
                limit: 6,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ list: res.data, count: res.count })
            }
        })
    }
    //////////////////////
    formatDate(dates) {
        var date = new Date(dates);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        return y + '-' + m + '-' + d
    }
    //
    showContent(flag, item) {
        this.setState({ visible: flag, content: item })
    }
    getSimpleText(html) {
        if (!html) { return }
        var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
        var m = rx.exec(html);
        if (m) {
            m = m[1];
        } else {
            m = html
        }
        var re1 = new RegExp("<.+?>", "g")
        var msg = m.replace(re1, '')
        msg = msg.replace(/&nbsp;/g, ' ')
        return msg
    }
}
