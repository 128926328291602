import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'


import { homeRoutes } from '../routes'

import pl1 from '../../public/pl1.jpg'
import pl2 from '../../public/pl2.png'
import pl3 from '../../public/pl3.png'
import pabcg from '../../public/pabcg.jpg'
import e2 from '../../public/e2.png'
// import pa1 from '../../public/pa1.png'
// import rr from '../../public/r右.png'

export default class Leader extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper style={{ background: `url(${pl1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>领导团队</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '45rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>领导团队</div>
        </styled.Swipper>
        <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', background: `url(${pabcg}) no-repeat`, backgroundSize: '100% 100%', }}>
          <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <styled.FontStyles>
              <div style={{ float: 'left', clear: 'both', position: 'relative', overflow: 'hidden', margin: '0 3rem 1rem 0' }} align='center'>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '99',
                    background: `url(${e2}) no-repeat`,
                    backgroundSize: '100% 100%',
                    width: '16rem',
                    height: '3.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '8rem',
                    color: '#fff',
                    fontSize: '1rem',
                    bottom: '3.5rem',
                    left: '-8rem',
                    letterSpacing: '.1rem',
                  }}><span style={{ borderLeft: '1px solid #fff', paddingLeft: '1rem' }}><p style={{lineHeight:'1.2rem'}}>张峰</p><p style={{lineHeight:'1.2rem'}}>董事长</p></span></div>
                <img src={pl2} align="left" style={{ width: '16.88rem', height: '24.22rem', }} hspace="8" vspace='8' />
              </div>
              <p style={{ color: '#ba0f22', fontWeight: 'bold', marginTop: '2rem' }}>主要经历</p>
              <p>1990年-1994年  从事美容美发行业</p>
              <p>1994年-2008年  创办花都美发化妆职业技术培训学校任校长</p>
              <p>2008年-2011年  成立石家庄花都职业技术中专学校任校长</p>
              <p>2011年-2018年  学校更名为石家庄花都形象艺术学校任校长</p>
              <p>2018年-2019年  成立河北女子职业技术学院花都校区任院长</p>
              <p>2019年-2020年  成立石家庄花都技工学校任校长</p>
              <p>2020年-至今      成立花都教育集团任董事长</p>
              <p style={{ color: '#ba0f22', fontWeight: 'bold', marginTop: '2rem' }}>主要社会职务</p>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;现任石家庄桥西区人大常委、石家庄市人大代表；石家庄桥西区工商联副主席；石家庄桥西区社会救助基金会副会长；石家庄市商业联合会常务理事；河北省美容美发协会常务会长，1994年至今，连续担任河北省美发大赛评委、中国美容美发大赛发型评委等；被亚洲美业文化交流协会、亚洲美业金紫荆大奖盛典组委会聘为：亚洲美业金紫荆大奖盛典中国区副会长、专家评审委员会主任委员；并曾担任第九届世界华人大赛评判长等。</span>
              {/* <img src={pl2} align="left" style={{ width: '16.88rem', height: '24.22rem' }} /> */}
            </styled.FontStyles>
            <styled.FontStyles>
              <p style={{ color: '#ba0f22', fontWeight: 'bold', marginTop: '2rem' }}>主要社会荣耀</p>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;石家庄桥西区优秀人大代表；石家庄市优秀人大代表；石家庄市教育局“先进教育工作者”；获得中共石家庄市委办公厅、石家庄市人民政府办公厅颁布的石家庄市“优秀高级技师”的称号；连续多年荣获石家庄市人力资源和社会保障局“先进个人”称号；被中共桥西区红旗街道工作委员会评为“最美社区之星”；被石家庄市新华区工商联评为新华区爱心慈善民营企业家；曾被河北日报报业集团、燕赵教育网评为河北省年度“十大教育培训风云人物”；被中国民办教育联合会授予“全国民办教育杰出管理者”；被石家庄市职业技能教研鉴定指导中心聘请为美发师职业（工种）国家职业技能鉴定考评员；2020年、2021年连续被桥西区红十字会授予“抗击疫情优秀志愿者”称号等。</span>
            </styled.FontStyles>
            <styled.FontStyles>
              <div style={{ float: 'right', clear: 'both', position: 'relative', overflow: 'hidden', margin: '3rem 0 1rem 2rem' }} align='center'>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '99',
                    background: `url(${e2}) no-repeat`,
                    backgroundSize: '100% 100%',
                    width: '16rem',
                    height: '3.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '8rem',
                    color: '#fff',
                    fontSize: '1rem',
                    bottom: '3.5rem',
                    left: '-8rem',
                    letterSpacing: '.1rem',
                  }}><span style={{ borderLeft: '1px solid #fff', paddingLeft: '1rem' ,}}><p style={{lineHeight:'1.2rem'}}>李春芳</p><p style={{lineHeight:'1.2rem'}}>校长</p></span></div>
                <img src={pl3} align="left" style={{ width: '16.88rem', height: '24.22rem', }} hspace="8" vspace='8' />
              </div>
              <p style={{ color: '#ba0f22', fontWeight: 'bold', marginTop: '2rem' }}>创始人说</p>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;从自己成为一名技术工作者、传授技术到目前成立中等职业学校，花都带给学生的不只是专业技术，更有今后可以立足社会的学历教育。特别是在艺术学院成立后，打通了从中专到大专的升学通道，让每一名中专学生都有升学大专学校的机会。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;我希望把学生从学习外在美的技术，提升到美的文化、美的内涵、提升人文素养、对美有深层次的理解能适应社会发展。<br />
                &nbsp;&nbsp;&nbsp;&nbsp;这也是我在做美业教育30年的初心，发现美、创造美、传播美、提升人们对美的认识境界，专注美业教育，将工匠精神传播下去，坚持初心，让我们的梦想变得更远大，希望下一代享受美的生活。</span>
              {/* <img src={pl2} align="left" style={{ width: '16.88rem', height: '24.22rem' }} /> */}
            </styled.FontStyles>
            <styled.FontStyles>
            <p style={{ color: '#ba0f22', fontWeight: 'bold'}}>主要社会职务</p>
              <p>中国民主促进会石家庄民进成员</p>
              <p>石家庄市长安区政协委员</p>
              <p>中国美发美容协会国家级评委</p>
              <p>中国美发美容协会国家级裁判委员会裁判长</p>
              <p>河北省美发美容协会秘书长</p>
              <p>河北女子职业技术学院艺术设计学院执行院长</p>
              <p>石家庄花都形象艺术学校总校长</p>
            </styled.FontStyles>
          </div>
          <div style={{ width: '20%' }}>
            <RightBar active={'领导团队'} history={this.props.history} routes={homeRoutes[1]} />
          </div>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
}
