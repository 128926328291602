import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
// import service from '../Utlis/request'

import img from '../../public/campus.jpg'
import c1 from '../../public/xq1.jpg'
import c2 from '../../public/xq2.jpg'
import c3 from '../../public/xq3.jpg'
import c4 from '../../public/xq4.jpg'
import t from '../../public/t.png'

export default class Campus extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div style={{ position:'relative',display: 'flex', width: '100%', height: '25rem',minHeight: '25rem', color: '#2a2a2a', background: `url(${img}) no-repeat`, backgroundSize: '100% 100%', padding: '3.8rem 4rem' }}>
                {/* <styled.Img src={t} alt=''/> */}
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>                    
                    <span style={{ flex: '1', borderLeft: '0.5rem solid transparent', borderTop: '0.5rem solid transparent', display: 'flex' }}>
                        {/* 分校4 */}
                        <span style={{ flex: '1', background: '#fff', paddingRight: '1rem', display: 'flex' }}>
                            <styled.CampusImg style={{ flex: '1' }}><i style={{ height: '100%', background: `url(${c1}) no-repeat`, backgroundSize: '100% 100%'}} /></styled.CampusImg>
                            <styled.CampusImg style={{ flex: '.8', paddingTop: '2rem', paddingBottom: '2rem', paddingRight: '0' }}>
                                <p>红旗校区</p>
                                <span><p>石家庄花都形象设计职业学校是石家庄正规专业的美容、美发、美甲、化妆、学历提升、摄影培训...</p></span>
                                <div>
                                    <span>
                                        <styled.Icon><use xlinkHref='#icon-weizhi'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>地址 : 石家庄桥西区红旗大街337号</span>
                                    </span>
                                    {/* <span>
                                        <styled.Icon><use xlinkHref='#icon-dianhua'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>电话 : 87029515</span>
                                    </span> */}
                                </div>
                            </styled.CampusImg>
                        </span>
                    </span>
                </div>
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>                    
                    <span style={{ flex: '1', borderLeft: '0.5rem solid transparent', borderTop: '0.5rem solid transparent', display: 'flex' }}>
                        {/* 分校4 */}
                        <span style={{ flex: '1', background: '#fff', paddingRight: '1rem', display: 'flex' }}>
                            <styled.CampusImg style={{ flex: '1' }}><i style={{ height: '100%', background: `url(${c3}) no-repeat`, backgroundSize: '100% 100%'}} /></styled.CampusImg>
                            <styled.CampusImg style={{ flex: '.8', paddingTop: '2rem', paddingBottom: '2rem', paddingRight: '0' }}>
                                <p>中华校区</p>
                                <span><p>石家庄花都形象设计职业学校是石家庄正规专业的美容、美发、美甲、化妆、学历提升、...</p></span>
                                <div>
                                    <span>
                                        <styled.Icon><use xlinkHref='#icon-weizhi'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>地址 : 石家庄长安区古城西路3号</span>
                                    </span>
                                    {/* <span>
                                        <styled.Icon><use xlinkHref='#icon-dianhua'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>电话 : 87029515</span>
                                    </span> */}
                                </div>
                            </styled.CampusImg>
                        </span>
                    </span>
                </div>
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>                    
                    <span style={{ flex: '1', borderLeft: '0.5rem solid transparent', borderTop: '0.5rem solid transparent', display: 'flex' }}>
                        {/* 分校4 */}
                        <span style={{ flex: '1', background: '#fff', paddingRight: '1rem', display: 'flex' }}>
                            <styled.CampusImg style={{ flex: '1' }}><i style={{ height: '100%', background: `url(${c4}) no-repeat`, backgroundSize: '100% 100%'}} /></styled.CampusImg>
                            <styled.CampusImg style={{ flex: '.8', paddingTop: '2rem', paddingBottom: '2rem', paddingRight: '0' }}>
                                <p>阿里山校区</p>
                                <span><p>石家庄花都形象设计职业学校是石家庄正规专业的美容、美发、美甲、化妆、学历提升、摄影培训...</p></span>
                                <div>
                                    <span>
                                        <styled.Icon><use xlinkHref='#icon-weizhi'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>地址 : 石家庄经济开发区阿里山大街19号</span>
                                    </span>
                                    {/* <span>
                                        <styled.Icon><use xlinkHref='#icon-dianhua'></use></styled.Icon>
                                        <span style={{color:'#787878',fontSize:'.88rem'}}>电话 : 87029515</span>
                                    </span> */}
                                </div>
                            </styled.CampusImg>
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}
