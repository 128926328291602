import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Campus_Banner from '../component/campus_banner'
import Campus_Footer from '../component/campus_footer'
import RightBar from '../component/rightbar'
import Videoplayer from '../component/videoplayer'
import { campusRoutes } from '../routes'

import v from '../../public/video.mp4'

import e1 from '../../public/e1.jpg'
import cibankground from '../../public/cibankground.jpg'
import ci1 from '../../public/picture14.jpg'
import ci2 from '../../public/picture15.jpg'
import ci3 from '../../public/picture13.jpg'
import ci4 from '../../public/picture16.jpg'
import ci5 from '../../public/picture17.jpg'
import ci6 from '../../public/picture18.png'
import ci7 from '../../public/picture11.jpg'
import ci8 from '../../public/picture12.jpg'
import blue5 from '../../public/blue5.png'

//

export default class Campus_Environment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgs: [
      ],
      types: [{ ID: 1, Name: '航空高铁' }, { ID: 2, Name: '计算机' }, { ID: 3, Name: '形象设计部' }, { ID: 4, Name: '幼师部' }, { ID: 5, Name: '个人' }],
      typeID: 1,
    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <styled.ContentBox>
        <Campus_Banner history={this.props.history} active={'校区环境'} />
        <styled.Swipper style={{ minHeight:"40rem",background: `url(${e1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          {/* <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>名师风采</div> */}
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${blue5}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '53rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>校区环境</div>
        </styled.Swipper>
        <styled.EducationBox style={{ minHeight: '65rem', marginTop: '5rem', justifyContent: 'space-between', position: 'relative', background: `url(${cibankground}) no-repeat`, backgroundSize: '100% 100%' }}>
          <div style={{ width: '100%', height: 'calc(100% - 4rem)', padding: '1rem 0', flexDirection: 'column' }}>
            <div style={{ width: '100%', height: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '.72rem', marginBottom: '1rem', color: 'rgb(58, 58, 58)' }}>
              <span style={{ fontSize: '.88rem', display: 'flex', alignItems: 'center' }}><i style={{ width: '2rem', height: '2px', background: '#260ea2', display: 'inline-block', marginRight: '.5rem' }} />校区环境</span>
              {/* <span style={{ display: 'flex', alignItems: 'center' }}>
                {this.state.types.map((item, index) => {
                  return (
                    <div key={index}>
                      <styled.HoverBox onClick={() => { this.setState({ typeID: item.ID }, () => { this.getImages(item.ID) }) }} style={{ color: this.state.typeID === item.ID ? '#ba0f22' : '' }}>{item.Name}</styled.HoverBox>
                      {this.state.types.length - 1 === index ? '' : <span style={{ margin: '0 1rem' }}>/</span>}
                    </div>
                  )
                })}
              </span> */}
            </div>
            {/* 图片集 */}
            {/* <styled.ImgBackground style={{ flex: '1', background: `url(${he3}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.385rem solid #fff' }}></styled.ImgBackground> */}
            <div style={{ flex: '1', display: 'flex', boxShadow: '0px 28px 34.83px 8.17px rgba(74, 74, 74, 0.26)' }}>
              {/* 左 */}
              <div style={{ flex: '2', borderRight: '0.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                {/* 上 */}
                <div style={{ flex: '2', display: 'flex', borderBottom: '.25rem solid #fff' }}>
                  <styled.ImgBackground style={{ flex: '1', background: `url(${ci1}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.25rem solid #fff' }}>
                    {/* <styled.ImgMask>
                      <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>教学楼</p>
                      <p style={{ fontSize: '.83rem' }}>The TEACHING BUILDING</p>
                    </styled.ImgMask> */}
                  </styled.ImgBackground>
                  <div style={{ flex: '1', borderLeft: '.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                    <styled.ImgBackground style={{ flex: '1', background: `url(${ci2}) no-repeat`, backgroundSize: '100% 100%', borderBottom: '.25rem solid #fff' }}>
                      {/* <styled.ImgMask>
                        <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>教学楼</p>
                        <p style={{ fontSize: '.83rem' }}>The TEACHING BUILDING</p>
                      </styled.ImgMask> */}
                    </styled.ImgBackground>
                    <styled.ImgBackground style={{ flex: '1', background: `url(${ci3}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.25rem solid #fff' }}>
                      {/* <styled.ImgMask>
                        <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>行政办公楼</p>
                        <p style={{ fontSize: '.83rem' }}>ADMINISTRATIVE OFFICE BUILDING</p>
                      </styled.ImgMask> */}
                    </styled.ImgBackground>
                  </div>
                </div>
                {/* 中 */}
                <div style={{ flex: '1', display: 'flex', borderBottom: '.25rem solid #fff', borderTop: '.25rem solid #fff' }}>
                  <styled.ImgBackground style={{ flex: '1', background: `url(${ci4}) no-repeat`, backgroundSize: '100% 100%', borderRight: '.25rem solid #fff' }}>
                    {/* <styled.ImgMask>
                      <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>校园美术课堂</p>
                      <p style={{ fontSize: '.83rem' }}>CAMPUS ART CLASS</p>
                    </styled.ImgMask> */}
                  </styled.ImgBackground>
                  <styled.ImgBackground style={{ flex: '1', background: `url(${ci5}) no-repeat`, backgroundSize: '100% 100%', borderLeft: '.25rem solid #fff' }}>
                    {/* <styled.ImgMask>
                      <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>校园超市</p>
                      <p style={{ fontSize: '.83rem' }}>CAMPUS SUPERMARKET</p>
                    </styled.ImgMask> */}
                  </styled.ImgBackground>
                </div>
                {/* 下 */}
                <styled.ImgBackground style={{ flex: '2', background: `url(${ci6}) no-repeat`, backgroundSize: '100% 100%', borderTop: '.25rem solid #fff' }}>
                  {/* <styled.ImgMask>
                    <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>食堂</p>
                    <p style={{ fontSize: '.83rem' }}>THE DINING ROOM</p>
                  </styled.ImgMask> */}
                </styled.ImgBackground>
              </div>
              {/* 右 */}
              <div style={{ flex: '1', borderLeft: '0.25rem solid #fff', display: 'flex', flexDirection: 'column' }}>
                {/* 上 */}
                <styled.ImgBackground style={{ flex: '2', background: `url(${ci7}) no-repeat`, backgroundSize: '100% 100%', borderBottom: '.25rem solid #fff' }}>
                  {/* <div style={{ width: '100%', height: '100%' }}>
                    <Videoplayer url={v} />
                  </div> */}
                  {/* <styled.ImgMask>
                    <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>假山池</p>
                    <p style={{ fontSize: '.83rem' }}>ROCKERY POOL</p>
                  </styled.ImgMask> */}
                </styled.ImgBackground>
                {/* 中 */}
                <styled.ImgBackground style={{ flex: '2', background: `url(${ci8}) no-repeat`, backgroundSize: '100% 100%', borderBottom: '.25rem solid #fff', borderTop: '.25rem solid #fff' }}>
                  {/* <styled.ImgMask>
                    <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>篮球场</p>
                    <p style={{ fontSize: '.83rem' }}>BASKETBALL COURT</p>
                  </styled.ImgMask> */}
                </styled.ImgBackground>
                {/* 下 */}
                <styled.ImgBackground style={{ flex: '1', borderTop: '.25rem solid #fff', background: '#260ea2' }}>
                  <p style={{ fontSize: '.95rem', marginBottom: '.7rem' }}>敬请期待</p>
                  <p style={{ fontSize: '.83rem' }}>COMING SOON</p>
                </styled.ImgBackground>
              </div>
            </div>
          </div>
          <div style={{ width: '20%', height: '100%' }}>
            {/* <RightBar active={'名师风采'}   history={this.props.history} routes={campusRoutes[3]} /> */}
          </div>
          {/* <styled.HoverBox style={{ position: 'absolute', bottom: '2rem', left: '45%', fontSize: '.84rem', color: '#ba0f22', letterSpacing: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div>MORE</div>
            <styled.Icon><use xlinkHref='#icon-jiantouxia'></use></styled.Icon>
          </styled.HoverBox> */}
        </styled.EducationBox>
        <Campus_Footer history={this.props.history} />
      </styled.ContentBox>
    )
  }
  /////////////////////////////////////////////////////////////
  getImages(id) {
    // service.request({
    //   method: 'get',
    //   url: '',
    //   params: {
    //     typeID: this.state.id,
    //   }
    // }).then(res => {
    //   if (res.data.code === 200) {
    //     this.setState({ imgs: res.data.data})
    //   }
    // })
  }
  //
  matching(id, arr) {
    let result = arr.find(obj => {
      if (obj.ID === id) {
        return obj
      }
      return null
    })
    return result.Name
  }
}
