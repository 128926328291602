import React, { Component } from 'react'
import {
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton
} from 'video-react';
import "../../node_modules/video-react/dist/video-react.css"; // import css
import '../Utlis/video.css'




export default class Videoplayer extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className='own' style={{ height: '100%', height: '100%' }}>
                {/* <video controls autoPlay style={{ width: '100%', height: '100%' }} src={"https://api2.yuexiaojing.com/" + this.props.url} /> */}
                <Player
                    fluid={false}
                    width={"100%"}
                    height={"100%"}
                    // src={this.props.url}
                    src={this.props.url}
                    playsInline={true}
                >
                    <ControlBar autoHide={true} disableDefaultControls={false}>
                        <ReplayControl seconds={10} order={1.1} />
                        <ForwardControl seconds={30} order={1.2} />
                        <PlayToggle />
                        <CurrentTimeDisplay order={4.1} />
                        <TimeDivider order={4.2} />
                        <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                        <VolumeMenuButton />
                    </ControlBar>
                </Player>
            </div>
        )
    }
}
