import React, { Component } from 'react'
// import { Switch, Route, Redirect } from 'react-router-dom'
// import 'antd/dist/antd.css'
import { homeRoutes, renderRoutesMain } from './routes/index'


export default class Home extends Component {
    render() {
        return (
            <>
                {renderRoutesMain(homeRoutes)}
               
            </>

            // <>
            //     {homeRoutes.map(route => {
            //         if (route.routes) {
            //             return (
            //                 <Switch key={route.path}>
            //                     {route.routes.map(item => {
            //                         return <Route key={item.path} {...item} />
            //                     })}
            //                     < Route  {...route} />
            //                 </Switch>
            //             )
            //         } else {
            //             return (
            //                 < Route key={route.path} {...route} />
            //             )
            //         }
            //     })}
            // </>
        )
    }
}
