import React, { Component } from 'react'
import * as styled from '../Utlis/styled'

import '../../public/iconfont'
import service from '../Utlis/request'

// import img from '../../public/3.png'
import t from '../../public/st1.jpg'
import x from '../../public/st2.jpg'
import r from '../../public/右.png'

export default class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imgs: [{ Cover: '', Title: '', Time: 0 }],
            imgIndex: 0,
            news: [{ Cover: '', Title: '', Time: 0 }],
            type: '头条',
            news5:[]
        }
    }
    componentDidMount() {
        this.getNews()
        service.request({
            method: 'get',
            url: '/article',
            params: {
                isCover: 1,
                columnID: 1,
                limit: 5,
            }
        }).then(res => {
            if (res.code === 200 && res.data.length !== 0) {
                this.swipper()
                this.setState({ imgs: res.data }) 
            }
        })
    }
    render() {
        let news5 = this.cutNews()
        return (
            <div style={{ display: 'flex', width: '100%', minHeight: '37rem', padding: '2rem 7.5rem', color: '#2a2a2a' }}>
                <div style={{ flex: '2.5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ flex: '1', fontSize: '1.8rem' }}>
                        <p><i style={{ color: '#ba0f22' }}>_</i>HUADU News</p>
                        <p><i style={{ color: '#fff' }}>_</i>花都新闻</p>
                    </div>
                    <styled.NewsFirst>
                        <div onClick={() => { this.pushPage(this.state.imgs[this.state.imgIndex]) }} id='newimg' style={{ flex: '1', maxWidth: '30rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span style={{ width: '100%', height: "26rem", padding: '1rem', border: '3px solid #ba0f22', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <img src={'http://49.4.2.15:9090/' + this.state.imgs[this.state.imgIndex].Cover} style={{ maxHeight: '20rem', width: '100%' }} alt='' />
                                <span>
                                    <p style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{this.state.imgs[this.state.imgIndex].Title}</p>
                                    <span style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>
                                        <styled.Icon style={{ marginRight: '.5rem', width: '1rem', height: '1rem' }}><use xlinkHref='#icon-shijian'></use></styled.Icon>
                                        <p style={{ fontSize: '.8rem', color: '#ba0f22', display: 'inline-block' }}>{'发布时间 : ' + this.formatDate(this.state.imgs[this.state.imgIndex].Time / 1000000)}</p>
                                    </span>
                                </span>
                            </span>
                            <span style={{ flex: '1', display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
                                {this.state.imgs.map((item, index) => {
                                    return (
                                        <div key={index} style={{ height: '.5rem', flex: '1', margin: '0 10px', borderRadius: '.5rem', background: index === this.state.imgIndex ? '#ba0f22' : '#e3e3e3' }}></div>
                                        // <styled.Icon style={{ fill: index === this.state.imgIndex ? '#ba0f22' : '#e3e3e3' }} key={index}><use xlinkHref='#icon-dian'></use></styled.Icon>
                                    )
                                })
                                }
                            </span>
                        </div>
                        <div style={{ flex: '1', margin: '1rem 2rem', maxWidth: '30rem' }}>
                            <div style={{ display: 'flex', fontSize: '1.5rem', padding: '0 2rem' }}>
                                <styled.NewsType active={this.state.type === '头条' ? true : false} onClick={() => { this.changeNewsType('头条') }}>花都头条</styled.NewsType>
                                <styled.NewsType active={this.state.type === '动态' ? true : false} onClick={() => { this.changeNewsType('动态') }}>实时动态</styled.NewsType>
                                <styled.NewsType active={this.state.type === '活动' ? true : false} onClick={() => { this.changeNewsType('活动') }}>实践活动</styled.NewsType>
                            </div>
                            <svg
                                style={{ marginTop: '1rem' }}
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="100%" height="2rem">
                                <path fillRule="evenodd" stroke="rgb(188, 20, 37)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
                                    d="M2.000,0.999 L2.000,33.999 L34.000,1.999 L600.000,1.999 " />
                            </svg>
                            <div style={{ display: 'flex', paddingLeft: '2rem', flexDirection: 'column', justifyContent: 'space-between', fontSize: '.88rem', height: '70%' }}>
                                {this.state.news.map((item, index) => {
                                    return (
                                        <styled.NewsItem onClick={() => { this.pushPage(item) }} key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ maxWidth: '65%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.Title}</span>
                                            <span style={{ color: '#9e9e9e', fontSize: '.8rem' }}>{this.formatDate(item.Time / 1000000)}</span>
                                        </styled.NewsItem>
                                    )
                                })}
                            </div>
                        </div>
                    </styled.NewsFirst>
                </div>
                <div style={{ flex: '.9', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ flex: '1', fontSize: '1.8rem' }}>
                        <p><i style={{ color: '#ba0f22' }}>_</i>Student Services</p>
                        <p><i style={{ color: '#fff' }}>_</i>学生服务</p>
                    </div>
                    <div style={{ flex: '5', padding: '0 1rem', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                        <styled.Card>
                            <span style={{ flex: '3', background: `url(${t}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <div>
                                <span style={{ fontSize: '.88rem' }}>通知公告</span>
                                <button onClick={() => { this.props.history.push({ pathname: '/home/studentservices/activity1', state: '通知公告' }) }}>查看详情</button>
                            </div>
                        </styled.Card>
                        <styled.Card>
                            <span style={{ flex: '3', background: `url(${x}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <div>
                                <span style={{ fontSize: '.88rem' }}>学生活动</span>
                                <button onClick={() => { this.props.history.push({ pathname: '/home/studentservices/activity3', state: '学生活动' }) }}>查看详情</button>
                            </div>
                        </styled.Card>
                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                            <styled.Button onClick={() => { this.props.history.push('/home/studentservices') }}>
                                更多活动
                            </styled.Button>
                            <img src={r} alt='' style={{ width: '15%', position: 'absolute', right: '2rem', top: '50%', transform: 'translateY(-50%)' }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////
    formatDate(time) {
        var date = new Date(time);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        return y + '/' + m + '/' + d;
    }
    //
    changeNewsType(s) {
        this.setState({ type: s }, () => { this.getNews(1); this.getNews() })
    }
    //
    cutNews() {
        if (this.state.imgs.length !== 0) {
            return this.state.imgs.slice(0, 5)
        }
        return []
    }
    //
    swipper() {
        //定时器
        let timer = setInterval(() => {
            let imgIndex = this.state.imgIndex;
            if (imgIndex === 4) {
                imgIndex = 0;
            } else {
                imgIndex++;
            }
            this.setState({ imgIndex })
        }, 3000);

        //鼠标移入移出
        document.getElementById('newimg').onmouseover = () => {
            clearInterval(timer);
        }
        document.getElementById('newimg').onmouseout = () => {
            timer = setInterval(() => {
                let imgIndex = this.state.imgIndex;
                if (imgIndex === 4) {
                    imgIndex = 0;
                } else {
                    imgIndex++;
                }
                this.setState({ imgIndex })
            }, 3000);
        }
    }
    //
    pushPage(item) {
        if (this.state.type === '头条') {
            this.props.history.push({ pathname: '/home/news/newslist1', state: '花都头条', article: item })
        }
        if (this.state.type === '动态') {
            this.props.history.push({ pathname: '/home/news/newslist2', state: '实时动态', article: item })
        }
        if (this.state.type === '活动') {
            this.props.history.push({ pathname: '/home/news/newslist3', state: '实践活动', article: item })
        }
    }
    //
    getNews() {
        var type
        if (this.state.type === '头条') {
            type = 1
        }
        if (this.state.type === '动态') {
            type = 2
        }
        if (this.state.type === '活动') {
            type = 3
        }
        service.request({
            method: 'get',
            url: '/article',
            params: {
                categoryID: type,
                columnID: 1,
                limit: 10,
            }
        }).then(res => {
            this.setState({ news: res.data }) 
        })
    }
}
