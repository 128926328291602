import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'
import logo from '../../public/logo.png'
import Banner from '../component/banner'
import Footer from '../component/footer'

import he1 from '../../public/he1.jpg'
// import he2 from '../../public/he2.jpg'
// import he3 from '../../public/he3.png'
// import he4 from '../../public/he4.png'
// import he5 from '../../public/he5.png'
// import he6 from '../../public/he6.jpg'
// import r from '../../public/右.png'


export default class Departments extends Component {
  constructor() {
    super()
    this.state = {
      list: [],
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'院系设置'} />
        <styled.Swipper
          style={{
            background: `url(${he1}) no-repeat`,
            backgroundSize: '100% 100%',
            overflow: 'visible',
            padding: '4.5rem 11rem',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'end'
          }}>
          <p style={{ color: '#ba0f22', fontSize: '3.33rem', fontWeight: '700', letterSpacing: '2px' }}>院系设置</p>
          <p style={{ color: '#fff', fontSize: '2.33rem', fontWeight: '700', letterSpacing: '2px' }}>选择一个好专业 拥有一个好前途</p>
        </styled.Swipper>
        <p style={{ color: '#6b6b6b', fontSize: '0.66rem', marginTop: "1.5rem" }}>每一个角落都装满了青春的脚步，和许多成长的故事，纪念着属于我们的时光......</p>
        <p style={{ color: '#6b6b6b', fontSize: '0.66rem', marginTop: "0.4rem" }}>花都校园，我们成长的乐土！我们在这里欢笑，我们在这里收获，物品们在这里成长，我们在这里找到未来的方向，我在花都等你！</p>
        <img src={logo} alt='' style={{ height: '3.55rem', width: '3.55rem', marginTop: "2rem", marginBottom: "0.5rem" }} />
        <styled.EducationBox style={{marginBottom:"4rem"}}>
          <div style={{ flex: 1, borderRight: "1px solid #ccc", paddingTop: "2rem", flexDirection: 'column',position: "relative", }}>
            {/* left */}

            {/* <div style={{ width: "0.66rem", height: "0.66rem", borderRadius: "0.33rem", background: "#bc1425", position: "absolute", right: "-0.36rem", top: "0rem",zIndex:99 }}></div> */}
            {
              this.state.list.map((item, index) => {
                if ((index % 2) === 0) {
                  return <div style={{ borderTop: "1px solid #bc1425", width: "100%", position: "relative", display: "flex", }}>
                    <div style={{ width: "0.33rem", height: "0.33rem", borderRadius: "0.16rem", background: "#bc1425", position: "absolute", right: "-0.22rem", top: "-0.22rem" ,zIndex:99}}></div>
                    <div style={{ flex: "1", padding: "1rem 0 1rem 0", }}>
                      {
                        item.Majors.map((i, j) => {
                          return <div style={{ paddingBottom: "0.7rem", color: "#cf1818", fontSize: "0.83rem" }} key={index}>{i.Name}</div>
                        })
                      }
                    </div>
                    <div style={{ flex: "1", padding: "1rem", }}>
                      <div style={{ color: "#cf1818", fontSize: "1.27rem" }}>{item.Name}</div>
                      <div style={{ fontSize: "0.66rem", marginTop: "0.5rem", lineHeight: "1.5rem" }}>{item.Note}</div>
                    </div>
                  </div>
                }
              })
            }
          </div>
          {/* right */}
          <div style={{ flex: 1, borderLeft: "1px solid #ccc", paddingTop: "4rem", flexDirection: 'column',position:"relative" }}>
          <div style={{ width: "0.66rem", height: "0.66rem", borderRadius: "0.33rem", background: "#bc1425", position: "absolute", left: "-0.4rem", bottom: "0rem" }}></div>
            {
              this.state.list.map((item, index) => {
                if ((index % 2) != 0) {
                  return <div style={{ borderTop: "1px solid #bc1425", width: "100%", position: "relative", display: "flex", }}>
                    <div style={{ width: "0.33rem", height: "0.33rem", borderRadius: "0.16rem", background: "#bc1425", position: "absolute", left: "-0.22rem", top: "-0.22rem" }}></div>
                    <div style={{ flex: "1", padding: "1rem", }}>
                      <div style={{ color: "#cf1818", fontSize: "1.27rem" }}>{item.Name}</div>
                      <div style={{ fontSize: "0.66rem", marginTop: "0.5rem", lineHeight: "1.5rem" }}>{item.Note}</div>
                    </div>
                    <div style={{ flex: "1", padding: "1rem 0 1rem 0", }}>
                      {
                        item.Majors.map((i, j) => {
                          return <div style={{ paddingBottom: "0.7rem", color: "#cf1818", fontSize: "0.83rem", textAlign: "right" }} key={index}>{i.Name}</div>
                        })
                      }
                    </div>
                  </div>
                }
              })
            }
          </div>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  componentDidMount() {
    this.getProfessional()
  }
  getProfessional() {
    service.request({
      method: 'get',
      url: '/department',
      //    params:,
    }).then(res => {
      if (res.code === 200) {
        this.setState({ list: res.data })
      }
    })
  }
}
