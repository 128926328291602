import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'
import { homeRoutes } from '../routes'


import pic1 from '../../public/pic1.jpg'
import c1 from '../../public/c1.png'
import c2 from '../../public/c2.png'
import c3 from '../../public/c3.png'
import c4 from '../../public/c4.png'
import c5 from '../../public/c5.png'
import c6 from '../../public/c6.png'
import c7 from '../../public/c7.png'
import c8 from '../../public/c8.png'
import c9 from '../../public/c9.png'
import c10 from '../../public/c10.png'
import c11 from '../../public/c11.png'
import c12 from '../../public/c12.png'

import e2 from '../../public/e2.png'

import v from '../../public/美发创业学员分享.mp4'
import Videoplayer from '../component/videoplayer'

export default class Cooperation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vides: [
                { Path: v },
            ],
            videoIndex: 0,
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'升学就业'} />
                <styled.Swipper style={{ background: `url(${pic1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>校企合作</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '53rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>升学就业<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>校企合作</div>
                </styled.Swipper>
                <styled.EducationBox style={{ marginTop: '5rem', justifyContent: 'space-between' }}>
                    <div style={{ width: '70%', padding: '1rem 0', flexDirection: 'column' }}>
                        <span>
                            <p style={{ fontSize: '1.68rem', fontWeight: 'bold', letterSpacing: '2px' }}>校企合作</p>
                            <div style={{ width: '3.6rem', height: '6px', background: '#ba0f22', margin: '1rem 0' }} />
                        </span>
                        <span style={{ fontSize: '.77rem', color: 'color: rgb(201, 20, 42)', lineHeight: '1.5rem', letterSpacing: '.1rem', marginBottom: '1.5rem' }}>
                            <p style={{ display: 'flex', alignItems: 'center', color: '#ba0f22' }}>合作模式<i style={{ width: '2rem', height: '1px', background: '#ba0f22' }} /></p>
                            <p>
                                产教融合、校企合作、工学结合、知行合一、教、学、做一体化人才培养模式<br />
                                1、产教融合主体从宏观方面是作用于产业，是产业与教育、企业与学校、生产和教学相融合的深度合作，是院校为提高其人才培养质量而与行业企业开展的深度合作。<br />
                                2、校企合作主体中观作用于专业，是学校与企业建立的一种合作模式。<br />
                                3、工学结合主体中微观作用于学校和老师，是一种将学习与工作相结合的教育模式。<br />
                                4、知行合一作用于学生，只有工学结合，方能知行合一。
                            </p>
                        </span>
                        <span style={{ fontSize: '.77rem', color: 'color: rgb(201, 20, 42)', lineHeight: '1.5rem', letterSpacing: '.1rem' }}>
                            <p style={{ display: 'flex', alignItems: 'center', color: '#ba0f22' }}>合作条件<i style={{ width: '2rem', height: '1px', background: '#ba0f22' }} /></p>
                            <p>
                                1、要求企业能够深度参与产教融合、校企合作，在职业院校、高等学校办学和深化改革中发挥重要主体作用，<br />
                                2、产教融合型企业建设信息储备库的建设培育企业，兴办职业教育的投资<br />
                                3、接收学生实习实训、接纳教师岗位实践、开展校企深度合作、建设产教融合实训基地等工作相挂钩<br />
                                4、共建合作专业：（1）服务企业；（2）校企互利；（3）统一管理；（4）校企互动<br />
                                5、共建人才培养方案：（1）人才培养目标；（2）课程体系；（3）推进双师型教师队伍建设。<br />
                                6、共建实训基地：（1）接受第一学年的认岗实习，第二学年的跟岗实习和第三学年的顶岗实习等实践教学环节；（2）接受教师参观、调研、双师型教师的培养；（3）实现资源共享，互派专业人员讲学、培训；（4）优先满足合作基地的用人需求。
                            </p>
                        </span>
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={'校企合作'} history={this.props.history} routes={homeRoutes[5]} />
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', background: '#f4f2f7', fontSize: '.77rem' }}>
                    <div style={{ width: '70%', padding: '2.5rem 0', flexDirection: 'column', lineHeight: '1.5rem', letterSpacing: '.1rem', }}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span style={{ width: '58%' }}>
                                <p style={{ color: '#ba0f22', marginBottom: '.5rem' }}>&nbsp;&nbsp;&nbsp;&nbsp;合作企业——上海心联美容美发公司</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;上海心联美容美发公司，自2015年开始与我校开始校企合作，每年的12月份，上海心联公司都会接收我校50人左右的跟岗实习学生到企业实习工作，实习时间为期3个月，每周一次总结和学生情况沟通情况向学校提报，确保了学生在岗的稳定性。每年的5-6月份有数十名学生到上海心联参加顶岗实习和就业。</p>
                            </span>
                            <img src={c1} align="right" style={{ width: '19.1rem', height: '14.3rem', }} hspace="8" vspace='8' />
                        </span>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <img src={c2} align="left" style={{ width: '19.1rem', height: '14.3rem', }} hspace="8" vspace='8' />
                            <span style={{ width: '58%' }}>
                                <p style={{ color: '#ba0f22', marginBottom: '.5rem' }}>&nbsp;&nbsp;&nbsp;&nbsp;合作企业——北京再清椿美容有限公司</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;北京再清椿美容有限公司与石家庄花都形象艺术学校于2014年5月开启合作，至今已有8年之久！</p>
                            </span>
                        </span>
                    </div>
                </styled.EducationBox>
                <styled.EducationBox style={{ marginBottom: '5rem', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div style={{ fontSize: '1.27rem', color: '#ba0f22', alignItems: 'center', fontWeight: '700', marginBottom: '5rem' }}>
                        <styled.Line />
                        <span style={{ margin: '0 1em' }}>就业回访在路上，爱与责任在心中</span>
                        <styled.Line />
                    </div>
                    <div style={{ minHeight: '21.36rem', overflowX: 'hidden', width: '79.5rem', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                        <styled.SwipperContent id='video' style={{ alignItems: 'center', justifyContent: 'center' }} left={(this.state.videoIndex * (-26.5)) + 'rem'} >
                            {this.state.vides.map((item, index) => {
                                return (
                                    // <styled.VideoCard key={index} center={this.state.videoIndex + 1 === index ? 1 : 0}>
                                    <styled.VideoCard key={index} center={1} style={{transform:'scale(1.3)'}}>
                                        {/* {index===1?<Videoplayer url={item.Path} />:<img src={item.Path} alt=''/>} */}
                                        <Videoplayer url={item.Path} />
                                    </styled.VideoCard>
                                )
                            })}
                        </styled.SwipperContent>
                    </div>
                    {/* <span style={{ position: 'absolute', top: 'calc(50% + 4rem)', left: '9rem', transform: 'translateY(-50%)' }}>
                        <styled.HoverIcon onClick={() => { this.pre() }} style={{ width: '4rem', height: '4rem', fill: '#ccc' }}><use xlinkHref='#icon-xiangzuojiantou'></use></styled.HoverIcon>
                    </span>
                    <span style={{ position: 'absolute', top: 'calc(50% + 4rem)', right: '9rem', transform: 'translateY(-50%)' }}>
                        <styled.HoverIcon onClick={() => { this.next() }} style={{ width: '4rem', height: '4rem', fill: '#ccc' }}><use xlinkHref='#icon-xiangyoujiantou'></use></styled.HoverIcon>
                    </span> */}
                </styled.EducationBox>
                <styled.EducationBox style={{ flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div style={{ fontSize: '1.27rem', color: '#ba0f22', alignItems: 'center', fontWeight: '700', marginBottom: '4rem' }}>
                        <styled.Line />
                        <span style={{ margin: '0 1em' }}>部分企业</span>
                        <styled.Line />
                    </div>
                    <styled.ImageCard >
                        < span style={{ background: `url(${c3}) no-repeat`, backgroundSize: '100% 100%', }} />
                        < span style={{ background: `url(${c4}) no-repeat`, backgroundSize: '100% 100%', margin: '0 1rem' }} />
                        < span style={{ background: `url(${c5}) no-repeat`, backgroundSize: '100% 100%', }} />
                    </styled.ImageCard>
                    <styled.ImageCard >
                        < span style={{ background: `url(${c6}) no-repeat`, backgroundSize: '100% 100%', }} />
                        < span style={{ background: `url(${c7}) no-repeat`, backgroundSize: '100% 100%', margin: '0 1rem' }} />
                        < span style={{ background: `url(${c8}) no-repeat`, backgroundSize: '100% 100%', }} />
                    </styled.ImageCard>
                    <styled.ImageCard style={{ backgroundSize: '100% 100%', }} url={c9} />
                    <styled.ImageCard style={{ backgroundSize: '100% 100%', }} url={c10} />
                    <styled.ImageCard style={{ backgroundSize: '100% 100%', }} url={c11} />
                    <styled.ImageCard style={{ backgroundSize: '100% 100%', }} url={c12} />
                </styled.EducationBox>
                <styled.EducationBox style={{ marginBottom: '5rem', justifyContent: 'center', color: 'rgb(87, 87, 87)', letterSpacing: '1px', fontSize: '.77rem' }}>更多合作 敬请期待 ......</styled.EducationBox>
                <Footer history={this.props.history} />
            </styled.ContentBox>
        )
    }

    //////
    pre() {
        if (this.state.videoIndex === -1) { return }
        this.setState({ videoIndex: this.state.videoIndex - 1 })
    }
    next() {
        if (this.state.videoIndex === this.state.vides.length - 2) { return }
        this.setState({ videoIndex: this.state.videoIndex + 1 })
    }
}
