import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import { homeRoutes } from '../routes'
import '../../public/iconfont'
import e1 from '../../public/e1.jpg'
import e2 from '../../public/e2.png'
import e4 from '../../public/e4.png'
import e5 from '../../public/e5.png'
import e6 from '../../public/e6.png'
import e7 from '../../public/e7.png'
import pabcg from '../../public/pabcg.jpg'
import nb3 from '../../public/nb3.png'


{/* <styled.Down style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-xiala'></use></styled.Down>
<styled.Up style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-shangla'></use></styled.Up> */}
export default class Professional extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropDown: false,
      list: [{ ID: 1, Name: "政策背景", dropDown: true, Note: [{ a: '2017年10月18日，习近平同志在十九大报告中指出，"我国经济已由高速增长阶段转向高质量发展阶段"这一历史性论断，强调"发展是第一要务，人才是第一资源，创新是第一动力"。深化产教融合，是解决人才供需结构性矛盾、推进人才和人力资源供给侧结构性改革的一项非常迫切的任务，对新形势下全面提高教育质量、扩大就业创业、推进经济转型升级、培育经济发展新动能具有重要意义。' }, { a: '2017年12月5日，国务院办公厅发布了《关于深化产教融合的若干意见》，并与当日起实施。明确了发挥政府统筹规划、企业重要主体、人才培养改革主线、社会组织等供需对接作用“四位一体”制度架构，推动产教融合从发展理念向制度供给落地。' }, { a: '2019年4月，国家发改委、教育部联合印发《建设产教融合型企业实施办法（试行）》' }, { a: '2019年6月，国家教育部印发《关于职业院校专业人才培养方案制订与实施工作的指导意见》' }, { a: '2019年9月，国家发改委印发了《国家产教融合建设试点实施方案》' }, { a: '2018年-2020年，在两会上的政府工作报告中，每年都在提倡，推动教育公平发展和质量提升。' }, { a: '2022年的两会中，政府工作报告提出“改善职业教育办学条件，完善产教融合办学体制”，“发展现代职业教育、增强职业教育适应性”。报告指出，要提高职业教育质量，需要产教融合政策落地，引导企业和学校联合培养人才、为企业减免教育附加税等。' }] },
      { ID: 2, Name: "产教融合概念", dropDown: false, Note: [{ a: ' 所谓产教融合是指根据学校所设立的专业，积极开办专业产业，实行产教结合、相互支持、相互促进，把学校办成集人才培养、科研、科技服务于一体的产业经营实体，形成校企一体化办学模式的学校。产教融合是职业院校与企业为提高人才培养质量而进行的深层次合作。' }, { a: '1、产教融合的基础是“产业、生产、产品”，即必须以真正的产业、产品生产为前提，在这样的基础和氛围中进行专业实践教学，学生才能学到真正的技能，教师才能教到真正的水平。' }, { a: '2、产教融合中的“产”是指产业，产品，“教”指教育培训，教书，教学。' }, { a: '3、产教融合是产业与教育的融合，企业与职业院校都是人才培养的主体，两者是你中有我，我中有你的双向关系。' },] },
      { ID: 3, Name: "产教融合模式", dropDown: false, Note: [{ a: '1、技术合作模式，即职业院校与企业开展资源整合优势互补的技术性合作。' }, { a: '2、科技攻关项目模式，高校和企业联合进行国家或者地方产学研项目的攻关，校企联合攻关一方面引进了企业的技术创新和产业升级能力，另一方面锻炼了学校师生的科研能力与提升了服务地方经济的水平，同时校企联合科技攻关加快了学校的科研成果积累，并将科技成果转化为现实生产力，为企业带来直接经济利益的同时，积累了一定的实战经验且增强了自身创新的能力。' }, { a: '3、现代学徒制模式，作为一种近几年教育部提出的一种技术技能型人才培养的创新模式，旨在通过学生、学徒的“双身份”，学校、企业的“双主体”来联合培育人才' }, { a: '4、职教集团模式，主要通过政府机构、行业组织、企（事）业单位、职业院校、研究机构和社会组织等六大类组成的职教集团，围绕地方支柱产业或者特色产业进行优势互补、资源共享、合作发展，为职业院校与行业企业的发展提供全方位的保障。' }, { a: '5、共建实训基地模式，该模式主要通过学校负责提供实训场地与环境以及部分的设备和资源，企业提供目前生产实践中新型的实训设备或者投入部分经费，在校内共同建设实训基地共同完成校企合作的实训项目，以及实践授课的环节。通过实训基地培训的学生能够直接上岗并会使用新型设备，实现教学与就业的对接与贯通。' }, { a: ' 6、共建二级学院模式。' },] },
      { ID: 4, Name: "产教融合的意义", dropDown: false, Note: [{ a: '1、对企业、行业、产业及社会的意义：（1）有利于促进企业技术技能、工匠精神、先进管理、创新文化等要素融入学校人才培养、科学研究、创新创业全过程；（2）促进教育人才优势、创新优势与企业需求对接，推动完善产业创新链条，提升产业人力资本，是增强内在核心竞争力的重要渠道；（3）从行业的角度来看，产教融合校企合作方案在调动企业积极性方面起到了非常大的作用，能够让企业参与到办学机制中。' }, { a: '2、对教育教学的意义：（1）有利于提高教师的业务水平；（2）有利于促进职业教育的健康发展；（3）促进工匠精神等融入人才培养。' }, { a: '3、对于学生的意义：（1）构建科学合理的模块化课程体系；（2）加强学生规范意识、质量意识的培养；（3）实施分组分阶段教学。' },] }
      ],
      header: 'http://49.4.2.15:9090/',
    }
  }
  render() {
    return (
      <styled.ContentBox>
        <Banner history={this.props.history} active={'产教融合'} />
        <styled.Swipper style={{ background: `url(${e1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>产教融合</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '45rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>升学就业<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>产教融合</div>
        </styled.Swipper>
        <styled.EducationBox style={{ paddingTop: '5rem', justifyContent: 'space-between', background: `url(${pabcg}) repeat`, backgroundSize: '100% 100%', }}>
          <div style={{ width: '70%', padding: '2rem 0', flexDirection: "column" }}>
            <div style={{ fontSize: "1.61rem", fontWeight: "bolder" }}>产教融合</div>
            <div style={{ width: "3.5rem", height: "0.22rem", background: "#cf1818", margin: "1rem 0 1rem 0" }}></div>
            <div style={{ height: "100%", }}>
              {
                this.state.list.map((item, index) => {
                  return <div style={{ marginBottom: "0.2rem" }}>
                    <div onClick={() => this.Drapdown(item.ID)} style={{ width: "100%", height: "2.16rem", fontWeight: "bolder", padding: "0 1rem 0 1rem", background: `url(${nb3}) no-repeat`, backgroundSize: '100% 100%', color: "#fff", fontSize: "0.77rem", lineHeight: "2.16rem", position: "relative" }}>{item.Name}
                      {item.dropDown == false ?
                        <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#fff", transform: "rotate(-90deg)" }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                        : <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#fff", }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                      }
                    </div>
                    <div style={{ display: this.renderShow(item.dropDown), padding:"0.5rem"}}>
                      {
                        item.Note.map((i, j) => {
                          return <div style={{ fontSize: "0.7rem", marginTop: "0.3rem", lineHeight: "1.5rem",textIndent:"2rem" }}>
                         {i.a}
                          </div>
                        })
                      }

                    </div>
                  </div>
                })
              }
            </div>
          </div>
          <div style={{ width: '20%', height: '100%' }}>
            <RightBar active={'产教融合'} history={this.props.history} routes={homeRoutes[5]} />
          </div>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  componentDidMount() {
    // this.getProfessional()
  }
  // getProfessional() {
  //     service.request({
  //         method: 'get',
  //         url: '/department',
  //         //    params:,
  //     }).then(res => {
  //         if (res.code === 200) {
  //             res.data.forEach((item,index) => {
  //                 item.dropDown = false
  //                 if(index==0){
  //                     item.dropDown = true
  //                 }
  //                 item.Majors.forEach((i,j) => {
  //                     i.secondaryDropDown = false
  //                     if(j==0){
  //                         i.secondaryDropDown = true
  //                     }
  //                 });
  //             });
  //             this.setState({ list: res.data }, () => {
  //                 console.log(this.state.list, "list")
  //             })
  //         }
  //     })
  // }
  Drapdown(ID) {
    const Drapdownitem = this.state.list.find((ele) => { return ele.ID == ID })
    if (Drapdownitem.dropDown == true) {
      Drapdownitem.dropDown = false
      this.setState({})
    } else {
      Drapdownitem.dropDown = true
      this.setState({})
    }
  }
  renderShow(dropDown) {
    switch (dropDown) {
      case false:
        return "none";
      case true:
        return 'block';
    }
  }
}


