import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'

import sp1 from '../../public/sp1.png'
import sp2 from '../../public/sp2.jpg'
import sp4 from '../../public/sp4.jpg'
import sp5 from '../../public/sp5.png'
import sp6 from '../../public/sp6.png'
import sp7 from '../../public/sp7.png'
import sp8 from '../../public/sp8.png'
import rr from '../../public/r右.png'

import Img1 from '../../public/pl3(1).png'
import Img2 from '../../public/xq2.jpg'
import Img3 from '../../public/img (1).jpg'
import Img4 from '../../public/i3.png'
import Img5 from '../../public/img (3).jpg'
import Img6 from '../../public/img (5).jpg'
import Img7 from '../../public/img (4).jpg'
import Img8 from '../../public/xq1.jpg'
import Img9 from '../../public/pl4.png'
import Img10 from '../../public/xq3.jpg'
import Img11 from '../../public/img (2).jpg'




export default class SchoolProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      course: [
        { ID: 1, Img: Img1, String: `李春芳女士<br/>创办石家庄花都美容美发<br/>短期培训学校`, Year: '1994' },
        { ID: 2, Img: Img2, String: `开设第一家行业连锁店<br/>形成产教结合新格局`, Year: '2006' },
        { ID: 3, Img: Img3, String: `中等职业学校审批成功<br/>审批成功`, Year: '2008' },
        { ID: 4, Img: Img4, String: `正式迁入<br/>红旗大街校区`, Year: '2009' },
        { ID: 5, Img: Img5, String: `被省教育厅评为<br/>省级重点职业学校`, Year: '2011' },
        { ID: 6, Img: Img6, String: `人物形象设计专业<br/>被评为省级特色专业`, Year: '2014' },
        { ID: 7, Img: Img7, String: `职业技能学校<br/>迁入博物院校区`, Year: '2017' },
        { ID: 8, Img: Img8, String: `与河北女院合作成立花都<br/>校区形成中高职衔接混合<br/>所有制模式`, Year: '2018' },
        { ID: 9, Img: Img9, String: `肖家营校区启用<br/>技工学校审批成功`, Year: '2019' },
        { ID: 10, Img: Img10, String: `阿里山校区启用`, Year: '2021' },
        { ID: 11, Img: Img11, String: `与河北女院深度合作<br/>成立形象设计二级学院`, Year: '2021' },
      ],
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper
          style={{
            background: `url(${sp1}) no-repeat`,
            backgroundSize: '100% 100%',
            overflow: 'visible',
            padding: '4.5rem 11rem',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'end'
          }}>
          <p style={{ color: '#ba0f22', fontSize: '3.33rem', fontWeight: '700', letterSpacing: '2px' }}>学校概况</p>
          <p style={{ color: '#fff', fontSize: '1.68rem', fontWeight: '700', letterSpacing: '2px' }}>近三十年来学校始终坚持文化立校、特色办学、改革创新多元发展的办学宗旨</p>
          <p style={{ color: '#fff', fontSize: '1.68rem', fontWeight: '700', letterSpacing: '2px' }}>遵循“尚德、至善、存真、尚美”的校训</p>
        </styled.Swipper>
        <styled.NewsBox style={{ minHeight: '26rem', justifyContent: 'space-between', position: 'relative', background: '#edf8fa', padding: '0 20rem' }}>
          <span style={{ color: 'rgb(102, 102, 102)', fontSize: '.88rem', height: '100%', display: 'flex', flexDirection: 'column', maxWidth: '50%' }}>
            <span>
              <p style={{ fontSize: '1.68rem', color: '#ba0f22', marginTop: '3rem', fontWeight: 'bold', letterSpacing: '2px' }}>办学宗旨</p>
              <div style={{ width: '3.6rem', height: '6px', background: '#ba0f22', margin: '1rem 0' }} />
            </span>
            <span style={{ overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '11', lineHeight: '1.5rem', letterSpacing: '.15rem' }}>坚持走长短结合、校企合作、中高职衔接、升学就业国际交流的多元办
              学道路,以创建品牌特色为先导,以培养复合型应用人才为目标,实现高职
              带中职、中职互补高职、以长促短、以短促活、混合所有、共赢发展。
              为学生的成长、成才、成功搭建了广阔的平台,就业有出路,升学有通道,
              积极引领学生创业发展。学校坚持统筹规划、强化管理制度治校、专家
              引领、打造文化、注重效益、追逐质量品牌立校的内部机制建设,不断改
              善办学，提升品质,创设温馨和谐、优美文雅的校园环境,促进学校不断
              发展。</span>
          </span>
          <span style={{ width: '24rem', height: '100%', background: `url(${sp2}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }} />
        </styled.NewsBox>
        <styled.NewsBox style={{ justifyContent: 'space-between', position: 'relative', margin: '5rem 0', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ fontSize: '1.68rem', color: '#ba0f22', fontWeight: 'bold', letterSpacing: '2px' }}>28年职业教育办学品质</p>
          <div style={{ width: '3.6rem', height: '6px', background: '#ba0f22', margin: '1rem 0' }} />
          <p style={{ fontSize: '.77rem', color: 'rgb(110, 110, 110)', letterSpacing: '1px' }}>经过28年的蓬勃发展,花都己成为集短期培训,学历教育、时尚连锁、产品研发为一体的时尚教育集团 </p>
          <p style={{ fontSize: '.77rem', color: 'rgb(110, 110, 110)', letterSpacing: '1px', padding: '.5rem 0' }}>拥有多家分校及连锁店，致力于培养高素质、高学历、高技能的综合性人才;为学院提供技能教育、学历教育、就业指导、创业扶持等一体化服务</p>
          <p style={{ fontSize: '.77rem', color: 'rgb(110, 110, 110)', letterSpacing: '1px' }}>已为社会培养80000余名精英人才,得到了学生及社会各界的一致认可与好评!</p>

        </styled.NewsBox>
        <styled.NewsBox style={{ padding: '0 6rem', }}>
          {this.state.course.map((item, index) => {
            return (
              <styled.CourseCard key={index} style={{ display: index % 2 === 1 ? 'none' : 'inline-flex', }}>
                <img src={item.Img} alt='' style={{ width: '90%' }} />
                <p dangerouslySetInnerHTML={{ __html: item.String }} />
                <span />
                <i>{item.Year}</i>
              </styled.CourseCard>
            )
          })}
        </styled.NewsBox>
        <styled.NewsBox style={{ padding: '0 6rem', width: '100%', height: '4rem', fontSize: '1.68rem', color: '#ba0f22', fontWeight: 'bold', letterSpacing: '2px', alignItems: 'center', justifyContent: 'center', display: 'flex', margin: '2.5rem 0' }}>
          <span>发展历程</span>
          <span style={{ flex: '1', height: '4px', background: '#ba0f22', marginLeft: '.5rem', position: 'relative' }}>
          </span>
        </styled.NewsBox>
        <styled.NewsBox style={{ padding: '0 6rem', marginBottom: '5rem' }}>
          {this.state.course.map((item, index) => {
            return (
              <styled.CourseCard key={index} style={{ display: index % 2 !== 1 ? 'none' : 'inline-flex', transform: 'translateX(12.5rem)' }}>
                <p dangerouslySetInnerHTML={{ __html: item.String }} />
                <img src={item.Img} alt='' style={{ width: '90%' }} />
                <span style={{ top: '-2rem' }} />
                <i style={{ top: `calc(-5.1rem - .5px)` }} >{item.Year}</i>
              </styled.CourseCard>
            )
          })}
          <styled.CourseCard style={{ display: 'inline-flex', transform: 'translateX(12.5rem)' }}>
            <i style={{ top: `calc(-5.1rem - .5px)` }} >未来</i>
          </styled.CourseCard>
        </styled.NewsBox>
        <styled.NewsBox style={{ justifyContent: 'space-between', position: 'relative', background: '#edf8fa', padding: '0' }}>
          {/* <div style={{ width: '100%', height: '20.5rem', background: `url(${sp4}) no-repeat`, backgroundSize: '100% 100%', }}></div> */}
          <styled.ImgBackground style={{ width: '100%', height: '20.5rem', background: `url(${sp4}) no-repeat`, backgroundSize: '100% 100%', }}>
            <styled.ImgMask>
              <p style={{ fontSize: '1.68rem' }}>党团建设</p>
              <div style={{ width: '3.6rem', height: '6px', background: '#ba0f22', margin: '1rem 0' }} />
              <p style={{ width: '50%', marginBottom: '3rem' }}>中国共产主义青年团是中国共产党领导下的先进青年的群团组织，是青年在实践中 学习中国特色社会主义和共产主义的学校，是党的助手和后备军，是党联系青年学生的桥梁和纽带。</p>
              <styled.More>
                <span>+ MORE</span><span style={{ background: '#bc1425' }} onClick={() => { this.pushPage('/home/schoolprofile/party', '党团建设') }}>点击进入</span>
              </styled.More>
            </styled.ImgMask>
          </styled.ImgBackground>
        </styled.NewsBox>
        <styled.NewsBox style={{ justifyContent: 'space-between', position: 'relative', background: '#edf8fa', padding: '0' }}>
          <styled.ImgBackground onClick={() => { this.pushPage('/home/schoolprofile/picture', '花都影像') }} style={{ flex: '1', height: '25rem', background: `url(${sp5}) no-repeat`, backgroundSize: '100% 100%', }}>
            <styled.ImgMask>
              <p style={{ fontSize: '1.68rem' }}>花都影像</p>
              <img src={rr} alt='' style={{ width: '5rem', marginTop: '1rem' }} />
            </styled.ImgMask>
          </styled.ImgBackground>
          <styled.ImgBackground onClick={() => { this.pushPage('/home/schoolprofile/leader', '领导团队') }} style={{ flex: '1', height: '25rem', background: `url(${sp6}) no-repeat`, backgroundSize: '100% 100%', }}>
            <styled.ImgMask>
              <p style={{ fontSize: '1.68rem' }}>领导团队</p>
              <img src={rr} alt='' style={{ width: '5rem', marginTop: '1rem' }} />
            </styled.ImgMask>
          </styled.ImgBackground>
          <styled.ImgBackground onClick={() => { this.pushPage('/home/schoolprofile/distribution', '校区分布') }} style={{ flex: '1', height: '25rem', background: `url(${sp7}) no-repeat`, backgroundSize: '100% 100%', }}>
            <styled.ImgMask>
              <p style={{ fontSize: '1.68rem' }}>校区分布</p>
              <img src={rr} alt='' style={{ width: '5rem', marginTop: '1rem' }} />
            </styled.ImgMask>
          </styled.ImgBackground>
          <styled.ImgBackground onClick={() => { this.pushPage('/home/schoolprofile/contact', '联系我们') }} style={{ flex: '1', height: '25rem', background: `url(${sp8}) no-repeat`, backgroundSize: '100% 100%', }}>
            <styled.ImgMask>
              <p style={{ fontSize: '1.68rem' }}>联系我们</p>
              <img src={rr} alt='' style={{ width: '5rem', marginTop: '1rem' }} />
            </styled.ImgMask>
          </styled.ImgBackground>
        </styled.NewsBox>
        <Footer history={this.props.history} />
      </styled.ContentBox>

    )
  }
  //
  pushPage(p, s) {
    this.props.history.push({ pathname: p, state: s })
  }
}
