import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'


import { homeRoutes } from '../routes'

import pl1 from '../../public/pl1.jpg'
import pabcg from '../../public/pabcg.jpg'
import e2 from '../../public/e2.png'
import pl2 from '../../public/xq1.jpg'
import pl3 from '../../public/xq2.jpg'
import pl4 from '../../public/xq3.jpg'
import pl5 from '../../public/xq4.jpg'
// import rr from '../../public/r右.png'

export default class Distribution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [
        { ID: 1, Name: '红旗校区', Img: pl2, Address: '石家庄桥西区红旗大街337号', Website: 'http://www.sjzhuadu.cn', Nub1: '0311-86618000'},
        { ID: 2, Name: '中华校区', Img: pl4, Address: '石家庄长安区古城西路3号', Website: 'http://www.sjzhuadu.cn', Nub1: '0311-86618000'},
        { ID: 3, Name: '阿里山校区', Img: pl5, Address: '石家庄经济开发区阿里山大街19号', Website: 'http://www.sjzhuadu.cn', Nub1: '0311-86618000'},
        
       
      ],
    }
  }
  render() {
    return (
      <styled.ContentBox style={{ alignItems: 'center' }}>
        <Banner history={this.props.history} active={'学校概况'} />
        <styled.Swipper style={{ background: `url(${pl1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>校区分布</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '45rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>校区分布</div>
        </styled.Swipper>
        <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', background: `url(${pabcg}) no-repeat`, backgroundSize: '100% 100%', }}>
          <div style={{ width: '70%', flexWrap: 'wrap' }}>
            {this.state.list.map((item, index) => {
              return (
                <styled.ImageItem3 key={index} onClick={() => { this.pushPage(item.ID) }} img={item.Img} style={{ backgroundSize: '100% 100%' }}><i>{item.Name}</i>
                  <div>
                    <span style={{ fontSize: '1.33rem', fontWeight: 'bold', marginBottom: '2rem' }}>{item.Name}</span>
                    <span>
                      <p>地址 : {item.Address}</p>
                      <p>官方网站 : {item.Website}</p>
                      <p>招生电话 : {item.Nub1}</p>
                    </span>
                  </div>
                </styled.ImageItem3>
              )
            })}
          </div>
          <div style={{ width: '20%' }}>
            <RightBar active={'校区分布'} history={this.props.history} routes={homeRoutes[1]} />
          </div>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  pushPage(s) {
    this.props.history.push({ pathname: '/home/schoolprofile/contact', state: s })
  }
  //////////////////////
  getList() {
    // service.request({
    //     method: 'get',
    //     url: '',
    // }).then(res => {
    //     if (res.data.code === 200) {
    //         this.setState({ list: res.data.data })
    //     }
    // })
  }
}
