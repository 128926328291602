import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
// import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import { homeRoutes } from '../routes'
import '../../public/iconfont'
import s1 from '../../public/s1.jpg'
import e2 from '../../public/e2.png'

import ea1 from '../../public/ea1.jpg'
import pabcg from '../../public/pabcg.jpg'


export default class Admissions extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'招生报名'} />
                <styled.Swipper style={{ background: `url(${s1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>中专招生</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>招生报名<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>中专招生</div>
                </styled.Swipper>
                <styled.EducationBox style={{ paddingTop: '5rem',paddingBottom: '5rem', justifyContent: 'space-between', background: `url(${pabcg}) repeat`, backgroundSize: '100% 100%', }}>
                    <div style={{ width: '70%', padding: '2rem 0', flexDirection: "column" }}>
                       <img src={ea1} alt=''/>
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={'中专招生'} history={this.props.history} routes={homeRoutes[4]} />
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
    
}


