import React, { Component } from 'react'
import * as styled from '../Utlis/styled'

export default class Pagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 1,
            nums: [],

        }
    }
    componentDidMount() {
        const arr = [...new Array(Math.ceil(this.props.max)).keys()];
        this.setState({
            nums: arr,
        })

    }
    render() {
        return (
            <styled.Pagination>
                {this.props.page === 1 ? '' : (<div onClick={this.changpageAction.bind(this, 1)} >首页</div>)}
                {this.props.page === 1 ? '' : (<div onClick={this.changpageAction.bind(this, this.props.page - 1)}>上页</div>)}
                {this.state.nums.map((item, index) => {
                    let alive = {}
                    if (this.props.page === (item + this.state.index)) { alive = { color: '#fff', border: 'none', background: this.props.campus === 1 ? '#260ea2' : '#ba0f22' } }
                    if ((item + this.state.index) > Math.ceil(this.props.count / 8)) { alive = { display: 'none' } }
                    return (
                        <div
                            style={alive}
                            key={index}
                            onClick={this.changpageAction.bind(this, item + this.state.index)}>
                            {item + this.state.index}
                        </div>
                    )
                })}
                {this.props.page === Math.ceil(this.props.count / 8) ? '' : (<span>...</span>)}
                {this.props.page === Math.ceil(this.props.count / 8) ? '' : (<div onClick={this.changpageAction.bind(this, this.props.page + 1)}>下页</div>)}
                <div onClick={this.changpageAction.bind(this, Math.ceil(this.props.count / 8))}>尾页</div>
                <div style={{ border: 'none' }}>共{this.props.count}条</div>
            </styled.Pagination>
        )
    }

    //
    changpageAction(page) {
        if (page >= this.state.index && page < (this.state.index + this.props.max)) {
            this.props.onPaging(page)
        } else if (page === this.state.index + this.props.max && page !== Math.ceil(this.props.count / 8)) {
            this.setState((state) => {
                return { index: state.index + 1 }
            }, () => {
                this.props.onPaging(page)
            })
        } else if (page < this.state.index && page >= 1) {
            this.setState(() => {
                return { index: page }
            }, () => {
                this.props.onPaging(page)
            })
        } else if (page === Math.ceil(this.props.count / 8)) {
            this.setState(() => {
                return { index: page }
            }, () => {
                this.props.onPaging(page)
            })

        }
    }

}
