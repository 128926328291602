import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
import service from '../Utlis/request'





export default class Swipper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // swipper: [{ ID: 1, Path: swipper1 }, { ID: 2, Path: swipper2 }, { ID: 3, Path: swipper1 }, { ID: 4, Path: swipper2 }, { ID: 5, Path: swipper1 }, { ID: 1, Path: swipper1 }],
            swipper: [],
            swipperIndex: 0,
            newswipper: [],
            timer:{}
        }

        
    }
    ChangeImage = (index) => {
        let swipperIndex = this.state.swipperIndex;
        if(index == swipperIndex){
            return;
        }
        this.state.swipperIndex = index -1; 
        console.log(swipperIndex);
        this.setState(index -1)
        // clearInterval(this.swipper.timer);
    }
    componentDidMount() {
        this.swipper()
        this.getSwipper()
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        // const newswipper = this.state.swipper.slice(0, this.state.swipper.length - 1)
        return (
            <div>
                <styled.Swipper >
                    <styled.SwipperContent id='swipper' left={(this.state.swipperIndex * -100)+'%'} end={this.state.swipperIndex === 0 ? 1 : 0}>
                        {this.state.swipper.map((item, index) => {
                            return (
                                <span key={index} style={{ height: '100%', minWidth: '100%', background: `url(${"http://49.4.2.15:9090/"+item.Path}) no-repeat`, display: '-webkit-flex', backgroundSize: '100% 100%' }} />
                            )
                        })}
                        {/* <span style={{ height: '100%', minWidth: '100%', background: `url(${this.state.swipper[0].Path}) no-repeat`, display: '-webkit-flex', backgroundSize: '100% 100%' }} /> */}
                    </styled.SwipperContent>
                </styled.Swipper>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1rem' }}>
                    {this.state.swipper.map((item, index) => {
                        return (
                            // <styled.SwipperPoint key={index} active={index === this.state.swipperIndex || index === 0 && this.state.swipperIndex === this.state.swipper.length - 1 ? true : false}><use xlinkHref='#icon-dian'></use></styled.SwipperPoint>
                            <styled.SwipperPoint key={index} active={index === this.state.swipperIndex ? true : false} onClick={ () => this.ChangeImage(index)}><use xlinkHref='#icon-dian'></use></styled.SwipperPoint>
                        )
                    })}
                </div>
            </div>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    getSwipper() {
        service.request({
            method: 'get',
            url: '/slideshow',
        }).then(res => {
            if (res.code === 200) {
                this.setState({ swipper: res.data })
            }
        })
    }

    swipper() {

        //定时器
        this.state.timer = setInterval(() => {
            let swipper = this.state.swipper;
            let swipperIndex = this.state.swipperIndex;
            if (swipperIndex === swipper.length - 1) {
                swipperIndex = 0;
            } else {
                swipperIndex++;
            }
            this.setState({ swipperIndex })
        },  1500);

        //鼠标移入移出
        document.getElementById('swipper').onmouseover = () => {
            clearInterval(this.state.timer);
        }
        document.getElementById('swipper').onmouseout = () => {
            this.state.timer = setInterval(() => {
                let swipper = this.state.swipper;
                let swipperIndex = this.state.swipperIndex;
                if (swipperIndex === swipper.length - 1) {
                    swipperIndex = 0;
                } else {
                    swipperIndex++;
                }
                this.setState({ swipperIndex })
            }, 1500);
        }
        
        // ResetImage = ()=>{
        //     console.log('000000000000');
        // }
    }
}
