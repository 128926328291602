import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'
import Pagination from '../component/pagination'

import { homeRoutes } from '../routes'

import pp1 from '../../public/pp1.jpg'
// import pp2 from '../../public/pp2.png'

import e2 from '../../public/e2.png'

export default class Dynamic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            news: [{ Cover: '' }],
            news0: [{ Cover: '' }],
            newsType: 1,
            page: 1,
            count: 0,
            visible: false,
            content: {},
        }
    }
    componentDidMount() {
        this.getNews()
        service.request({
            method: 'get',
            url: '/article',
            params: {
                categoryID: 12,
                columnID: 5,
                offset: 0,
                limit: 1,
                isCover: 1
            }
        }).then(res => {
            if (res.code === 200 && res.data.length !== 0) {
                this.setState({ news0: res.data })
            }
        })
    }
    render() {
        let news3 = this.cutList()
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'学校概况'} />
                <styled.Swipper style={{ background: `url(${pp1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>团委动态</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '53rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>学校概况<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>党团建设<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>团委动态</div>
                </styled.Swipper>
                <styled.EducationBox style={{ marginTop: '5rem', justifyContent: 'space-between' }}>
                    <div style={{ width: '70%', padding: '2rem 0', display: this.state.visible ? 'none' : 'flex', justifyContent: 'space-between' }}>
                        <div onClick={() => { this.showContent(true, this.state.news0[0]) }} style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span><p style={{ fontSize: '.87rem', color: '#ba0f22', fontWeight: '700' }}>最新资讯</p><p style={{ color: 'rgb(102, 102, 102)', fontSize: '.75rem', fontWeight: '700' }}>LATEST NEWS</p></span>
                            <span style={{ width: '100%', height: '14rem', }} ><img src={'http://49.4.2.15:9090/' + this.state.news0[0].Cover} style={{ width: '100%', height: '14rem' }} /></span>
                            {/* <span style={{ width: '100%', height: '12rem', background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(this.state.news0[0].Cover)}) no-repeat`, backgroundSize: '100% 100%' }} /> */}
                            <styled.NewsCard>
                                <span style={{ textAlign: 'center' }}><p style={{ fontSize: '1.2rem', fontWeight: '700' }}>{this.formatDate(this.state.news0[0].Time / 1000000, 1)}</p><p style={{ fontSize: '.6rem' }}>{this.formatDate(this.state.news0[0].Time / 1000000, 2)}</p></span>
                                <span style={{ maxWidth: '70%' }}>
                                    <p style={{ fontSize: '.77rem', color: '#555', marginBottom: '.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{this.state.news0[0].Title}</p>
                                    <p style={{ fontSize: '.55rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(this.state.news0[0].Content)}</p>
                                </span>
                                <styled.TipsIcon style={{ backgroundSize: '100% 100%' }}>HOT</styled.TipsIcon>
                            </styled.NewsCard>
                        </div>
                        <div style={{ width: '45%', height: '100%', display: this.state.visible ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            {news3.map((item, index) => {
                                return (
                                    <styled.NewsCard onClick={() => { this.showContent(true, item) }} key={index}>
                                        <span style={{ textAlign: 'center', padding: '1rem 0' }}><p style={{ fontSize: '1.2rem', fontWeight: '700' }}>{this.formatDate(item.Time / 1000000, 1)}</p><p style={{ fontSize: '.6rem' }}>{this.formatDate(item.Time / 1000000, 2)}</p></span>
                                        <span style={{ width: '70%', padding: '1rem', borderLeft: '3px solid #f1f1f1', borderTop: index === 0 ? '' : '2px solid #f1f1f1' }}>
                                            <p style={{ fontSize: '.77rem', color: '#555', marginBottom: '1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                            <p style={{ fontSize: '.55rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                        </span>
                                        <styled.TipsIcon style={{ backgroundSize: '100% 100%', margin: '1rem 0' }}>HOT</styled.TipsIcon>
                                    </styled.NewsCard>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: '70%', maxWidth: '55rem', height: 'calc(100% - 4rem)', padding: '2rem 0', display: this.state.visible ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {/* 正文 */}
                        <div style={{ width: '100%', padding: '1rem 0', color: '#ba0f22', textAlign: 'center', borderBottom: '1px solid #ccc', }}>{this.state.content.Title}</div>
                        <div style={{ width: '100%', padding: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '.66rem' }}>
                            <styled.HoverBox onClick={() => { this.showContent(false, {}) }} style={{ color: '#ba0f22' }}><span >返回</span></styled.HoverBox>
                            <span>上传时间 : {this.formatDate(this.state.content.Time / 1000000)}</span>
                        </div>
                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: this.state.content.Content }} />
                    </div>
                    <div style={{ width: '20%', height: '200%' }}>
                        <RightBar active={'党团建设'} active_next={'团委动态'} history={this.props.history} routes={homeRoutes[1]} />
                    </div>
                    {/* <styled.HoverBox style={{ position: 'absolute', bottom: '2rem', left: '45%', fontSize: '.84rem', color: '#ba0f22', letterSpacing: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div>MORE</div>
                        <styled.Icon><use xlinkHref='#icon-jiantouxia'></use></styled.Icon>
                    </styled.HoverBox> */}

                </styled.EducationBox>
                <styled.EducationBox style={{ display: this.state.visible ? 'none' : 'flex', }}>
                    <div style={{ width: '70%', padding: '1rem', flexDirection: 'column', alignItems: 'center' }}>
                        <styled.NewList>
                            {this.state.news.map((item, index) => {
                                return (
                                    <div onClick={() => { this.showContent(true, item) }} key={index}><span>一一{item.Title}</span><span>{this.formatDate(item.Time / 1000000)}</span></div>
                                )
                            })}
                        </styled.NewList>
                        <div style={{ marginTop: '3rem' }}>
                            <Pagination page={this.state.page} max={3} onPaging={(page) => { this.setState({ page }, () => { this.getNews() }) }} count={this.state.count} />
                        </div>
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history} />
            </styled.ContentBox>
        )
    }
    // , overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '16', lineHeight: '1.5rem', letterSpacing: '.15rem'

    getNews() {
        let offset = (this.state.page - 1) * 8
        service.request({
            method: 'get',
            url: '/article',
            params: {
                categoryID: 12,
                columnID: 5,
                offset: offset,
                limit: 8,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ news: res.data, count: res.count })
            }
        })
    }
    //
    formatDate(dates, type) {
        var date = new Date(dates);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        if (type === 1) { return y }
        if (type === 2) { return m + '.' + d }
        if (!type) { return y + '-' + m + '-' + d }
    }
    //
    showContent(flag, item) {
        this.setState({ visible: flag, content: item })
    }
    // changeNewsType(newsType) {
    //     this.setState({ newsType })
    // }
    //
    // isType(t) {
    //     if (this.state.newsType === t) { return 1 }
    //     return 2
    // }
    //
    cutList() {
        if (this.state.news.length !== 0) {
            return this.state.news.slice(0, 3)
        }
        return []
    }
    getSimpleText(html) {
        if (!html) { return }
        var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
        var m = rx.exec(html);
        if (m) {
            m = m[1];
        } else {
            m = html
        }
        var re1 = new RegExp("<.+?>", "g")
        var msg = m.replace(re1, '')
        msg = msg.replace(/&nbsp;/g, ' ')
        return msg
    }
}

