import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import cookie from 'react-cookies'
import '../../public/iconfont'
import service from '../Utlis/request'

// import img from '../../public/3.png'
import news1 from '../../public/news1.png'
import blue1 from '../../public/blue1.png'
import blue2 from '../../public/blue2.png'
import x from '../../public/st2.jpg'
import r from '../../public/右.png'

export default class Campus_News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imgs: [{ Cover: '', Title: '', Time: 0 }],
            imgIndex: 0,
            news: [{ Cover: '', Title: '', Time: 0 }, { Cover: '', Title: '', Time: 0 }, { Cover: '', Title: '', Time: 0 }, { Cover: '', Title: '', Time: 0 }, { Cover: '', Title: '', Time: 0 }],
            type: '动态',
            news5: []
        }
    }
    componentDidMount() {
        this.getNews()
        service.request({
            method: 'get',
            url: '/article',
            params: {
                isCover: 1,
                columnID: 1,
                limit: 5,
            }
        }).then(res => {
            if (res.code === 200 && res.data.length !== 0) {
                this.swipper()
                this.setState({ imgs: res.data })
            }
        })
    }
    render() {
        let news5 = this.cutNews()
        return (
            <div style={{ width: "100%", minHeight: '40rem', background: `url(${news1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'hidden', padding: '4rem 0', display: "flex", justifyContent: 'center', position: 'relative' }}>
                <div style={{ fontSize: '2rem', letterSpacing: '2px' }}>校区新闻</div>
                <img alt='' src={blue2} style={{ position: 'absolute', left: '0', height: "100%" }} />
                <div style={{ width: '90%', height: '80%', position: "absolute", right: '0', bottom: '0', background: '#fff', display: "flex" }}>
                    <div style={{ height: "100%", flex: "1", padding: '2rem 1rem', display: "flex", flexDirection: "column", justifyContent: "space-around", position: "relative" }}>
                        <div style={{ width: '100%', display: 'inline-flex', alignItems: 'center', color: '#1b019e', fontSize: '1rem', fontWeight: 'bold', }}>
                            <span style={{ width: '4px', height: '1.2rem', background: '#1b019e', marginRight: '10px' }} />
                            头条新闻
                            <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.imgs[this.state.imgIndex] }); cookie.save('campus', "校区新闻") }} style={{ width: '60%', display: "-webkit-box", WebkitBoxOrient: 'vertical', WebkitLineClamp: '1', overflow: 'hidden', fontWeight: "400", color: "#000", marginLeft: '3rem' }}>{this.getSimpleText(this.state.imgs[this.state.imgIndex].Content)}</styled.HoverBox1>
                        </div>
                        <styled.ImageItem4 id='newimg' style={{ width: "90%", height: "85%", transform: 'translateX(-10%)', border: "none" }}>
                            <div style={{ background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(this.state.imgs[this.state.imgIndex].Cover)}) no-repeat`, }} />
                            <span style={{ display: 'flex' }}>
                                <p style={{ display: "-webkit-box", WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', overflow: 'hidden' }}>{this.getSimpleText(this.state.imgs[this.state.imgIndex].Content)}</p>
                            </span>
                        </styled.ImageItem4>
                        <div style={{ position: 'absolute', right: "0", height: '85%', width: '20%', display: "flex", justifyContent: "space-around", alignItems: "end" }}>
                            <styled.HoverIcon2 onClick={() => { this.pre() }} style={{ height: "3rem", width: "2rem" }}><use xlinkHref='#icon-qiehuanqizuo'></use></styled.HoverIcon2>
                            <styled.HoverIcon2 onClick={() => { this.next() }} style={{ height: "3rem", width: "2rem" }}><use xlinkHref='#icon-qiehuanqiyou'></use></styled.HoverIcon2>
                        </div>
                    </div>
                    <div style={{ height: '100%', flex: '1.2', background: `url(${blue1}) no-repeat`, backgroundSize: '100% 100%', padding: '4rem 2rem', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ width: '100%', display: 'inline-flex', alignItems: 'center', color: '#fff', fontSize: '1rem', fontWeight: 'bold', }}>
                            <span style={{ width: '4px', height: '1.2rem', background: '#ffc422', marginRight: '10px' }} />
                            <styled.HoverBox1 onClick={() => { this.setState({ type: '动态' }, this.getNews()) }} style={{ color: this.state.type == "动态" ? "#ffc422" : "" }}>实时动态</styled.HoverBox1>
                            <styled.Icon style={{ margin: '0 .5rem', fill: '#fff', height: "1.5rem", width: "1.5rem" }}><use xlinkHref='#icon-dian'></use></styled.Icon>
                            <styled.HoverBox1 onClick={() => { this.setState({ type: '活动' }, this.getNews()) }} style={{ color: this.state.type == "活动" ? "#ffc422" : "" }}>实践活动</styled.HoverBox1>
                        </div>
                        <div style={{ width: "100%", height: "90%", background: "", display: "flex" }}>
                            <div style={{ width: '35%', height: '100%', display: "flex", flexDirection: "column", justifyContent: "space-between", color: "#fff" }}>
                                <img alt='' src={'http://49.4.2.15:9090/' + encodeURIComponent(this.state.news[0].Cover)} style={{ height: "40%" }} />
                                <p>{this.formatDate(this.state.news[0].Time / 1000000)}</p>
                                <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.news[0] }); cookie.save('campus', "校区新闻") }} style={{ display: "-webkit-box", WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', overflow: 'hidden' }}>{this.state.news[0].Title}</styled.HoverBox1>
                                <p style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "4", overflow: "hidden", fontSize: ".77rem", letterSpacing: "2px", lineHeight: "1.5em" }}>{this.getSimpleText(this.state.news[0].Content)}</p>
                            </div>
                            <div style={{ width: 'calc(65% - 3.5rem)', height: '100%', marginLeft: "3.5rem", color: "#fff" }}>
                                <div style={{ width: "100%", height: '25%', display: "flex", flexDirection: "column", justifyContent: 'space-around', borderBottom: "1px dotted #710db7" }}>
                                    <p>{this.formatDate(this.state.news[1].Time / 1000000)}</p>
                                    <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.news[1] }); cookie.save('campus', "校区新闻") }} style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", fontSize: ".77rem", letterSpacing: "2px", lineHeight: "1.5em" }}>{this.state.news[1].Title}</styled.HoverBox1>
                                </div>
                                <div style={{ width: "100%", height: '25%', display: "flex", flexDirection: "column", justifyContent: 'space-around', borderBottom: "1px dotted #710db7" }}>
                                    <p>{this.formatDate(this.state.news[2].Time / 1000000)}</p>
                                    <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.news[2] }); cookie.save('campus', "校区新闻") }} style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", fontSize: ".77rem", letterSpacing: "2px", lineHeight: "1.5em" }}>{this.state.news[2].Title}</styled.HoverBox1>
                                </div>
                                <div style={{ width: "100%", height: '25%', display: "flex", flexDirection: "column", justifyContent: 'space-around', borderBottom: "1px dotted #710db7" }}>
                                    <p>{this.formatDate(this.state.news[3].Time / 1000000)}</p>
                                    <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.news[3] }); cookie.save('campus', "校区新闻") }} style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", fontSize: ".77rem", letterSpacing: "2px", lineHeight: "1.5em" }}>{this.state.news[3].Title}</styled.HoverBox1>
                                </div>
                                <div style={{ width: "100%", height: '25%', display: "flex", flexDirection: "column", justifyContent: 'space-around', borderBottom: "1px dotted #710db7" }}>
                                    <p>{this.formatDate(this.state.news[4].Time / 1000000)}</p>
                                    <styled.HoverBox1 onClick={() => { this.props.history.push({ pathname: '/campus_news/' + this.props.id, article: this.state.news[4] }); cookie.save('campus', "校区新闻") }} style={{ display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: "2", overflow: "hidden", fontSize: ".77rem", letterSpacing: "2px", lineHeight: "1.5em" }}>{this.state.news[4].Title}</styled.HoverBox1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////
    formatDate(time) {
        var date = new Date(time);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        return y + '-' + m + '-' + d;
    }
    //
    getSimpleText(html) {
        if (!html) { return }
        var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
        var m = rx.exec(html);
        if (m) {
            m = m[1];
        } else {
            m = html
        }
        var re1 = new RegExp("<.+?>", "g")
        var msg = m.replace(re1, '')
        msg = msg.replace(/&nbsp;/g, ' ')
        return msg
    }
    //
    next() {
        if (this.state.imgIndex === 4) {
            return
        }
        this.setState({ imgIndex: this.state.imgIndex + 1 })
    }
    pre() {
        if (this.state.imgIndex === 0) {
            return
        }
        this.setState({ imgIndex: this.state.imgIndex - 1 })
    }
    //
    changeNewsType(s) {
        this.setState({ type: s }, () => { this.getNews(1); this.getNews() })
    }
    //
    cutNews() {
        if (this.state.imgs.length !== 0) {
            return this.state.imgs.slice(0, 5)
        }
        return []
    }
    //
    swipper() {
        //定时器
        let timer = setInterval(() => {
            let imgIndex = this.state.imgIndex;
            if (imgIndex === 4) {
                imgIndex = 0;
            } else {
                imgIndex++;
            }
            this.setState({ imgIndex })
        }, 3000);

        //鼠标移入移出
        document.getElementById('newimg').onmouseover = () => {
            clearInterval(timer);
        }
        document.getElementById('newimg').onmouseout = () => {
            timer = setInterval(() => {
                let imgIndex = this.state.imgIndex;
                if (imgIndex === 4) {
                    imgIndex = 0;
                } else {
                    imgIndex++;
                }
                this.setState({ imgIndex })
            }, 3000);
        }
    }
    //
    pushPage(item) {
        if (this.state.type === '头条') {
            this.props.history.push({ pathname: '/home/news/newslist1', state: '花都头条', article: item })
        }
        if (this.state.type === '动态') {
            this.props.history.push({ pathname: '/home/news/newslist2', state: '实时动态', article: item })
        }
        if (this.state.type === '活动') {
            this.props.history.push({ pathname: '/home/news/newslist3', state: '实践活动', article: item })
        }
    }
    //
    getNews() {
        var type
        if (this.state.type === '头条') {
            type = 1
        }
        if (this.state.type === '动态') {
            type = 2
        }
        if (this.state.type === '活动') {
            type = 3
        }
        service.request({
            method: 'get',
            url: '/article',
            params: {
                campusID: this.props.id,
                categoryID: type,
                columnID: 1,
                limit: 5,
                isCover: 1
            }
        }).then(res => {
            this.setState({ news: res.data })
        })
    }
}
