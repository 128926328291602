import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'
import Pagination from '../component/pagination'

import { homeRoutes } from '../routes'

import s2 from '../../public/s2.jpg'
import ebcg from '../../public/ebcg.jpg'
// import ep1 from '../../public/ep1.png'
// import ep3 from '../../public/ep3.png'


import e2 from '../../public/e2.png'

export default class Start extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgs: [],
      offset: 0,
    }
  }
  componentDidMount() {
    this.getImages()
  }
  render() {
    return (
      <styled.ContentBox>
        <Banner history={this.props.history} active={'教学科研'} />
        <styled.Swipper style={{ background: `url(${s2}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
          <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>升学明星</div>
          <div
            style={{
              position: 'absolute',
              zIndex: '99',
              background: `url(${e2}) no-repeat`,
              backgroundSize: '100% 100%',
              width: '53rem',
              height: '4.5rem',
              paddingLeft: '18rem',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              bottom: '0',
              transform: 'translateY(50%)',
              letterSpacing: '.1rem',
            }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>教学科研<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>升学明星</div>
        </styled.Swipper>
        <styled.EducationBox style={{ margin: '5rem 0', justifyContent: 'space-between', position: 'relative', background: `url(${ebcg}) no-repeat`, backgroundSize: '100% 100%' }}>
          <div style={{ width: '70%', height: 'calc(100% - 4rem)', padding: '1rem 0', flexWrap: 'wrap' }}>
            {this.state.imgs.map((item, index) => {
              return (
                <styled.PictrueCard>
                   <span style={{width:'100%',height:'100%',background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(item.Path)}) no-repeat`,backgroundSize: '100% 100%',border:'2px solid'}}/>
                  {/* ,borderImage:'linear-gradient(to right, #ba0f22, #fff) 1', */}
                  {/* <span style={{width:'100%',flex:'1',background: '#ba0f22',padding:'.5rem .3rem'}}>
                    <img src={ep3} alt=''/>
                    <span style={{fontSize:'.88rem',fontWeight: 'bold',marginBottom:'.5rem'}}>{item.Name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总分 :&nbsp;&nbsp;{item.Score} </span>
                    <p>录取院校 : {item.Academy}</p>
                    <p>录取专业 : {item.Professional}</p>
                    <p>报考大类 : {item.Class}</p>
                    <p>家庭地址 : {item.Address}</p>
                  </span> */}
                </styled.PictrueCard>
                
              )
            })}
          </div>
          <div style={{ width: '20%' }}>
            <RightBar active={'升学明星'} active_next={'榜样力量'} history={this.props.history} routes={homeRoutes[7]} />
          </div>
          <styled.HoverBox onClick={()=>{this.getImages()}} style={{ position: 'absolute', bottom: '-2rem', left: '40%', fontSize: '.84rem', color: '#ba0f22', letterSpacing: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', transform: 'translateX(-50%)' }}>
            <div>MORE</div>
            <styled.Icon><use xlinkHref='#icon-jiantouxia'></use></styled.Icon>
          </styled.HoverBox>
        </styled.EducationBox>
        <Footer history={this.props.history}/>
      </styled.ContentBox>
    )
  }
  /////////////////////////////////////////////////////////////
  getImages(id) {
    service.request({
      method: 'get',
      url: '/example',
      params: {
        category: '',
        column: '升学明星',
        offset: this.state.offset,
        limit: 8,
      }
    }).then(res => {
      if (res.code === 200) {
        let newimgs = this.state.imgs.concat(res.data)
        this.setState({ imgs: newimgs, offset: this.state.offset + 8 })
      }
    })
  }
}
