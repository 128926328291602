import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'

import n1 from '../../public/n1.jpg'
import nb2 from '../../public/nb2.png'

import r from '../../public/右.png'
import rr from '../../public/r右.png'


export default class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            news: [],
            news1: [{ Cover: '' }],
            news2: [],
            news2_cover: [],
            news3: [],
            imgIndex: 0,
        }
    }
    componentDidMount() {
        this.getList(1, 1, 5)
        this.getList(2, 1, 2)
        this.getList(2, 1, 12)
        this.getList(3, 1, 8)
    }
    render() {
        let news4 = this.state.news1.slice(1)
        return (
            <styled.ContentBox style={{ alignItems: 'center' }}>
                <Banner history={this.props.history} active={'花都新闻'} />
                <styled.Swipper
                    style={{
                        background: `url(${n1}) no-repeat`,
                        backgroundSize: '100% 100%',
                        overflow: 'visible',
                        padding: '4.5rem 11rem',
                        flexDirection: 'column',
                        alignItems: 'end',
                        justifyContent: 'end'
                    }}>
                    <p style={{ color: '#ba0f22', fontSize: '3.33rem', fontWeight: '700', letterSpacing: '2px' }}>花都新闻</p>
                    <p style={{ color: '#fff', fontSize: '2.33rem', fontWeight: '700', letterSpacing: '2px' }}>搭建 “多专业、多层次、多路径” 的成才 “立交桥”</p>
                </styled.Swipper>
                <styled.NewsBox style={{ minHeight: '34rem', marginTop: '7rem', position: 'relative' }}>
                    <div style={{ width: 'calc(100% - 23rem)', height: '100%', position: 'absolute', background: `url(${nb2}) no-repeat`, backgroundSize: '100% 100%', right: '0', top: '-2rem', zIndex: '-2' }} />
                    <div onClick={() => { this.props.history.push({ pathname: '/home/news/newslist1', state: '花都头条', article: this.state.news1[0] }) }} style={{ flex: '2.1', background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(this.state.news1[0].Cover)}) no-repeat`, backgroundSize: '100% 100%', padding: '1.7rem 4rem', flexDirection: 'column', justifyContent: 'end' }}>
                        {/* <img src={'http://49.4.2.15:9090/' + this.state.news1[0].Cover} style={{ height: '100%', position: 'absolute',zIndex:'-1'}}/> */}
                        <div style={{ width: '6.5rem', height: '2rem', background: `url(${nb2}) no-repeat`, backgroundSize: '100% 100%', textAlign: 'center', lineHeight: '2rem', color: '#fff', fontSize: '1.1rem', fontWeight: 'bold' }}>
                            花都头条
                        </div>
                        <div style={{ width: '100%', color: '#fff', fontSize: '1.17rem', fontWeight: 'bold', marginTop: '1rem', letterSpacing: '1px', }}>{this.state.news1[0].Title}</div>
                        <div style={{ width: '100%', color: '#fff', fontSize: '.77rem', marginTop: '1rem', letterSpacing: '1px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ width: '60%', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1rem' }}>{this.getSimpleText(this.state.news1[0].Content)}</span>
                            <styled.HoverBox onClick={() => { this.props.history.push({ pathname: '/home/news/newslist1', state: '花都头条' }) }}><img src={r} alt='' /></styled.HoverBox>
                        </div>
                    </div>
                    <div style={{ flex: '1', flexDirection: 'column' }}>
                        {news4.map((item, index) => {
                            return (
                                <styled.NewsCardBox key={index} onClick={() => { this.props.history.push({ pathname: '/home/news/newslist1', state: '花都头条', article: item }) }}>
                                    <span style={{ flex: '1' }}>
                                        <span>{this.formatDate(item.Time / 1000000)}</span>
                                        <p>{item.Title}</p>
                                    </span>
                                    <span style={{ maxWidth: '12rem', border: 'none', padding: '0' }} ><img src={'http://49.4.2.15:9090/' + item.Cover} alt='' style={{ width: '100%', height: '100%' }} /></span>
                                </styled.NewsCardBox>
                            )
                        })}
                    </div>
                </styled.NewsBox>
                <styled.NewsBox style={{ minHeight: '48rem', height: '48rem', margin: '5rem 0', flexDirection: 'column', position: 'relative' }}>
                    <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <span><p style={{ fontSize: '.9rem', color: '#ba0f22', fontWeight: '700' }}>实时动态</p><p style={{ color: 'rgb(102, 102, 102)', fontSize: '.8rem', fontWeight: '700' }}>LATEST NEWS</p></span>
                        <styled.HoverBox onClick={() => { this.props.history.push({ pathname: '/home/news/newslist2', state: '实时动态' }) }}><img src={rr} alt='' /></styled.HoverBox>
                    </div>
                    <div style={{ flex: '1', width: '100%', height: '100%' }}>
                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            {this.state.news2_cover.map((item, index) => {
                                return (
                                    <div onClick={() => { this.props.history.push({ pathname: '/home/news/newslist2', state: '实时动态', article: item }) }} key={index} style={{ flex: '1', maxWidth: '25rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                        {/* <img src={item.Img} alt='' style={{height:'217px'}}/> */}
                                        <span style={{ minWidth: '25rem', height: '16rem', background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(item.Cover)}) no-repeat`, backgroundSize: '100% 100%' }} />
                                        <styled.NewsCard>
                                            <span style={{ textAlign: 'center' }}><p style={{ fontSize: '1.8rem', fontWeight: '700' }}>{this.formatDate(item.Time / 1000000, 1)}</p><p style={{ fontSize: '.7rem' }}>{this.formatDate(item.Time / 1000000, 2)}</p></span>
                                            <span style={{ maxWidth: '60%' }}>
                                                <p style={{ fontSize: '1rem', marginBottom: '.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                                <p style={{ fontSize: '.7rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                            </span>
                                            <styled.TipsIcon style={{ backgroundSize: '100% 100%' }}>HOT</styled.TipsIcon>
                                        </styled.NewsCard>
                                    </div>
                                )
                            })}
                        </div>
                        <styled.ArticleBox style={{ flex: '2.2' }}>
                            {this.state.news2.map((item, index) => {
                                return (
                                    <styled.NewsCard onClick={() => { this.props.history.push({ pathname: '/home/news/newslist2', state: '实时动态', article: item }) }} key={index} style={{ width: 'calc(50% - 4rem)', margin: '1rem 2rem', borderBottom: '2px solid #f2f2f2', paddingBottom: '.5rem' }}>
                                        <span style={{ textAlign: 'center' }}><p style={{ fontSize: '1.8rem', fontWeight: '700' }}>{this.formatDate(item.Time / 1000000, 1)}</p><p style={{ fontSize: '.7rem' }}>{this.formatDate(item.Time / 1000000, 2)}</p></span>
                                        <span style={{ maxWidth: '60%' }}>
                                            <p style={{ fontSize: '1rem', paddingBottom: '1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                            <p style={{ fontSize: '.7rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1.5rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                        </span>
                                        <styled.TipsIcon style={{ backgroundSize: '100% 100%' }}>HOT</styled.TipsIcon>
                                    </styled.NewsCard>
                                )
                            })}
                        </styled.ArticleBox>
                    </div>
                </styled.NewsBox>
                <styled.NewsBox style={{ minHeight: '26rem', height: '26rem', marginBottom: '5rem', flexDirection: 'column', position: 'relative' }}>
                    <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <span><p style={{ fontSize: '.9rem', color: '#ba0f22', fontWeight: '700' }}>实践活动</p><p style={{ color: 'rgb(102, 102, 102)', fontSize: '.8rem', fontWeight: '700' }}>LATEST NEWS</p></span>
                        <styled.HoverBox onClick={() => { this.props.history.push({ pathname: '/home/news/newslist3', state: '实践活动' }) }}><img src={rr} alt='' /></styled.HoverBox>
                    </div>
                    <div style={{ marginTop: '1rem', overflow: 'hidden' }}>
                        <styled.ScrollNews left={`-${this.state.imgIndex * 21 * 4}rem`}>
                            {this.state.news3.map((item, index) => {
                                return (
                                    <styled.NewsPicture onClick={() => { this.props.history.push({ pathname: '/home/news/newslist3', state: '实践活动', article: item }) }} key={index}>
                                        {/* <img src={item.Img} alt='' style={{height:'217px'}}/> */}
                                        <span style={{ width: '100%', height: '12.5rem', background: `url(${'http://49.4.2.15:9090/' + encodeURIComponent(item.Cover)}) no-repeat`, backgroundSize: '100% 100%' }} />
                                        <styled.NewsCard style={{ padding: '1rem' }}>
                                            <span style={{ textAlign: 'center' }}><p style={{ fontSize: '1.2rem', fontWeight: '700' }}>{this.formatDate(item.Time / 1000000, 1)}</p><p style={{ fontSize: '.6rem' }}>{this.formatDate(item.Time / 1000000, 2)}</p></span>
                                            <span style={{ maxWidth: '70%' }}>
                                                <p style={{ fontSize: '.88rem', marginBottom: '.5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.Title}</p>
                                                <p style={{ fontSize: '.6rem', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', lineHeight: '1rem', letterSpacing: '1px' }}>{this.getSimpleText(item.Content)}</p>
                                            </span>
                                        </styled.NewsCard>
                                    </styled.NewsPicture>
                                )
                            })}
                        </styled.ScrollNews>
                    </div>
                    <div style={{ position: 'absolute', left: '50%', bottom: '0', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <styled.HoverIcon onClick={() => { this.pre() }} style={{ width: '2rem', height: '2rem' }}><use xlinkHref='#icon-xiangzuojiantou'></use></styled.HoverIcon>
                        <span style={{ height: '1.8rem', width: '1px', background: '#000', margin: '0 1rem' }} />
                        <styled.HoverIcon onClick={() => { this.next() }} style={{ width: '2rem', height: '2rem' }}><use xlinkHref='#icon-xiangyoujiantou'></use></styled.HoverIcon>
                    </div>
                </styled.NewsBox>
                <Footer history={this.props.history} />
            </styled.ContentBox>
        )
    }
    cutNews(a, b) {
        if (this.state.news.length !== 0) {
            return this.state.news.slice(a, b)
        }
        return []
    }
    //
    formatDate(dates, type) {
        var date = new Date(dates);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // var h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // var minute = date.getMinutes();
        // minute = minute < 10 ? ('0' + minute) : minute;
        if (type === 1) { return y }
        if (type === 2) { return m + '.' + d }
        if (!type) { return y + '.' + m + '.' + d }
    }
    //
    pre() {
        if (this.state.imgIndex === 0) { return }
        this.setState({ imgIndex: this.state.imgIndex - 1 })
    }
    next() {
        if (this.state.imgIndex === Math.ceil(this.state.news3.length / 4) - 1) { return }
        this.setState({ imgIndex: this.state.imgIndex + 1 })
    }
    //
    getList(id, isCover, limit) {
        service.request({
            method: 'get',
            url: '/article',
            params: {
                categoryID: id,
                limit: limit,
                isCover: isCover,
            }
        }).then(res => {
            if (res.code === 200) {
                if (id === 1) { this.setState({ news1: res.data }) }
                if (id === 2 && limit !== 2) { this.setState({ news2: res.data }) }
                if (id === 2 && limit === 2) { this.setState({ news2_cover: res.data }) }
                if (id === 3) { this.setState({ news3: res.data }) }
            }
        })
    }
    getSimpleText(html) {
        if (!html) { return }
        var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
        var m = rx.exec(html);
        if (m) {
            m = m[1];
        } else {
            m = html
        }
        var re1 = new RegExp("<.+?>", "g")
        var msg = m.replace(re1, '')
        msg = msg.replace(/&nbsp;/g, ' ')
        return msg
    }
}
