import React, { Component } from 'react'


import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import service from '../Utlis/request'

import Banner from '../component/banner'
import Footer from '../component/footer'
import RightBar from '../component/rightbar'

import { homeRoutes } from '../routes'
import '../../public/iconfont'
import e1 from '../../public/e1.jpg'
import e2 from '../../public/e2.png'
// import e4 from '../../public/e4.png'
// import e5 from '../../public/e5.png'
// import e6 from '../../public/e6.png'
// import e7 from '../../public/e7.png'
import pabcg from '../../public/pabcg.jpg'
import nb3 from '../../public/nb3.png'


{/* <styled.Down style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-xiala'></use></styled.Down>
<styled.Up style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-shangla'></use></styled.Up> */}
export default class Professional extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropDown: false,
            secondaryDropDown: false,
            list: [],
            header: 'http://49.4.2.15:9090/',
        }
    }
    render() {
        return (
            <styled.ContentBox>
                <Banner history={this.props.history} active={'招生报名'} />
                <styled.Swipper style={{ background: `url(${e1}) no-repeat`, backgroundSize: '100% 100%', overflow: 'visible' }}>
                    <div style={{ position: 'absolute', left: '18rem', bottom: '3rem', color: '#fff', fontWeight: '700', fontSize: '2.5rem', letterSpacing: '.1rem' }}>专业介绍</div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: '99',
                            background: `url(${e2}) no-repeat`,
                            backgroundSize: '100% 100%',
                            width: '45rem',
                            height: '4.5rem',
                            paddingLeft: '18rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '1.2rem',
                            bottom: '0',
                            transform: 'translateY(50%)',
                            letterSpacing: '.1rem',
                        }}>首页<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>招生报名<styled.Icon style={{ fill: '#fff' }}><use xlinkHref='#icon-dian'></use></styled.Icon>专业介绍</div>
                </styled.Swipper>
                <styled.EducationBox style={{ paddingTop: '5rem',paddingBottom: '5rem', justifyContent: 'space-between', background: `url(${pabcg}) repeat`, backgroundSize: '100% 100%', }}>
                    <div style={{ width: '70%', padding: '2rem 0', flexDirection: "column" }}>
                        <div style={{ fontSize: "1.61rem", fontWeight: "bolder" }}>专业介绍</div>
                        <div style={{ width: "3.5rem", height: "0.22rem", background: "#cf1818", margin: "1rem 0 1rem 0" }}></div>
                        <div style={{ height: "100%", }}>
                            {
                                this.state.list.map((item, index) => {
                                    return <div style={{ marginBottom: "0.2rem" }}>
                                        <div onClick={() => this.Drapdown(item.ID)} style={{ width: "100%", height: "2.16rem", fontWeight: "bolder", padding: "0 1rem 0 1rem", background: `url(${nb3}) no-repeat`, backgroundSize: '100% 100%', color: "#fff", fontSize: "0.77rem", lineHeight: "2.16rem", position: "relative" }}>{item.Name}
                                            {item.dropDown == false ?
                                                <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#fff", transform: "rotate(-90deg)" }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                                : <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#fff", }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                            }
                                        </div>
                                        {
                                            item.Majors.map((i, j) => {
                                                return <div>
                                                    <div style={{ display: this.renderShow(item.dropDown) }}>
                                                        {i.secondaryDropDown == false ? <div onClick={() => this.secondaryDrapdown(i.ID, item.Majors)} style={{  width: "100%", height: "2.16rem", fontWeight: "bolder", border: "1px solid #fbf1f2", padding: "0 1rem 0 1rem", background: "#fdf8f8", color: "#585858", fontSize: "0.77rem", lineHeight: "2.16rem", position: "relative" }}>{i.Name}
                                                            {i.secondaryDropDown == false ?
                                                                <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#ecadb3", transform: "rotate(-90deg)" }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                                                : <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#d03445", }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                                            }
                                                        </div> :
                                                            <div onClick={() => this.secondaryDrapdown(i.ID, item.Majors)} style={{ width: "100%", height: "2.16rem", fontWeight: "bolder", border: "1px solid #fbf1f2", padding: "0 1rem 0 1rem", background: "#fdf8f8", color: "#c91427", fontSize: "0.77rem", lineHeight: "2.16rem", position: "relative" }}>{i.Name}
                                                                {i.secondaryDropDown == false ?
                                                                    <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#ecadb3", transform: "rotate(-90deg)" }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                                                    : <styled.Down style={{ width: "1.5rem", height: "1.5rem", position: "absolute", top: "0.4rem", right: "1.5rem", fill: "#d03445", }}><use xlinkHref='#icon-xiala'></use></styled.Down>
                                                                }
                                                            </div>}
                                                    </div>
                                                    <div style={{ display: this.renderShow(i.secondaryDropDown), }}>
                                                        <div style={{display:"flex",padding:"0.5rem"}}>
                                                            <div style={{width:"65%" }}>
                                                                <p style={{fontSize:"0.7rem",marginTop:"0.5rem",color:"#d04852"}}>培养目标</p>
                                                                <div style={{fontSize:"0.7rem",marginTop:"0.3rem",lineHeight:"1.5rem"}}>{i.Target}</div>
                                                                <p style={{fontSize:"0.7rem",marginTop:"0.5rem",color:"#d04852"}}>就业方向</p>
                                                                <div style={{fontSize:"0.7rem",marginTop:"0.3rem",lineHeight:"1.5rem"}}>{i.Employment}</div>
                                                            </div>
                                                            <div style={{ width:"30%",height:"8.61rem",marginLeft:"1rem",marginTop:"1rem"}}>
                                                                <img style={{ width:"100%",height:"8.61rem"}} src={this.state.header + i.Cover}></img>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{ width: '20%', height: '100%' }}>
                        <RightBar active={'专业介绍'} history={this.props.history} routes={homeRoutes[4]} />
                    </div>
                </styled.EducationBox>
                <Footer history={this.props.history}/>
            </styled.ContentBox>
        )
    }
    componentDidMount() {
        this.getProfessional()
    }
    getProfessional() {
        service.request({
            method: 'get',
            url: '/department',
            //    params:,
        }).then(res => {
            if (res.code === 200) {
                res.data.forEach((item,index) => {
                    item.dropDown = false
                    if(index==0){
                        item.dropDown = true
                    }
                    item.Majors.forEach((i,j) => {
                        i.secondaryDropDown = false
                        if(j==0&&index==0){
                            i.secondaryDropDown = true
                        }
                    });
                });
                this.setState({ list: res.data })
            }
        })
    }
    Drapdown(ID) {
        const Drapdownitem = this.state.list.find((ele) => { return ele.ID == ID })
        if (Drapdownitem.dropDown == true) {
            Drapdownitem.dropDown = false
            Drapdownitem.Majors.forEach((i,j) => {
                i.secondaryDropDown = false
            });
            this.setState({})
        } else {
            Drapdownitem.dropDown = true
            this.setState({})
        }
    }
    secondaryDrapdown(ID, Majors) {
        const secondaryDropDownitem = Majors.find((ele) => { return ele.ID == ID })
        if (secondaryDropDownitem.secondaryDropDown == true) {
            secondaryDropDownitem.secondaryDropDown = false
            this.setState({})
        } else {
            secondaryDropDownitem.secondaryDropDown = true
            this.setState({})
        }
    }
    renderShow(dropDown) {
        switch (dropDown) {
            case false:
                return "none";
            case true:
                return 'block';
        }
    }
}


