import React, { Component } from 'react'
import * as styled from '../Utlis/styled'
import '../../public/iconfont'
import cookie from 'react-cookies'
// import service from '../Utlis/request'

import { campusRoutes } from '../routes'

import logo from '../../public/logowhite.png'
import zhaosheng from '../../public/zhaosheng.pdf'


export default class Campus_Banner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: '首页',
        }
    }
    componentDidMount() {
        console.log(cookie.load('campus'))
        this.setState({ active: cookie.load('campus') })
    }
    render() {
        return (
            <styled.Banner style={{ color:'#fff',position: 'absolute', zIndex: '99',border:'none',background:'linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%)',minHeight:"0" ,paddingTop:'10px',paddingBottom:'10px'}}>
                <div style={{ justifyContent: '' }}>
                    <img src={logo} alt='' style={{ height: '5rem', width: '5rem', marginRight: '10px' }} />
                    <span style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '1.6rem', fontWeight: 'bold', letterSpacing: '2px' }}>花都形象艺术学校</p>
                        <p style={{ fontSize: '.9rem', letterSpacing: '.9px', marginTop: '.3rem' }}>HUADU IMAGE ART SCHOOL</p>
                    </span>

                </div>
                <div style={{ height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <styled.CampusItem style={{padding:'0',marginBottom:"10px"}}>
                            <span style={{color:'#ffbe43'}}>{cookie.load('campusname')}</span>
                        </styled.CampusItem>
                    </span>
                    <span style={{ fontSize: '1.1rem', color: '#000' }}>
                        {campusRoutes.map((item, index) => {
                            return (
                                <styled.C_PageTitle active={this.state.active === item.name ? true : false} key={index} style={{padding:"0"}}>
                                    {/* <Link  to={'/campus_news/' + cookie.load('campusid')} onClick={() => { this.pushCampus(item) }}>{item.Name}</Link> */}
                                    <span onClick={() => { this.pushPage(item) }}>{item.name}</span>
                                    {/* <styled.Down style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-xiala'></use></styled.Down> */}
                                    {/* <styled.Up style={{ display: item.routes ? '' : 'none', }}><use xlinkHref='#icon-shangla'></use></styled.Up>
                                    <styled.Drapdown style={{ display: item.routes ? '' : 'none' }}>
                                        {item.routes ? item.routes.map((route, index) => {
                                            return (
                                                <span key={index} onClick={() => { this.pushPage(route.path, route.name) }} style={{ display: route.name === '校友会' || route.name === '心理健康' ? 'none' : '' }}>{route.name}</span>
                                            )
                                        }) : ''}
                                    </styled.Drapdown> */}
                                    {/* <styled.Icon style={{ bottom: '-1.3rem', left: item.name === '首页' ? '50%' : '', transform: item.name === '首页' ? 'translateX(-50%)' : '' }}><use xlinkHref='#icon-xiangshang'></use></styled.Icon> */}
                                </styled.C_PageTitle>
                            )
                        })}
                    </span>
                </div>
            </styled.Banner>
        )
    }
    /////////////////////////////////////////// //////////////////////////////////////////////////////////////////////
    pushPage(item) {
        let path = item.path.slice(0, item.path.lastIndexOf('/') + 1);
        cookie.save('campus', item.name)
        this.props.history.push(path + cookie.load('campusid'))
        return
    }
    //
}
