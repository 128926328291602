import axios from "axios";
import cookie from 'react-cookies'

// const TOKEN_KEY = 'AS_MALL_ACCESS_TOKEN';

// 设置默认请求头token
// axios.defaults.headers.common['Authorization'] = localStorage.getItem(TOKEN_KEY);

// 创建实例
const service = axios.create({
    baseURL: 'http://49.4.2.15:9090/general',
})



// 请求拦截

service.interceptors.request.use(
    function (config) {
        config.headers.Token = cookie.load("TOKEN_KEY");
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    function (response) {
        // if (response && response.data.code === 479) {
        //     cookie.remove("TOKEN_KEY")
        //     window.location.reload()
        // }
        return response.data;
    }, function (error) {
        // console.log("响应错误：");
        // console.log(error);
        // 响应错误处理
        return Promise.reject(error);
    }
);

export function isLogined() {
    if (cookie.load("TOKEN_KEY")) {
        return true;
    } else {
        return true;
    }
}

export function clearToken() {
    cookie.remove("TOKEN_KEY") 
}


export default service;