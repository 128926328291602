import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
// import service from '../Utlis/request'
import i1 from '../../public/xq3.jpg'
import i2 from '../../public/update4.jpg'
// import i3 from '../../public/update2.jpg'
import i3 from '../../public/sp11.png'
import i4 from '../../public/i4.png'
import i5 from '../../public/update3.jpg'
import i6 from '../../public/i6.png'

export default class Images extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div style={{ width: '100%', height: '77rem', minHeight: '77rem', padding: '0 7.5rem', position: 'relative' }}>
                <div style={{ fontSize: '1.8rem' }}>
                    <p><i style={{ color: '#ba0f22' }}>_</i>HuaDu Images</p>
                    <p><i style={{ color: '#fff' }}>_</i>花都影像</p>
                </div>
                <div style={{ width: '100%', height: '70rem', position: 'absolute', left: '0', right: '0', bottom: '0', background: '#000', padding: '1.5rem 7rem', display: 'flex' }}>
                    {/* left */}
                    <div style={{ flex: '1.5', margin: '.5rem', display: 'flex', flexDirection: 'column' }}>
                        <styled.ImageItem style={{ flex: '1', marginBottom: '.5rem' }}>
                            <div style={{ background: `url(${i1}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <span>
                                <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                <span>
                                    <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                    <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                </span>
                            </span>
                        </styled.ImageItem>
                        <div style={{ flex: '1', marginTop: '.5rem', display: 'flex' }}>
                            <styled.ImageItem style={{ flex: '1', marginRight: '.5rem' }}>
                                <div style={{ background: `url(${i2}) no-repeat`, backgroundSize: '100% 100%', }} />
                                <span>
                                    <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                    <span>
                                        <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                        <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                    </span>
                                </span>
                            </styled.ImageItem>
                            <styled.ImageItem style={{ flex: '2.5', marginLeft: '.5rem' }}>
                                <div style={{ background: `url(${i3}) no-repeat`, backgroundSize: '100% 100%', }} />
                                <span>
                                    <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                    <span>
                                        <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                        <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                    </span>
                                </span>
                            </styled.ImageItem>
                        </div>
                    </div>
                    {/* right */}
                    <div style={{ flex: '1', margin: '.5rem', display: 'flex', flexDirection: 'column' }}>
                        <styled.ImageItem style={{ flex: '1', marginBottom: '.5rem' }}>
                            <div style={{ background: `url(${i4}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <span>
                                <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                <span>
                                    <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                    <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                </span>
                            </span>
                        </styled.ImageItem>
                        <styled.ImageItem style={{ flex: '1', margin: '.5rem 0' }}>
                            <div style={{ background: `url(${i5}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <span>
                                <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                <span>
                                    <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                    <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                </span>
                            </span>
                        </styled.ImageItem>
                        <styled.ImageItem style={{ flex: '1', marginTop: '.5rem' }}>
                            <div style={{ background: `url(${i6}) no-repeat`, backgroundSize: '100% 100%', }} />
                            <span>
                                <p>校园如摇篮,陪伴我们成长;校园如乐园。给以我们快乐;校园如花园,四处洋溢着青春。</p>
                                <span>
                                    <p onClick={()=>{this.props.history.push('/home/schoolprofile/picture')}}>进入相册</p>
                                    <styled.Icon><use xlinkHref='#icon-jiantou_xiangyou'></use></styled.Icon>
                                </span>
                            </span>
                        </styled.ImageItem>
                    </div>
                </div>
            </div>
        )
    }
}
