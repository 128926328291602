import React, { Component } from 'react'
import * as styled from '../Utlis/styled'


import '../../public/iconfont'
import service from '../Utlis/request'
import logowhite from '../../public/logowhite.png'

export default class Campus_Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            web: [
                { ID: 1, Name: '学校概况', Url: '/home/schoolprofile' },
                { ID: 2, Name: '招生就业', Url: '/home/enrolling' },
                { ID: 3, Name: '新闻动态', Url: '/home/news' },
                { ID: 4, Name: '产教融合', Url: '/home/education/merge' },
                { ID: 5, Name: '党团园地', Url: '/home/schoolprofile/party' },
                { ID: 6, Name: '名师风采', Url: '/home/teaching/mien' },
                { ID: 7, Name: '专业介绍', Url: '/home/enrolling/professional' },
                { ID: 8, Name: '校园风光', Url: '/home/schoolprofile/picture/scenery' },
                { ID: 9, Name: '校区分布', Url: '/home/schoolprofile/distribution' },
                { ID: 10, Name: '联系我们', Url: '/home/schoolprofile/contact' },
            ],
            link: [],
            system: {
                Phone: "",
                Postcodes: "",
                ICP: "",
                ZipCode: "",
            }
        }
    }
    render() {
        return (
            <div style={{ width: '100%', height: '18rem', minHeight: '18rem', background: 'linear-gradient(90deg, #7311ab 0%, #3908a2 100%)', position: 'relative', marginTop: '1rem', color: 'rgba(255, 255, 255, 0.4)' }}>
                <div style={{ width: '100%', height: 'calc(100% - 2.5rem)', padding: '0 13rem', paddingTop: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#fff' }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <span style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={logowhite} alt='' style={{ height: '5rem', width: '5rem', marginRight: '10px' }} />
                                <span style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', letterSpacing: '2px' }}>花都形象艺术学校</p>
                                    <p style={{ fontSize: '.7rem', letterSpacing: '1.1px', marginTop: '.3rem' }}>HUADU IMAGE ART SCHOOL</p>
                                </span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '.9rem', paddingTop: '1.5rem' }}>
                                <p>电话 : {this.state.system.Phone}</p>
                                <p>邮编 : {this.state.system.ZipCode}</p>
                            </div>
                        </span>
                        <span style={{ width: '7.5rem', height: '7.5rem', marginLeft: '2rem', height: '100%' }}>
                            <img src={"http://49.4.2.15:9090/" + this.state.system.Postcodes} alt='' style={{ width: '100%' }} />
                        </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p style={{ paddingBottom: '1.5rem' }}>联系我们</p>
                        <span style={{ fontSize: '.9rem' }}>
                            <p style={{ fontWeight: '700', paddingBottom: '.4rem' }}>地址 : </p>
                            <p style={{ paddingBottom: '.4rem' }}>石家庄桥西区红旗大街337号(红旗校区)</p>
                            <p style={{ paddingBottom: '.4rem' }}>石家庄长安区古城西路3号(中华校区)</p>
                            <p style={{ paddingBottom: '.4rem' }}>石家庄长安区中山东路289号(博物院校区)</p>
                            <p style={{ paddingBottom: '.4rem' }}>石家庄经济开发区阿里山大街19号(阿里山校区)</p>
                        </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p style={{ paddingBottom: '1.5rem' }}>网站导航</p>
                        <span style={{ fontSize: '.9rem', display: 'flex' }}>
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                {this.state.web.slice(0, 5).map((item, index) => {
                                    return (
                                        <styled.HoverBox key={index} onClick={() => { this.props.history.push(item.Url) }} style={{ color: '#fff', paddingBottom: '.4rem' }}>{item.Name}</styled.HoverBox>
                                    )
                                })}
                            </span>
                            <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '.6rem' }}>
                                {this.state.web.slice(5, 10).map((item, index) => {
                                    return (
                                        <styled.HoverBox key={index} onClick={() => { this.props.history.push(item.Url) }} style={{ color: '#fff', paddingBottom: '.4rem' }}>{item.Name}</styled.HoverBox>
                                    )
                                })}
                            </span>
                        </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p style={{ paddingBottom: '1.5rem' }}>友情链接</p>
                        <span style={{ display: 'flex', flexDirection: 'column', fontSize: '.9rem' }}>
                            {this.state.link.map((item, index) => {
                                return (
                                    <a key={index} href={item.URL} target="_blank" alt='' style={{ color: '#fff', paddingBottom: '.4rem', textDecorationLine: 'none' }}>{item.Name}</a>
                                )
                            })}
                        </span>
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: '0', display: 'flex', height: '2.5rem', width: '100%', background: 'linear-gradient(90deg, #7311ab 0%, #3908a2 100%)', alignItems: 'center', justifyContent: 'center', fontSize: '.88rem' }}>
                    <p style={{ margin: '0 .5rem' }}>@石家庄花都艺术学校 ALL Rights Reserved</p>
                    <p style={{ margin: '0 .5rem' }}>技术支持 : 石家庄次元时空科技有限公司</p>
                    <p style={{ margin: '0 .5rem' }}>备案号 : <a href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank" style={{ color: 'rgba(255, 255, 255, 0.4)' }}>{this.state.system.ICP}</a></p>
                    {/* <p style={{ margin: '0 .5rem' }}><a href='' style={{ color: 'rgba(255, 255, 255, 0.4)' }}>隐私政策</a></p> */}
                </div>
            </div>
        )
    }
    //
    componentDidMount() {
        this.getLink()
        this.getSystem()
    }
    //
    getLink() {
        service.request({
            method: 'get',
            url: '/link',
        }).then(res => {
            if (res.code === 200) {
                this.setState({ link: res.data })
            }
        })
    }
    //
    getSystem() {
        service.request({
            method: 'get',
            url: '/system',
        }).then(res => {
            if (res.code === 200) {
                this.setState({ system: res.data })
            }
        })
    }
}
